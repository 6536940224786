import ApiService from "@/api/ApiService";
import { usePersistantStore } from "@/stores/global";
import {
  Box,
  CircularProgress,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Fragment } from "react/jsx-runtime";
import { PossibleCalendarTypes } from "./MainCalendar";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { AxiosResponse } from "axios";
import { useState } from "react";
import FreeContainer from "./FreeContainer";
import {
  ArrowBackIos,
  ArrowForwardIos,
  BeachAccess,
  Bolt,
  Search,
} from "@mui/icons-material";
import CollisionContainer from "./CollisionContainer";

interface OpenRequest {
  date: string;
  resource_tag_id: number;
  open: number;
  name: string;
  type: "RESOURCE" | "CREW" | "HUMAN";
}

export default function OpenRequests({
  setCalendar,
  type,
}: {
  type: "LONG" | "SHORT";
  setCalendar: (
    type: PossibleCalendarTypes,
    reference: number,
    date: dayjs.Dayjs
  ) => void;
}) {
  const region = usePersistantStore((store) => store.region);
  const dispoCategory = usePersistantStore((store) => store.dispoCategory);
  const setDispoCategory = usePersistantStore(
    (store) => store.setDispoCategory
  );

  const [xtype, setXtype] = useState<"OPEN" | "FREE" | "COLLISIONS">("OPEN");
  const [week, setWeek] = useState(dayjs().startOf("isoWeek"));

  const query = useQuery<OpenRequest[], AxiosResponse>({
    queryKey: ["getOpenRequests", "" + region, dispoCategory, type],
    enabled: !!dispoCategory,
    queryFn: ApiService.getOpenRequests(region, dispoCategory, type),
  });

  const freeQuery = useQuery<any[], AxiosResponse>({
    queryKey: [
      "getOpenRequests",
      "free",
      "" + region,
      dispoCategory,
      week.format("YYYY-MM-DD"),
    ],
    enabled: !!dispoCategory && type === "LONG" && !!region,
    queryFn: ApiService.getFreeRequests(
      region,
      dispoCategory,
      week.format("YYYY-MM-DD")
    ),
  });

  const collisionQuery = useQuery<any[], AxiosResponse>({
    queryKey: [
      "getOpenRequests",
      "collisions",
      "" + region,
      dispoCategory
    ],
    enabled: !!dispoCategory && type === "LONG" && !!region,
    queryFn: ApiService.getCollisions(
      region,
      dispoCategory
    ),
  });


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px -2px 4px #0000004a",
        maxWidth: "250px",
        borderLeft: "1px solid #888",
      }}
    >
      <Box sx={{ minWidth: "160px", borderBottom: "1px solid #888" }}>
        <ManyToOneSelect
          sx={{
            m: 0,
            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            "& .MuiAutocomplete-clearIndicator": { display: "none !important" },
          }}
          remoteFilter={{ region: ["region", region] }}
          entity={"dispoCategory"}
          size="small"
          currentId={dispoCategory}
          disabled={false}
          setId={(id) => !id || setDispoCategory(id)}
          showWithoutChoice
          autoSelect
        />
      </Box>
      {type === "LONG" ? (
        <Box
          sx={{
            borderBottom: "1px solid #aaa",
            cursor: "pointer",
            display: "flex",
          }}
        >
          <Box
            className={xtype === "OPEN" ? "act" : ""}
            sx={{
              "&:last-child": { borderRight: "none" },
              borderRight: "1px solid #aaa",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              background: "#eee",
              color: "#555",
              "&:hover": {
                background: "#ddd",
                color: "#666",
              },
              "&.act": {
                background: "#fff",
                color: "#000",
              },
            }}
            onClick={() => setXtype("OPEN")}
          >
            <Search />
          </Box>
          <Box
            className={xtype === "FREE" ? "act" : ""}
            sx={{
              "&:last-child": { borderRight: "none" },
              borderRight: "1px solid #aaa",
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#eee",
              color: "#555",
              "&:hover": {
                background: "#ddd",
                color: "#666",
              },
              "&.act": {
                background: "#fff",
                color: "#000",
              },
            }}
            onClick={() => setXtype("FREE")}
          >
            <BeachAccess />
          </Box>
          <Box
            className={(xtype === "COLLISIONS" ? "act" : "") + ((collisionQuery?.data??[]).length > 0 ? " collwarning" : "")}
            sx={{
              "&:last-child": { borderRight: "none" },
              borderRight: "1px solid #aaa",
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#eee",
              color: "#555",
              "&:hover": {
                background: "#ddd",
                color: "#666",
              },
              "&.act": {
                background: "#fff",
                color: "#000",
              },
            }}
            onClick={() => setXtype("COLLISIONS")}
          >
            <Bolt />
          </Box>
        </Box>
      ) : null}
      {xtype === "FREE" ? (
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            borderBottom: "1px solid #aaa",
          }}
        >
          <IconButton
            size="small"
            onClick={() => setWeek(week.subtract(1, "week"))}
          >
            <ArrowBackIos fontSize="small" />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              minWidth: "145px",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Typography variant="h6">KW {week.format("W/GGGG")}</Typography>
          </Box>
          <IconButton size="small" onClick={() => setWeek(week.add(1, "week"))}>
            <ArrowForwardIos fontSize="small" />
          </IconButton>
        </Box>
      ) : null}
      <Box
        sx={{

          overflow: "auto",
          position: "relative",
          background: "#fafafa",
          flex: 1,
        }}
      >
        {xtype === "COLLISIONS" ? (
          <CollisionContainer setCalendar={setCalendar} query={collisionQuery} />
        ) : xtype === "FREE" ? (
          <FreeContainer draggable={true} setCalendar={setCalendar} query={freeQuery} />
        ) : query.isLoading ? (
          <CircularProgress />
        ) : query.isLoadingError ? (
          "Error"
        ) : (
          (query?.data ?? []).map((x, i, f) => (
            <Fragment key={x.date + "_" + x.resource_tag_id}>
              {i === 0 || f[i - 1].date !== x.date ? (
                <Box
                  sx={{
                    position: "sticky",
                    top: 0,
                    color: "#555",
                    p: "4px 6px",
                    lineHeight: 1,
                    fontWeight: "14px",
                    mb: "8px",
                    flexShrink: 0,
                    background: "#edf0f9",
                    borderTop: "1px solid rgba(0,0,0,0.2)",
                    borderBottom: "1px solid rgba(0,0,0,0.2)",
                    boxShadow: "inset 0px -4px 5px rgba(0,0,0,0.1)",
                  }}
                >
                  {dayjs(x.date).format("DD.MM.YYYY")}
                </Box>
              ) : null}
              <Box
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    opacity: 0.5,
                  },
                  display: "flex",
                  border: "1px solid #aaa",
                  mb: "8px",
                  mx: "4px",
                  alignItems: "stretch",
                  overflow: "hidden",
                  borderRadius: "5px",
                  flexShrink: 0,
                  fontSize: "13px",
                }}
                onClick={() =>
                  setCalendar(
                    x.type === "CREW"
                      ? "CREWS"
                      : x.type === "RESOURCE"
                      ? "RESOURCES"
                      : "STAFF",
                    x.resource_tag_id,
                    dayjs(x.date, "YYYY-MM-DD")
                  )
                }
              >
                <Box
                  sx={{
                    borderRight: "1px solid #aaa",
                    color: "#333",
                    boxShadow: "inset -4px 0px 5px rgba(0,0,0,0.1)",
                    p: "4px 6px",
                    lineHeight: 1,
                    background: "#edf0f9",
                  }}
                >
                  {x.open}
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    p: "4px",
                    lineHeight: 1,
                    textTransform: "uppercase",
                  }}
                >
                  {(x.type === "CREW"
                    ? "Kolonne "
                    : x.type === "HUMAN"
                    ? "Mitarbeiter "
                    : "") + x.name}
                </Box>
              </Box>
            </Fragment>
          ))
        )}
      </Box>
    </Box>
  );
}
