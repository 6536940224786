import { useMemo } from "react";
import { MaterialOrder, ProjectJob, ResourceOrder } from "../Project";

function useJobHashes(jobs: { [date: string]: ProjectJob }) {
  return useMemo(() => {
    // Extract ProjectJob array from the object
    const projectJobs = Object.values(jobs).filter(job => !job.deleted);

    // Sort jobs by date (lexicographical string comparison for YYYY-MM-DD format)
    projectJobs.sort((a, b) => a.date.localeCompare(b.date));

    // Helper to generate MaterialOrder hash
    const hashMaterialOrder = (mo: MaterialOrder): string => {
      if (!mo.article) return "";
      const additionalHash = mo.additionalArticles
        .filter(aa => !aa.deleted && aa.article)
        .map(aa => aa.article!.id.toString())
        .sort()
        .join("-");
      return `${mo.article.id}-${additionalHash}`;
    };

    // Helper to generate ResourceOrder hash
    const hashResourceOrder = (ro: ResourceOrder): string => {
      return `${ro.type}-${ro.amount ?? 0}-${ro.tag ?? "null"}`;
    };

    // Generate total hash for each job
    const jobHashes: { [jobId: number]: string } = {};
    const uniqueHashes: string[] = [];
    projectJobs.forEach(job => {
      const materialOrdersHash = job.materialOrders
        .filter(mo => !mo.deleted)
        .map(hashMaterialOrder)
        .sort()
        .join("|");

      const resourceOrdersHash = job.resourceOrders
        .filter(ro => !ro.deleted)
        .map(hashResourceOrder)
        .sort()
        .join("|");

      const totalHash = `${materialOrdersHash}:${resourceOrdersHash}`;
      if (totalHash === ":") {
        jobHashes[job.id] = ""; // No MO or RO, empty letter
      } else {
        if (!uniqueHashes.includes(totalHash)) {
          uniqueHashes.push(totalHash);
        }
        const hashIndex = uniqueHashes.indexOf(totalHash);
        const assignedLetter = String.fromCharCode(65 + (hashIndex % 26)); // Cycle A-Z
        jobHashes[job.id] = assignedLetter;
      }
    });

    return jobHashes;
  }, [jobs]); // Recompute only when jobs change
}

export default useJobHashes;
