import { Fragment, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import ApiService from "@/shared/api/BaseApiService";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert, Paper } from "@mui/material";
import { setToken } from "@/shared/api/request";

const BaseLogin = ({ setAuthenticated = undefined, setAuthenticatedWithUser = undefined, image, checkAccessRights = _ => true }: {
  setAuthenticated?: (state: boolean) => void;
  setAuthenticatedWithUser?: (state: boolean, user: number) => void;
  image: string;
  checkAccessRights?: (rights: {[right: string]: any}) => boolean
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = useMutation({
    mutationFn: ApiService.loginCheck,
    onSuccess: (answer) => {
      let rawRights = Array.isArray(answer?.rights ?? "{}") ? "{}" : (answer?.rights ?? "{}");
      if (typeof rawRights !== "string")
        rawRights = JSON.stringify(rawRights);
      if (!checkAccessRights(JSON.parse(rawRights)))
        throw new Error("Access control failure");
      setToken(answer.token, answer.refresh_token, rawRights);
      if (!!setAuthenticatedWithUser)
        setAuthenticatedWithUser(true, answer?.user?.id ?? 0);
      else if (!!setAuthenticated) {
        setAuthenticated(true);
      }
    },
    onError: (error) => {
     
    },
  });

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        background: "url(" + image + ") center center",
        backgroundSize: "cover",
      }}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper
          sx={{
            px: 3,
            pt: 2,
            pb: 2,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Anmelden
          </Typography>
          <Box
            component="form"
            sx={{ mt: 1 }}
            onSubmit={(e) => {
              e.preventDefault();
              login.mutate({ email, password });
            }}
          >
            {login.isError ? (
              <Alert severity="error">Fehler beim Anmelden.</Alert>
            ) : null}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-Mail"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              value={password}
              onChange={(e) =>
                setPassword((e.target as HTMLInputElement).value)
              }
              label="Passwort"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <LoadingButton
              loading={login.isLoading}
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Anmelden
            </LoadingButton>
          </Box>
          {import.meta.env.VITE_SHOW_LEGAL === "no" ? null : <Fragment><Box sx={{fontSize: "14px"}}><a href="https://www.juchem-gruppe.de/impressum" target="_blank">Impressum</a> &ndash; <a href="https://www.juchem-gruppe.de/datenschutz" target="_blank">Datenschutz</a></Box></Fragment>}
        </Paper>
      </Container>
    </Box>
  );
};

export default BaseLogin;
