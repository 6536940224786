import ApiService from "@/api/ApiService";
import AddResourceDialog from "@/components/planning/dialogs/AddResourceDialog";
import {
  initialResourceOrder,
  ResourceOrder,
} from "@/components/planning/Project";
import { Add, Loop } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Fragment, useState } from "react";

type SingleJobResourceOrder = ResourceOrder & { job: number };

export function DirectBooking({
  job,
  region,
  project,
  date
}: {
  job: number;
  date: string;
  region: number;
  project: number;
}) {
  const [holdingState, setHoldingState] =
    useState<SingleJobResourceOrder | null>(null);

 const queryClient = useQueryClient();

  const saveMutation = useMutation<
    SingleJobResourceOrder,
    AxiosResponse,
    SingleJobResourceOrder
  >({
    mutationFn: ApiService.createEntity("resourceOrder"),
    onSuccess: (answer) => {
      setHoldingState({ ...answer, autoBooking: false });
      autoBookMutation.mutate(project);
    },
  });

  const autoBookMutation = useMutation<any, AxiosResponse, number>({
    mutationFn: ApiService.autoBook(),
    onSuccess: (_) => {
      if (!!holdingState) updateMutation.mutate(holdingState);
    },
  });

  const updateMutation = useMutation<
    any,
    AxiosResponse,
    SingleJobResourceOrder
  >({
    mutationFn: ApiService.editEntity("resourceOrder"),
    onSuccess: (_) => {
      setHoldingState(null);
      queryClient.invalidateQueries({queryKey: ["getDispoCal", date, ""+region]});
      queryClient.invalidateQueries({queryKey: ["getJobApp", ""+job]});
    },
  });

  const loading =
    saveMutation.isPending ||
    autoBookMutation.isPending ||
    updateMutation.isPending;

  return (
    <AddResourceDialog<SingleJobResourceOrder>
      save={function (order: SingleJobResourceOrder): void {
        saveMutation.mutate(order);
      }}
      OpenButton={
        ({onClick}) => <div className="directbookingButton" onClick={!loading ? onClick : () => {}}>
          {loading ? <Loop fontSize="inherit" /> : <Add fontSize="inherit" />}
          <div className="dibutext">hinzufügen</div>
        </div>
      }
      initialState={{
        ...initialResourceOrder(),
        job,
        autoBooking: true,
      }}
      dates={[date]}
      hideAutoBooking
      forceWishable
      region={region}
    />
  );
}
