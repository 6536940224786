import {
  Add,
  AddCircleTwoTone,
  RemoveCircleTwoTone,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import LabelWrapper from "../../root/LabelWrapper";
import {
  initialJob,
  initialResourceOrder,
  ResourceOrder,
} from "../../planning/Project";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import ApiService from "@/api/ApiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { usePersistantStore } from "@/stores/global";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import NewRangePicker from "@/shared/forms/NewRangePicker";

const fixSizes = (refs: ResourceOrder["references"], amount: number) =>
  refs.length === amount
    ? refs
    : refs.length > amount
    ? refs.slice(0, refs.length)
    : refs.concat(new Array(amount - refs.length).fill(null));

const initialState = (type, reference, date) => ({
  id: 0,
  deleted: false,
  amount: 1,
  tag: reference || null,
  comment: "",
  type: type === "CREWS" ? "CREW" : type === "RESOURCES" ? "RESOURCE" : "HUMAN",
  references: [],
  job: {
    id: 0,
    date,
    uuid: self.crypto.randomUUID(),
    deleted: false,
    phase: {
      id: 0,
      name: "Baustelle",
      type: "sonstiges",
      uuid: self.crypto.randomUUID(),
      plannedStart: date,
      plannedEnd: date,
      project: 0,
    },
  },
});

const initialState2 = (type, reference, date) => ({
  ...initialResourceOrder(),
  tag: reference || null,
  type: type === "CREWS" ? "CREW" : type === "RESOURCES" ? "RESOURCE" : "HUMAN",
  phase: 0,
  startConstraint: date,
  endConstraint: date,
});

export default function AddFastResourceDialog({
  date,
  reference,
  type,
  close,
}) {
  const region = usePersistantStore((store) => store.region);
  const [variant, setVariant] = useState<"EXISTING" | "NEW">("EXISTING");
  const [state, setState] = useState(initialState(type, reference, date));
  const [alternativeState, setAlternativeState] = useState(
    initialState2(type, reference, date)
  );

  useEffect(() => {
    setState(initialState(type, reference, date));
    setAlternativeState(initialState2(type, reference, date));
  }, [type, reference, date]);
  const queryClient = useQueryClient();

  const saveMutationExisting = useMutation<
    ResourceOrder,
    AxiosResponse,
    ResourceOrder
  >({
    mutationFn: ApiService.createEntity("resourceOrder", true, {
      version: "fastbookingPhase",
    }),
    onSuccess: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      close();
    },
  });

  const saveMutation = useMutation<any, AxiosResponse, any>({
    mutationFn: ApiService.createEntity("resourceOrder", true, {
      version: "fastbooking",
    }),
    onSuccess: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      close();
    },
  });

  const jobQuery = useQuery<any[], AxiosResponse>({
    queryKey: ["getJobByDate", "" + state.job.phase.id, date],
    enabled: state.job.phase.id > 0,
    queryFn: ApiService.getEntitiesWithFilter("projectJob", {
      phase: ["projectPhase", state.job.phase.id],
      date: ["%DATETIME", date],
    }),
  });

  const valid =
    (variant === "NEW" && !!state.job.phase.project && !jobQuery.isLoading) ||
    (variant === "EXISTING" && alternativeState.phase !== 0);

  useEffect(() => {
    if (jobQuery.status !== "success" || jobQuery.data.length === 0) {
      setState((s) => ({
        ...s,
        job: { ...s.job, id: 0 },
      }));
    } else {
      setState((s) => ({
        ...s,
        job: { ...s.job, id: jobQuery.data[0].id ?? 0 },
      }));
    }
  }, [jobQuery.status]);

  return (
    <Dialog open={!!date} maxWidth="lg" fullWidth onClose={close}>
      <Fragment>
        <DialogTitle
          sx={{
            p: 1,
            px: 2,
            background: "#002169",
            color: "white",
            borderBottom: "1px solid rgba(255,255,255,0.5)",
          }}
        >
          Schnellauftrag für {dayjs(date).format("ddd, D. MMMM YYYY")}
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <LabelWrapper label="Variante">
            <ToggleButtonGroup
              orientation="horizontal"
              exclusive
              value={variant}
              onChange={(_, variant) => setVariant(variant)}
            >
              <ToggleButton value={"EXISTING"}>Bestehende Planung</ToggleButton>
              <ToggleButton value={"NEW"}>Neue Planung</ToggleButton>
            </ToggleButtonGroup>
          </LabelWrapper>
          <LabelWrapper label="Projekt">
            <ManyToOneSelect
              showWithoutChoice
              remoteFilter={
                !!region
                  ? { region: ["region", region], active: true }
                  : { active: true }
              }
              displayFunction={(o) => o.name + " (" + o.number + ")"}
              remoteFilterReady={true}
              placeholder="Bitte wählen..."
              entity={"project"}
              currentId={state.job.phase.project ?? 0}
              setId={(project) => {
                setState((s) => ({
                  ...s,
                  job: {
                    ...s.job,
                    id: 0,
                    phase: { ...s.job.phase, id: 0, project },
                  },
                }));
              }}
            />
          </LabelWrapper>
          {!!state.job.phase.project ? (
            <LabelWrapper label="Bauphase">
              <ManyToOneSelect
                showWithoutChoice
                remoteFilter={{ project: ["project", state.job.phase.project] }}
                remoteFilterReady={!!state.job.phase.project}
                placeholder={
                  variant === "NEW"
                    ? "Leer lassen zum Erstellen einer neuen Bauphase"
                    : "Bitte wählen..."
                }
                entity={"projectPhase"}
                currentId={state.job.phase.id ?? 0}
                setId={(id) => {
                  setState((s) => ({
                    ...s,
                    job: { ...s.job, phase: { ...s.job.phase, id } },
                  }));
                  setAlternativeState((s) => ({ ...s, phase: id }));
                }}
              />
            </LabelWrapper>
          ) : null}
          {!!alternativeState.startConstraint && variant === "EXISTING" ? (
            <LabelWrapper label="Zeitraum wählen">
              <NewRangePicker
                small
                onChange={([startDate, endDate]) => {
                  setAlternativeState((s) => ({
                    ...s,
                    startConstraint: !!startDate
                      ? startDate.format("YYYY-MM-DD")
                      : null,
                    endConstraint: !!endDate
                      ? endDate.format("YYYY-MM-DD")
                      : null,
                  }));
                }}
                value={[
                  dayjs(alternativeState.startConstraint, "YYYY-MM-DD"),
                  dayjs(alternativeState.endConstraint, "YYYY-MM-DD"),
                ]}
              />
            </LabelWrapper>
          ) : null}
          <LabelWrapper label="Anzahl">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                disabled={state.amount <= 1}
                onClick={() => {
                  setState((s) => ({
                    ...s,
                    amount: s.amount - 1,
                  }));
                  setAlternativeState((s) => ({ ...s, amount: s.amount - 1 }));
                }}
              >
                <RemoveCircleTwoTone />
              </IconButton>
              <Box sx={{ fontSize: "20px", mx: "10px" }}>{state.amount}</Box>
              <IconButton
                onClick={() => {
                  setState((s) => ({
                    ...s,
                    amount: s.amount + 1,
                  }));
                  setAlternativeState((s) => ({ ...s, amount: s.amount + 1 }));
                }}
              >
                <AddCircleTwoTone />
              </IconButton>
            </Box>
          </LabelWrapper>
          <LabelWrapper label="Weitere Informationen">
            <TextField
              size="small"
              fullWidth
              value={state.comment}
              placeholder="Weitere Informationen"
              multiline
              onChange={(e) => {
                setState((s) => ({
                  ...s,
                  comment: (e.target as HTMLInputElement).value,
                }));
                setAlternativeState((s) => ({
                  ...s,
                  comment: (e.target as HTMLInputElement).value,
                }));
              }}
            />
          </LabelWrapper>
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
          <Button onClick={close}>Abbrechen</Button>
          <LoadingButton
            variant="outlined"
            color="primary"
            disabled={!valid}
            loading={saveMutation.isPending || saveMutationExisting.isPending}
            onClick={() => {
              variant === "NEW"
                ? saveMutation.mutate(state)
                : saveMutationExisting.mutate(alternativeState);
              close();
            }}
          >
            Speichern
          </LoadingButton>
        </DialogActions>
      </Fragment>
    </Dialog>
  );
}
