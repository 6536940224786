import ApiService from "@/api/ApiService";
import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { CalJob } from "../calendarElements/InnerCalendar";
import { MaterialOrder } from "@/components/planning/Project";
import { Button, CircularProgress } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import { forwardRef, useImperativeHandle, useState } from "react";


import {
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import OrderInformation from "./OrderInformation";
import { useNavigate } from "react-router-dom";
import useRights from "@/shared/api/useRights";

interface ResourceTag {
  id: number;
  name: string;
  type: "HUMAN" | "RESOURCE" | "CREW";
  calendarType: "NONE" | "CALENDAR_ONLY" | "WISHABLE" | "REQUESTABLE";
}

export interface StaffMember {
  id: number;
  name: string;
  tags: ResourceTag[];
}

export interface Crew {
  id: number;
  name: string;
}

export interface Resource {
  id: number;
  name: string;
  tags: ResourceTag[];
  number: string;
}

interface ResourceBooking {
  id: number;
  crew: Crew | null;
  isLeader: boolean;
  resource: Resource | null;
  staffMember: StaffMember | null;
  order: ResourceOrder;
  type: "CREW" | "RESOURCE" | "HUMAN";
}

interface ResourceOrder {
  id: number;
  amount: number;
  tag: ResourceTag | null;
  comment: string;
  type: "CREW" | "RESOURCE" | "HUMAN";
  job: number | null;
  phase: number | null;
  deleted: boolean;
  references: (number | null)[];
  exceptions: string[];
}

export interface WithOrders {
  materialOrders: MaterialOrder[];
  resourceOrders: {
    resourceBookings: ResourceBooking[];
    resourceOrders: ResourceOrder[];
  };
}




export default forwardRef(function CalendarListView({
  setCalendar,
}: {
},
ref) {
  const rights = useRights();
  const settings = usePersistantStore((state) => state.settings);
  const region = usePersistantStore((state) => state.region);
  const [hiddenJobs, setHiddenJobs] = useState<Set<number>>(new Set());

  const date = useGlobalStore((state) => state.date);

  const query = useQuery<(CalJob & WithOrders)[], AxiosResponse>({
    queryKey: ["getDispoCal", date, "" + region],
    queryFn: ApiService.getDispoCal(date, region),
  });

  useImperativeHandle(ref, () => ({
      hideAll: () => setHiddenJobs(new Set(query.data?.map(x => x.id))),
      showAll: () => setHiddenJobs(new Set()),
  }));


  const navigate = useNavigate();

  return (
    <div className="calListWrapper">
      {query.isLoading ? (
        <CircularProgress />
      ) : query.isLoadingError ? (
        "Error"
      ) : (
        (query.data ?? []).map((x, i, a) => (
          <Fragment key={x.id}>
            <div
              className="calJobHeader"
              onClick={() =>
                setHiddenJobs((prevSet) => {
                  const newSet = new Set(prevSet); // Create a new Set for immutability
                  if (newSet.has(x.id)) {
                    newSet.delete(x.id); // Remove value if it exists
                  } else {
                    newSet.add(x.id); // Add value if it doesn't exist
                  }
                  return newSet;
                })
              }
            >
              <div
                className={
                  "calOrderState color "+(!!x.orderSentAt ? "darkgreen" : x.status === "PLANNING"
                    ? "blue"
                    : x.status === "RAW"
                    ? "yellow"
                    : x.status === "READY"
                    ? "orange"
                    : "green")
                }
                
              >
                {hiddenJobs.has(x.id) ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowDown />
                )}
              </div>
              <div className="calJobNames">
                <div className="calJobNumber">{x.phase.project.number}</div>
                <div className="calJobName">{x.phase.project.name}</div>
                {Object.keys(x.crews).length > 0 ? (
                  <div className="calCrewName">
                    {Object.values(x.crews).join("; ")}
                  </div>
                ) : (
                  <div className="calCrewName" />
                )}
                <div className="calJobLink">
                    <Button
                    onClick={() =>
                      window.open(
                      `/project/${x.phase.project.id}?phaseId=${x.phase.id}&date=${x.date}`,
                       "_blank"
                      )
                    }
                    variant="contained"
                    color="info"
                    size="small"
                    >
                    Planung öffnen
                    </Button>
                </div>
              </div>
            </div>
            {!hiddenJobs.has(x.id) ? (
              <OrderInformation
                withDirectBooking={"DISPOSITION" in rights}
                setCalendar={setCalendar}
                data={x}
                showInfo={settings.showDispoInformation}
                showOrders={settings.showDispoOrders}
                showMaterial={settings.showDispoMaterial}
                showResources={settings.showDispoResources}
              />
            ) : null}
          </Fragment>
        ))
      )}
    </div>
  );
});
