import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { Alert, Box, IconButton, Paper } from "@mui/material";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import VerticalCalendar from "../shared/VerticalCalendar";
import SelectCalendarDialog from "./dialogs/SelectCalendarDialog";
import InnerCalendar from "./calendarElements/InnerCalendar";
import dayjs from "dayjs";
import { AddCircle, ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import AddFastResourceDialog from "./dialogs/AddFastResource";
import OpenRequests from "./OpenRequests";
import CalendarSettings from "./dialogs/CalendarSettings";
import JobInfoDialog from "./dialogs/JobInfoDialog";
import useRights from "@/shared/api/useRights";

export type PossibleCalendarTypes =
  | "PROJECTS"
  | "CREWS"
  | "STAFF"
  | "RESOURCES";

export default function MainCalendar() {
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);

  const calendar = useRef();
  const rights = useRights();


  const type = usePersistantStore((state) => state.type);
  const setType = usePersistantStore((state) => state.setType);
  const reference = usePersistantStore((state) => state.reference);
  const setReference = usePersistantStore((state) => state.setReference);
  const [selectedDate, setSelectedDate] = useState(null);
  const settings = usePersistantStore((state) => state.settings);
  const setSettings = usePersistantStore((state) => state.setSettings);

  const [jobInfoId, setJobInfoId] = useState(null);

  const [showDeletor, setShowDeletor] = useState(false);

  useEffect(() => setPageTitle("Großgeräteplanung"), []);

  const createDayButtons = useCallback(
    (date) => (
      <IconButton onClick={() => setSelectedDate(date)}>
        <AddCircle />
      </IconButton>
    ),
    []
  );

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          p: 1,
          background: "#eee",
        }}
      >
        {navigator.userAgent.toLowerCase().includes("firefox") ? (
          <Alert sx={{ mb: "10px" }} variant="filled" severity="warning">
            Der Kalender funktioniert im Firefox Browser leider nur
            eingeschränkt. Wir empfehlen deshalb Google Chrome oder Microsoft
            Edge.
          </Alert>
        ) : null}

        <Paper
          sx={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
        >
         
            <VerticalCalendar
              ref={calendar}
              withDragzones
              initialCalEnd={dayjs().endOf("week")}
              initialCalStart={dayjs().startOf("week")}
              dateButtons={
                ("DISPOSITION" in rights)
                  ? createDayButtons
                  : null
              }
              buttons={
                <div className="calendarButtons">
                  <SelectCalendarDialog
                    type={type}
                    reference={reference}
                    onChange={(type, reference) => {
                      setType(type);
                      setReference(reference);
                    }}
                  />
                  {type === "CREWS" ? <CalendarSettings
                    type={type}
                    settings={settings}
                    setSettings={setSettings}
                  /> : null}
                  <div />
                </div>
              }
            >
              {!!type ? (
                <InnerCalendar
                  type={type}
                  setJobInfoId={setJobInfoId}
                  settings={settings}
                  readOnly={!("DISPOSITION" in rights)}
                  reference={reference}
                />
              ) : (
                <Fragment />
              )}
            </VerticalCalendar>
    
      

            <AddFastResourceDialog
              date={selectedDate}
              type={type}
              reference={reference}
              close={() => setSelectedDate(null)}
            />
          <JobInfoDialog jobId={jobInfoId} close={() => setJobInfoId(null)} />
        </Paper>
      </Box>
      {!("DISPOSITION" in rights) ? null : (
        
          <OpenRequests
            type="LONG"
            setCalendar={function (
              type: PossibleCalendarTypes,
              reference: number,
              date: dayjs.Dayjs
            ): void {
              setType(type);
              setReference(reference);
              calendar.current?.setCalendar(
                date.subtract(1, "day"),
                date.add(8, "days")
              );
            }}
          />
      )}
    </Box>
  );
}
