import {
  Paper,
  Button,
  Typography,
  Box,
  IconButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Fragment, useCallback } from "react";
import { Add, Delete, Edit } from "@mui/icons-material";
import {
  ProjectState,
  StoffstromOrder,
} from "../Project";
import EnhancedTable from "@/shared/views/EnhancedTable";
import { MainContainer } from "@/shared/structure/MainContainer";
import { TopBar } from "@/shared/structure/TopBar";
import AddStoffstromDialog from "../dialogs/AddStoffstromDialog";
import useReferenceNames from "@/shared/forms/useReferenceNames";

const headCells = [
  {
    id: "categoryName",
    label: "Kategorie",
    numeric: false,
    sortable: true,
  },
  {
    id: "amount",
    label: "Menge",
    numeric: false,
    sortable: true,
  },
  {
    id: "date",
    label: "Zeitraum",
    numeric: false,
    sortable: true,
  },
  {
    id: "comment",
    label: "Weitere Informationen",
    numeric: false,
    sortable: true,
  },
];

export default function StoffstromPage({
  project,
  setField,
}: {
  project: ProjectState;
  setField: (changes: Partial<ProjectState>) => void;
}) {
  const deleteOrder = useCallback(
    (index: number) => {
      setField({
        stoffstrom: project.stoffstrom.slice().map((so, i) => ({
          ...so,
          changed: true,
          deleted: !!so.deleted || i === index,
        })),
      });
    },
    [project]
  );

  const saveOrder = useCallback(
    (subOrder: StoffstromOrder, index: number) => {
      if (index === -1) {
        const stoffstrom = project.stoffstrom.slice();
        stoffstrom.push(subOrder);
        setField({
          stoffstrom,
        });
      } else {
        setField({
          stoffstrom: project.stoffstrom
            .slice()
            .map((so, i) => (i === index ? {...subOrder, changed: true} : so)),
        });
      }
    },
    [project]
  );

  const categoryNames = useReferenceNames("stoffstromCategory");

  const stoffstromOfferCount = project.stoffstrom.filter(
    (x) => !x.deleted && x.type === "OFFER"
  ).length;
  const stoffstromRequestCount = project.stoffstrom.filter(
    (x) => !x.deleted && x.type === "REQUEST"
  ).length;

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      {project.writeable ? <TopBar>
        <AddStoffstromDialog
          save={o => saveOrder(o,-1)}
          OpenButton={({ onClick }) => (
            <Button startIcon={<Add />} onClick={onClick}>
              Eintrag hinzufügen
            </Button>
          )}
          initialState={null}
          index={-1}
        />
      </TopBar> : null}
      <MainContainer paperless>
        {stoffstromOfferCount + stoffstromRequestCount === 0 ? <Alert severity="info" variant="filled">
                            <AlertTitle>Nichts gefunden.</AlertTitle>
                            Hier gibt es noch keinen Eintrag.
                          </Alert> : null}
        {stoffstromOfferCount > 0 ? (
          <Typography variant="h6" gutterBottom>
            Verfügbares Material
          </Typography>
        ) : null}
        {stoffstromOfferCount > 0 ? (
          <Paper>
            <EnhancedTable
              initialRows={100}
              buttons={!project.writeable ? (row)=>null: (row) => (
                <Fragment>
                  <AddStoffstromDialog
                    save={order => saveOrder(order,row.index)}
                    initialState={row}
                    OpenButton={({onClick}) => <IconButton  size="small" onClick={onClick}>
                    <Edit />
                  </IconButton>}
                  />
                  <IconButton size="small" onClick={() => deleteOrder(row.index)}>
                    <Delete />
                  </IconButton>
                </Fragment>
              )}
              type=""
              noEmptyCells
              initialSort="category"
              headCells={headCells}
              rows={project.stoffstrom
                .map((x, index) => ({ ...x, index, categoryName: categoryNames.get(x.category) || "(Unbekannt)" }))
                .filter((x) => !x.deleted && x.type === "OFFER")}
            />
          </Paper>
        ) : null}
        {stoffstromRequestCount > 0 ? (
          <Typography sx={{ mt: 2 }} variant="h6" gutterBottom>
            Benötigtes Material
          </Typography>
        ) : null}
        {stoffstromRequestCount > 0 ? (
          <Paper>
            <EnhancedTable
              initialRows={100}
              buttons={project.writeable ? (row)=>null :(row) => (
                <Fragment>
                  <AddStoffstromDialog
                    save={order => saveOrder(order,row.index)}
                    initialState={row}
                    OpenButton={({onClick}) => <IconButton  size="small" onClick={onClick}>
                    <Edit />
                  </IconButton>}
                  />
                  <IconButton  size="small" onClick={() => deleteOrder(row.index)}>
                    <Delete />
                  </IconButton>
                </Fragment>
              )}
              type=""
              noEmptyCells
              initialSort="category"
              headCells={headCells}
              rows={project.stoffstrom
                .map((x, index) => ({ ...x, index, categoryName: categoryNames.get(x.category) || "(Unbekannt)" }))
                .filter((x) => !x.deleted && x.type === "REQUEST")}
            />
          </Paper>
        ) : null}
      </MainContainer>
    </Box>
  );
}
