import ApiService from "@/api/ApiService";
import LabelWrapper from "@/components/root/LabelWrapper";
import NewRangePicker from "@/shared/forms/NewRangePicker";
import {
  Call,
  ChatBubble,
  Check,
  Delete,
  DoneAll,
  Edit,
  Info,
  People,
  PeopleAlt,
  Search,
  Signpost,
  Tag,
  ThumbDown,
} from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export const Deleter = ({ date, bookingId, deleteMutation }) => {
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);

  return (
    <Fragment><div onClick={() => setOpen(true)}
      className="resOrderButton red"
      
    >
      <Delete fontSize="inherit" />
    </div>
    {open ? (
        <Dialog
          open={true}
          maxWidth="sm"
          onClose={() => setOpen(false)}
        >
          <DialogTitle>Buchung löschen</DialogTitle>
          <DialogContent>
            
          <NewRangePicker small
              onChange={([startDate, endDate]) => {
                setStartDate(!!startDate ? startDate.format("YYYY-MM-DD") : null);
                setEndDate(!!endDate ? endDate.format("YYYY-MM-DD") : null);
              } }
              value={[
                dayjs(startDate, "YYYY-MM-DD"),
                dayjs(endDate, "YYYY-MM-DD"),
              ]} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Abbrechen</Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setOpen(false);
                deleteMutation.mutate({id: bookingId, start: startDate, end: endDate});}
              }
            >
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    
    </Fragment>
  );
};

export const Rejector = ({ date, orderId }) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const queryClient = useQueryClient();

  const saveMutation = useMutation<any, AxiosResponse, any>({
    mutationFn: ApiService.createEntity("resourceOrderRejection"),
    onSuccess: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      queryClient.invalidateQueries({ queryKey: ["getKGP"] });
      setOpen(false);
    },
  });

  return (
    <Fragment>
      <div className="resOrderButton red" onClick={() => setOpen(true)}>
        <ThumbDown fontSize="inherit" />
      </div>
      {open ? (
        <Dialog
          open={true}
          maxWidth="md"
          onClose={() => setOpen(false)}
        >
          <DialogTitle>Anfrage ablehnen</DialogTitle>
          <DialogContent>
            <TextField
              size="small"
              fullWidth
              multiline
              value={comment}
              placeholder="Beschreibung"
              onChange={(e) => setComment((e.target as HTMLInputElement).value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Abbrechen</Button>
            <Button
              variant="contained"
              color="error"
              onClick={() =>
                saveMutation.mutate({ date, resourceOrder: orderId, comment })
              }
            >
              Ablehnen
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Fragment>
  );
};

const Information = ({ data, did }) => {
  return (
    <Fragment>
      <div
        className={
          "vc-res-status color " +
          (!!data.orderSentAt
            ? "darkgreen"
            : data.status === "PLANNING"
            ? "blue"
            : data.status === "RAW"
            ? "yellow"
            : data.status === "READY"
            ? "orange"
            : "green")
        }
      />
      <div className="infocomp">
        <div className="name">
          {data.phase.project.name + " (" + data.phase.project.number + ")"}
        </div>
        {!!data.resourceOrder ? (
          <Fragment>
            {!!data.orderSentBy ? (
              <div className="info">
                <Call fontSize="inherit" />
                <div className="txt">
                  {data.orderSentBy.firstName + " " + data.orderSentBy.name}
                </div>
              </div>
            ) : null}
            {data.resourceOrder.comment.length > 0 ? (
              <div className="info">
                <ChatBubble fontSize="inherit" />
                <div className="txt">{data.resourceOrder.comment}</div>
              </div>
            ) : null}
          </Fragment>
        ) : did in data.crews && Object.keys(data.crews).length === 1 ? null : (
          <Fragment>
            <div className="info">
              <PeopleAlt fontSize="inherit" />
              <div className="txt">{Object.values(data.crews).join("; ")}</div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default function ResInner({
  elements,
  did,
  i,
  readOnly,
  changeMutation,
  setJobInfoId,
  deleteMutation,
}) {
  const navigate = useNavigate();
  const dates = Object.keys(elements).sort();
  const date = dates[0];
  const element = !!elements[date][i] ? elements[date][i].element : null;

  if (!element) return null;

  return (
    <Fragment>
      <Information data={element} did={did} />
      {!!changeMutation ? (
        <div className="resOrderEdit">
          <div className="resOrderEditTopPart">
            {!readOnly &&
            !!element.resourceOrder &&
            !element.resourceOrder.singleDayOrder ? (
              did !== 0 ? (
                <div
                  onClick={() =>
                    changeMutation.mutate({
                      move: null,
                      orderToBooking: {
                        order: element.resourceOrder.id,
                        startDate: date,
                        jobId: null,
                        target: did,
                      },
                      bookingMove: null,
                    })
                  }
                  className="resOrderButton green"
                >
                  <DoneAll fontSize="inherit" />
                  {!!element?.resourceOrder?.endDate ? (
                    <div className="until">
                      &rarr;{element?.resourceOrder?.endDate}
                    </div>
                  ) : null}
                </div>
              ) : !!element?.resourceOrder?.endDate ? (
                <div className="until2">
                  &rarr;{element?.resourceOrder?.endDate}
                </div>
              ) : null
            ) : null}
          </div>
          <div className="resOrderEditBottomPart">
            {dates.map((datex) =>
              !!elements[datex][i] && !!elements[datex][i].element ? (
                <div className="resOrderEditDatePart" key={datex}>
                  <div
                    className="resOrderButton"
                    onClick={() => setJobInfoId(elements[datex][i].element.id)}
                  >
                    <Search fontSize="inherit" />
                  </div>
                  {!readOnly && !!elements[datex][i].element.resourceBooking ? (
                    <Deleter
                      date={elements[datex][i].element.date}
                      bookingId={elements[datex][i].element.resourceBooking.id}
                      deleteMutation={deleteMutation}
                    />
                  ) : !readOnly &&
                    did !== 0 &&
                    !!elements[datex][i].element.resourceOrder ? (
                    <div
                      className="resOrderButton green"
                      onClick={() =>
                        changeMutation.mutate({
                          move: null,
                          orderToBooking: {
                            order: elements[datex][i].element.resourceOrder.id,
                            jobId: elements[datex][i].element.id,
                            target: did,
                          },
                          bookingMove: null,
                        })
                      }
                    >
                      <Check fontSize="inherit" />
                    </div>
                  ) : null}
                  {!readOnly && !!elements[datex][i].element.resourceOrder ? (
                    <Rejector
                      date={elements[datex][i].element.date}
                      orderId={elements[datex][i].element.resourceOrder.id}
                    />
                  ) : null}
                </div>
              ) : null
            )}
          </div>
        </div>
      ) : (
        false
      )}
    </Fragment>
  );
}
