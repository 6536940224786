import {
  Box,
  Paper,
} from "@mui/material";
import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { useEffect } from "react";
import ProjectList from "./ProjectList";
import ProjectMap from "./ProjectMap";
import useRights from "@/shared/api/useRights";
import { Navigate } from "react-router-dom";
import Overdue from "./Overdue";



export default function TestList({}) {
  const region = usePersistantStore(store=>store.region);

  const setPageTitle = useGlobalStore((state) => state.setPageTitle);

  useEffect(() => setPageTitle("Start"), []);

  const rights = useRights();
  if (("PLANT" in rights)) return <Navigate replace to="/plants" />;

  return (
    <Box
      sx={{
        flex: 1,
        display: "grid",
        overflow: "hidden",
        background: "#eee",
        padding: 2,
        gap: 2,
        gridTemplateColumns: "repeat( auto-fit, minmax(350px, 2fr) )",
      }}
    >
       <Overdue />
        <ProjectList filter={{manager: "%USER", active: true}} title="Meine Projekte" />
        <ProjectList filter={!!region ? {region: ["region",region], active: true} : {active: true}} title="Alle Projekte" />
        {false ? <ProjectMap region={region} /> : null}
    </Box>
  );
}
