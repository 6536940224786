import BaseApiService from "@/shared/api/BaseApiService";
import ErrorDialog from "@/shared/dialogs/ErrorDialog";
import ErrorIndicator from "@/shared/dialogs/ErrorIndicator";
import { MainContainer } from "@/shared/structure/MainContainer";
import { TopBar } from "@/shared/structure/TopBar";
import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { Save, Cancel } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress } from "@mui/material";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import definitions, { SingleDefinition } from "./definitions";
import DynamicField from "./DynamicField";
const nothing = (a) => a;

export default function SettingsEditor({}) {
  const { type, rawId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);
  const region = usePersistantStore((state) => state.region);

  const definition = definitions[type] as SingleDefinition;

  if (!type || (definition.regionalized && region === 0))
    return <Navigate replace to="/" />;

  const id = !rawId ? 0 : parseInt(rawId);

  const [object, setObject] = useState(
    Object.fromEntries(
      definition.fields
        .map((x) => [x.id, x.default])
        .concat(definition.regionalized ? [["region", region]] : [])
    )
  );

  const [shouldFetch, setShouldFetch] = useState(id > 0);

  useEffect(
    () =>
      setPageTitle(
        definition.title + ": " + (id > 0 ? "Bearbeiten" : "Erstellen")
      ),
    [definition.title, id]
  );

  const query = useQuery<any, AxiosResponse>({
    queryKey: ["get" + type[0].toUpperCase() + type.slice(1), "" + id],
    queryFn: BaseApiService.getEntity(type, id, true, "settings"),
    enabled: shouldFetch,
  });


  useEffect(() => {
    if (!query.isSuccess || !query.data) return;
    setShouldFetch(false);
    setObject(query.data);
  }, [query.data]);

  const mutation = useMutation<any, AxiosResponse, any>({
    mutationFn:
      id > 0
        ? BaseApiService.editEntity(type, id, "settings")
        : BaseApiService.createEntity(type, true, { v: "settings" }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["get" + type[0].toUpperCase() + type.slice(1) + "s"],
      });
      if (id > 0){
        queryClient.invalidateQueries({
          queryKey: ["get" + type[0].toUpperCase() + type.slice(1), "" + id],
        });
      }
      navigate(-1);
    },
  });

  console.log("CURRENT OBJECT",object);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        bgcolor: "background.paper",
        overflow: "hidden",
      }}
    >
      <TopBar>
        <LoadingButton
          onClick={() => mutation.mutate(object)}
          loading={mutation.status === "pending"}
          variant="text"
          disabled={(!query.isError && shouldFetch) || query.isError}
          startIcon={<Save />}
        >
          Speichern
        </LoadingButton>
        <Button
          onClick={() => navigate(-1 /*"/settings/users"*/)}
          variant="text"
          startIcon={<Cancel />}
        >
          Abbrechen
        </Button>
      </TopBar>
      <MainContainer>
        {!query.isError && shouldFetch ? (
          <Box
            sx={{
              display: "flex",
              padding: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : query.isError ? (
          <ErrorIndicator />
        ) : (
          <Box>
            {definition.fields.map((field) => (
              <DynamicField
                relation={
                  !field.relation
                    ? undefined
                    : typeof field.relation === "string"
                    ? field.relation
                    : field.relation(object)
                }
                disabled={
                  (id > 0 && !!field.disableOnEdit) ||
                  (!!field.disabledFn && field.disabledFn(object))
                }
                value={object[field.id]}
                region={region}
                setObject={setObject}
                key={field.id}
                field={field}
              />
            ))}
          </Box>
        )}
      </MainContainer>
      <ErrorDialog forceOpen={query.isError && query.error.status === 401} />
    </Box>
  );
}
