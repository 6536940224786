type TreeNode = {
    name: string;
    type: "category";
    children: TreeNode[] | ObjectNode[];
  };
  
  type ObjectNode = {
    name: string;
    id: number;
    superCategory: string;
  };
  
  type InputNode = ObjectNode;
  
  // Main function to build the tree
  export default function buildTree(input: InputNode[]): (TreeNode | ObjectNode)[] {
    const root: (TreeNode | ObjectNode)[] = [];
  
    // Helper function to find or create a category node
    function findOrCreateCategoryNode(
      children: (TreeNode | ObjectNode)[],
      categoryName: string
    ): TreeNode {
      // Look for an existing category
      let categoryNode = children.find(
        (node) => node.name === categoryName && node.type === "category"
      ) as TreeNode;
  
      // If not found, create it and push into children
      if (!categoryNode) {
        categoryNode = { name: categoryName, type: "category", children: [] };
        children.push(categoryNode);
      }
  
      return categoryNode;
    }
  
    // Step through each input object
    for (const obj of input) {
      const categories = obj.superCategory
        ? obj.superCategory.split(";")
        : [];
      let currentLevel: (TreeNode | ObjectNode)[] = root;
  
      // Traverse through the category path
      for (const category of categories) {
        const categoryNode = findOrCreateCategoryNode(currentLevel, category.trim());
        currentLevel = categoryNode.children;
      }
  
      // Add the object at the appropriate leaf level
      currentLevel.push(obj);
    }
  
    // Helper function to sort the tree recursively
    function sortTree(nodes: (TreeNode | ObjectNode)[]) {
      nodes.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
  
      // Recurse into children if it's a category node
      for (const node of nodes) {
        if ((node as TreeNode).type === "category") {
          sortTree((node as TreeNode).children);
        }
      }
    }
  
    // Sort the entire tree starting from the root
    sortTree(root);
  
    return root;
  }
  
