import dayjs from "dayjs";
import Project, { ProjectPhase, ProjectState } from "../Project";
import { CalendarContext, CalendarElementsProps } from "../../shared/VerticalCalendar";
import { Fragment } from "react/jsx-runtime";
import { useState, useLayoutEffect, useMemo, useContext, useEffect } from "react";
import ProjectPhaseLine, { moveJobs } from "./ProjectPhaseLine";
import useRights from "@/shared/api/useRights";

const blankImage = new Image();
blankImage.src =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";

export default function PlanningCalendar(
  props: {
    setDateChangeNecessary: any;
    project: ProjectState;
    setField: (changes: Partial<ProjectState>, createBreakpoint?: boolean) => void;
    currentSelection: any;
    lockOrder: boolean;
    setCurrentSelection: any;
    weekplanning: boolean;
    setWeekplanning: (wk:boolean) => void;
  }
) {

  const view = useContext(CalendarContext);
  const [shouldBe, setShouldBe] = useState(true);

  useEffect(() => {
    setShouldBe(!!view?.zoom && view?.zoom < 25);
  }, [view.zoom]);

  useEffect(() => {
    props.setWeekplanning(shouldBe);
  }, [shouldBe]);

  const onDateChange = (plannedStart, plannedEnd, moveAdd) => {
    const phases = props.project.phases.map((phase) => ({
      ...phase,
      plannedStart: dayjs(phase.plannedStart)
        .add(moveAdd, "days")
        .format("YYYY-MM-DD"),
      changed: true,
      plannedEnd: dayjs(phase.plannedEnd)
        .add(moveAdd, "days")
        .format("YYYY-MM-DD"),
      jobs: moveJobs(phase.jobs, moveAdd),
    }));
    props.setField({
      plannedStart,
      plannedEnd,
      phases,
    },true);
  };

  const [startMax, endMin] = useMemo(() => {
    if (!props.project.plannedStart || !props.project.plannedEnd) return [0, 0];

    const ps = dayjs(props.project.plannedStart,"YYYY-MM-DD");
    const pe = dayjs(props.project.plannedEnd,"YYYY-MM-DD");

    const min = props.project.phases.reduce<string | null>((min, c) => (!min || c.plannedStart < min ? c.plannedStart : min), null);
    const max = props.project.phases.reduce<string | null>((max, c) => (!max || c.plannedEnd > max ? c.plannedEnd : max), null);

    if (!max || !min) {
      const s = pe.diff(ps, "days");
      return [s, -s];
    } else {
      return [
        dayjs(min,"YYYY-MM-DD").diff(ps,"days"),
        dayjs(max,"YYYY-MM-DD").diff(pe,"days")
      ]
    }
  }, [props.project.phases, props.project.plannedEnd, props.project.plannedStart]);


  const setPhaseField = (
    index: number,
    changes: Partial<ProjectPhase>,
    isDateChange: boolean = false
  ) => {
    const phases = props.project.phases.slice();
    const topChange = Object.keys(changes).some(key => !["jobs","resourceOrders"].includes(key));
    phases[index] = {
      ...phases[index],
      ...changes,
      changed: !!phases[index].changed || topChange
    };
    props.setField({ phases },true);
    if (isDateChange)
      props.setDateChangeNecessary(true);
  };

  const [startAdd, setStartAdd] = useState(0);
  const [moveAdd, setMoveAdd] = useState(0);
  const [endAdd, setEndAdd] = useState(0);
  const [startEvent, setStartEvent] = useState<{
    pageX: number;
    point: "start" | "end" | "move";
  } | null>(null);

  const rights = useRights();
  const isDispo = "DISPOSITION" in rights;
  let canBeMoved = isDispo;
  if (!canBeMoved){
    canBeMoved = true;
    for (let phase of props.project.phases){
      if (phase.deleted) continue;
    for( let job of Object.values(phase.jobs)){
      if (job.deleted) continue;
      if (job.status !== "PLANNING"){
        canBeMoved = false;
        break;
      }
    }
  }
  }

  useLayoutEffect(() => {
    setStartAdd(0);
    setMoveAdd(0);
    setEndAdd(0);
  }, [props.project.plannedStart, props.project.plannedEnd]);

  const start = dayjs(props.project.plannedStart);
  const end = dayjs(props.project.plannedEnd);

  const dragstart = (e, point) => {
    e.dataTransfer.setDragImage(blankImage, 0, 0);
    e.dataTransfer.effectAllowed = "move";
    e.target.style.cursor = "ew-resize !important";
    setStartEvent({ pageX: e.pageX, point });
  };

  const mouseMove = (pageX: number) => {
    if (pageX === 0) return;
    const factor = view.zoom < 15 ? 7 : 1;
    if (startEvent.point === "move") {
      const v =
        Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor;
      setMoveAdd(v);
    } else if (startEvent.point === "start") {
      setStartAdd(
        Math.min(startMax, Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor)
      );
    } else {
      setEndAdd(
        Math.max(endMin, Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor)
      );
    }
  };

  const mouseUp = (pageX: number) => {
    if (pageX === 0) return;
    const factor = view.zoom < 15 ? 7 : 1;
    if (startEvent.point === "move") {
      const v =
        Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor;
      setMoveAdd(v);
    } else if (startEvent.point === "start") {
      setStartAdd(
        Math.min(startMax, Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor)
      );
    } else {
      setEndAdd(
        Math.max(endMin,Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor)
      );
    }
    onDateChange(
      start.add(startAdd+moveAdd, "days").format("YYYY-MM-DD"),
      end.add(endAdd+moveAdd, "days").format("YYYY-MM-DD"),
      moveAdd
    );
    setStartEvent(null);
  };

  let startOffset = view.offset + 4;
  const astart = startAdd+moveAdd === 0 ? start : start.add(startAdd+moveAdd, "days");
  const aend = endAdd+moveAdd === 0 ? end : end.add(endAdd+moveAdd, "days");

  const xstart =
    astart > view.calEnd
      ? null
      : astart < view.calStart
      ? view.calStart
      : astart;
  const xend =
    aend < view.calStart ? null : aend > view.calEnd ? view.calEnd : aend;

  return (
    <Fragment>
      <div
        key={"sidebar" + startOffset}
        className={"vc-sidebar project"}
        style={{
          gridRow: startOffset,
        }}
      >
        {props.project.name}
      </div>
      {!xstart || !xend ? null : (
        <div
          className={"vc-project"}
          key={"project" + startOffset}
          style={{
            display: "flex",
            gridRow: startOffset,
            gridColumn:
              xstart.diff(view.calStart, "days") +
              2 +
              " / " +
              (xend.diff(view.calStart, "days") + 3),
          }}
        >
          <div
            className="vc-reshandle left"
            draggable={props.project.writeable}
            onDragOver={(e) => e.preventDefault()}
            onDragStart={(e) => dragstart(e, "start")}
            onDrag={(e) => (!startEvent ? null : mouseMove(e.pageX))}
            onDragEnd={(e) => (!startEvent ? null : mouseUp(e.pageX))}
          />
          <div
            className="vc-reshandle move"
            draggable={props.project.writeable && canBeMoved}
            onDragOver={(e) => e.preventDefault()}
            onDragStart={(e) => dragstart(e, "move")}
            onDrag={(e) => (!startEvent ? null : mouseMove(e.pageX))}
            onDragEnd={(e) => (!startEvent ? null : mouseUp(e.pageX))}
          />
          <div
            className="vc-reshandle right"
            draggable={props.project.writeable}
            onDragOver={(e) => e.preventDefault()}
            onDragStart={(e) => dragstart(e, "end")}
            onDrag={(e) => (!startEvent ? null : mouseMove(e.pageX))}
            onDragEnd={(e) => (!startEvent ? null : mouseUp(e.pageX))}
          />
        </div>
      )}
      <div
        className="vc-hl"
        key={"hl" + startOffset}
        style={{
          gridRow: startOffset,
          gridColumn:
            2 + " / " + (view.calEnd.diff(view.calStart, "days") + 2),
        }}
      ></div>
      {props.project.phases.map((phase, index) => ({...phase, index})).sort((a,b) => (a.plannedStart??"ZZZ").localeCompare(b.plannedStart??"ZZZ")).map(phase => 
        !!phase.deleted ? null : (
          <ProjectPhaseLine
            project={props.project}
            key={"phase" + phase.index}
            line={(startOffset += 2)}
            projectStartAdd={moveAdd}
            phaseId={phase.index}
            weekplanning={props.weekplanning}
            currentSelection={
              props.currentSelection
            }
            lockOrder={props.lockOrder}
            setField={setPhaseField}
            setCurrentSelection={props.setCurrentSelection}
          />
        )
      )}
    </Fragment>
  );
}
