import ApiService from "@/api/ApiService";
import {
  Construction,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowRight,
  People,
  Person,
  ThumbUpSharp,
} from "@mui/icons-material";
import { CircularProgress, Box, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { Fragment } from "react/jsx-runtime";

export default function CollisionContainer({ query, setCalendar }) {
  const [open, setOpen] = useState<Set<string>>(new Set());
  const toggle = (value: string) => {
    setOpen((prev) => {
      const newSet = new Set(prev); // Create a copy of the current Set
      if (newSet.has(value)) {
        newSet.delete(value); // Remove the value if it exists
      } else {
        newSet.add(value); // Add the value if it doesn't exist
      }
      return newSet; // Return the updated Set
    });
  };

  const queryClient = useQueryClient();

  const acceptM = useMutation<any, AxiosResponse, any>({
    mutationFn:
      ApiService.createEntity("acceptedCollision", true),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getOpenRequests","collisions"],
      });
    },
  });

  const deleteM = useMutation<any, AxiosResponse, any>({
    mutationFn:
      ApiService.deleteEntity("resourceBooking", 0),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getOpenRequests"],
      });
    },
  });



  return query.isLoading ? (
    <CircularProgress />
  ) : query.isLoadingError ? (
    "Error"
  ) : (
    (query?.data ?? []).map((x, i, f) => (
      <Fragment key={x.date + "_" + x.tag_id + "_" + x.id}>
        {i === 0 || f[i - 1].date !== x.date ? (
          <Box
            sx={{
              position: "sticky",
              top: 0,
              color: "#555",
              p: "4px 6px",
              lineHeight: 1,
              height: "26px",
              fontWeight: "14px",
              flexShrink: 0,
              background: "#edf0f9",
              borderTop: "1px solid rgba(0,0,0,0.2)",
              borderBottom: "1px solid rgba(0,0,0,0.2)",
              boxShadow: "inset 0px -4px 5px rgba(0,0,0,0.1)",
            }}
          >
            {dayjs(x.date).format("DD.MM.YYYY")}
          </Box>
        ) : null}
        {i === 0 || f[i - 1].tag_name !== x.tag_name ? (
          <Box
            style={
              open.has(x.date + "-" + x.tag_id)
                ? { borderBottom: "1px solid #aaa" }
                : {}
            }
            sx={{
              position: "sticky",
              top: "26px",
              background: "white",
              userSelect: "none",
              gap: "5px",
              fontSize: "14px",
              lineHeight: 1,
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              borderTop: "1px solid #aaa",
              cursor: "pointer",
              "&:hover": {
                background: "#eee",
              },
            }}
          >
            <Box
              onClick={() => toggle(x.date + "-" + x.tag_id)}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {open.has(x.date + "-" + x.tag_id) ? (
                <KeyboardArrowDown fontSize="inherit" />
              ) : (
                <KeyboardArrowRight fontSize="inherit" />
              )}
            </Box>
            <Box
              onClick={() => toggle(x.date + "-" + x.tag_id)}
              sx={{ flex: 1, p: "2px 0px" }}
            >
              {x.tag_name}
            </Box>
            <Box
              onClick={() =>
                setCalendar(
                  x.type === "CREW"
                    ? "CREWS"
                    : x.type === "RESOURCE"
                    ? "RESOURCES"
                    : "STAFF",
                  x.tag_id,
                  dayjs(x.date, "YYYY-MM-DD")
                )
              }
              sx={{
                display: "flex",
                background: "#fff",
                "&:hover": { background: "#002594", color: "white" },
                alignItems: "center",
                borderLeft: "1px solid #aaa",
                p: "0px 2px",
                alignSelf: "stretch",
              }}
            >
              {x.type === "CREW" ? (
                <People fontSize="inherit" />
              ) : x.type === "RESOURCE" ? (
                <Construction fontSize="inherit" />
              ) : (
                <Person fontSize="inherit" />
              )}
            </Box>
          </Box>
        ) : null}
        {open.has(x.date + "-" + x.tag_id) ? (
          <div className="collibox">
            <div className="collires">
            <div className="colliresname">{!!x.number ? <span className="colliresnumber">{x.number}</span> : null}
              {x.name}</div>
              <div className="colliaccept" onClick={()=>acceptM.mutate({targetId: x.id, type: x.type, date: x.date})}><ThumbUpSharp fontSize="inherit" /></div>
            </div>
            <div className="collidetails">
              {(x.projects ?? "").split("$").map((y) => {
                if (y.length === 0) return null;
                const [id, name, number, phase] = y.split("#");
                return (
                  <div className="colliproj">
                    <div className="collipname"><span className="collipnumber">{number}</span>{name}<span className="collipphase">({phase})</span></div>
                    <div className="collidel" onClick={() => deleteM.mutate(id)}><Delete  fontSize="inherit" /></div>
                  </div>
                );
              })}
              {(x.vacations ?? "")
                .split("$")
                .map((y) => {
                  if (y.length === 0) return null;
                  const [id, name] = y.split("#");
                  return (
                    <div className="colliproj">
                      <div className="collipname">{name}</div>
                    </div>
                  );
                })}
                
            </div>
            
          </div>
        ) : null}
      </Fragment>
    ))
  );
}
