import { Fragment } from "react/jsx-runtime";
import { CalDataLine, CalJob, CalProject } from "./InnerCalendar";
import {
  CalendarContext,
  CalendarElementsProps,
} from "@/components/shared/VerticalCalendar";
import { useContext } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Edit } from "@mui/icons-material";

export default function InnerCalendar({
  offset,
  data,
  readOnly
}: {
  offset: number;
  readOnly: boolean;
  data: CalDataLine<CalProject, CalJob>;
}) {
  const navigate = useNavigate();
  const view = useContext(CalendarContext);
  const days = [];
  const ostart = dayjs(data.plannedStart);
  const oend = dayjs(data.plannedEnd);
  const xstart = ostart < view.calStart ? view?.calStart : ostart;
  const xend = oend > view.calEnd ? view?.calEnd : oend;

  

  for (let date in data.elements) {
    const dd = dayjs(date, "YYYY-MM-DD");

    if (dd < view?.calStart || dd > view?.calEnd) continue;

    for (let i = 0; i < data.elements[date].length; i++) {
      const element = data.elements[date][i];

      days.push(
        <div
          className={"vc-date color "+(!!element.orderSentAt ? "darkgreen" : element.status === "PLANNING"
            ? "blue"
            : element.status === "RAW"
            ? "yellow"
            :element.status === "READY"
            ? "orange"
            : "green")}
          data-date={date}
          key={"phase" + date + i}
          style={{
            display: "flex",
            justifyContent: "space-between",
            gridRow: offset,
            gridColumn: dd.diff(view.calStart, "days") + 2,
          }}
        > <div className="resOrderEdit">
        <div className="resOrderButton">
          <Edit
            onClick={() => navigate("/project/" + data.id, {state: {phaseId: element.phase.id, date}})}
            fontSize="inherit"
          />
        </div>
      </div></div>
      );
    }
  }

  return (
    <Fragment>
      <div
        key={"sidebar" + offset}
        className={"vc-sidebar project"}
        style={{
          gridRow: offset,
        }}
      >
        {!!data?.number ? data?.number + ": " : ""}
        {data.name}
      </div>

      {days}
      <div
        className="vc-hl"
        key={"hl" + offset}
        style={{
          gridRow: offset,
          gridColumn: 2 + " / " + (view.calEnd.diff(view.calStart, "days") + 2),
        }}
      ></div>
    </Fragment>
  );
}
