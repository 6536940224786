import useResizeObserver from "@react-hook/resize-observer";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  cloneElement,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import dayjs from "dayjs";
import {
  ZoomIn,
  ZoomOut,
  Today,
  Add,
  ArrowLeft,
  ArrowRight,
  ArrowBack,
  ArrowForward,
  ArrowBackIos,
  ArrowForwardIos,
  ChevronLeft,
  ChevronRight,
  SwipeDownAlt,
} from "@mui/icons-material";


import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { deDE } from "@mui/x-date-pickers/locales";
import { useLazyEffect } from "@/shared/helpers/useLazyEffect";
import ApiService from "@/api/ApiService";
import { usePersistantStore } from "@/stores/global";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { ProjectState } from "../planning/Project";


export const CalendarContext = createContext<CalendarElementsProps | null>(
  null
);

function SelectCalendarPositionMenu({ setCalStart, calStart }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [cdate, setCdate] = useState(calStart);
  const [userDidUpdate, setUserDidUpdate] = useState(false);
  useEffect(() => setCdate(calStart),[calStart]);
  useLazyEffect(() => {if (!userDidUpdate) return; setCalStart(cdate);setAnchorEl(null); setUserDidUpdate(false);},[userDidUpdate, cdate],500);
  return (
    <div>
      <LocalizationProvider
      adapterLocale="de"
      dateAdapter={AdapterDayjs}
      localeText={
        deDE.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Today />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setCalStart(dayjs().subtract(6, "days").startOf("isoWeek"));
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <SwipeDownAlt fontSize="small" />
          </ListItemIcon>
          <ListItemText>Heute</ListItemText>
        </MenuItem>
        <ListItem>
        <DatePicker
          slotProps={{ textField: { size: "small" } }}
          onChange={x => {setCdate(x); setUserDidUpdate(true);}}
          value={cdate}
          displayWeekNumber
        />
        </ListItem>
      </Menu>
      </LocalizationProvider>
    </div>
  );
}

export interface CalendarElementsProps {
  zoom: number;
  calStart: dayjs.Dayjs;
  calEnd: dayjs.Dayjs;
  offset: number;
  safeStart: dayjs.Dayjs;
  safeEnd: dayjs.Dayjs;
  viewStart: dayjs.Dayjs;
  viewEnd: dayjs.Dayjs;
  loadStart: dayjs.Dayjs;
  loadEnd: dayjs.Dayjs;
}

export default forwardRef(
  (
    {
      initialCalStart = dayjs().startOf("isoWeek"),
      initialCalEnd = dayjs().add(4, "week"),
      children,
      dateButtons,
      buttons,
      maxHeight,
    }: {
      maxHeight?: string;
      children: JSX.Element;
      initialCalStart?: dayjs.Dayjs;
      initialCalEnd?: dayjs.Dayjs;
      buttons: JSX.Element;
    },
    ref
  ) => {
    const region = usePersistantStore(store=>store.region);
    const target = useRef(null);
    const sidebar = useRef<HTMLElement>(null);

    const [width, setWidth] = useState(0);
    const [sidebarWidth, setSidebarWidth] = useState(0);
    const [calStart, setCalStart] = useState(initialCalStart);

    const holidayQuery = useQuery<{date: string}[], AxiosResponse>({
      queryKey: ["getHolidays", region],
      queryFn: ApiService.getEntitiesWithFilter("holiday", !!region ? {region: ["region",region]} : {}),
    });

    const holidays = useMemo(() => {
      if (!holidayQuery.data) return new Set([]);
      return new Set(holidayQuery.data.map(x => x.date));
    },[holidayQuery.data])

    const setInitialZoom = () => {
      if (!target.current || !sidebar.current) return;
      try {
        const value = Math.floor(
          (target.current.clientWidth - sidebar.current.clientWidth) /
            initialCalEnd.diff(initialCalStart, "days")
        );
        if (isNaN(value)) return;
        setZoom(Math.min(150, Math.max(3.5, value)));
      } catch (e) {
        return;
      }
    };

    useImperativeHandle(ref, () => ({
      setCalendar: (start: dayjs.Dayjs, end: dayjs.Dayjs) =>
        setCalendar(start, end),
    }));

    const setCalendar = (start, end) => {
      if (!target.current || !sidebar.current) return;
      try {
        const value = Math.floor(
          (target.current.clientWidth - sidebar.current.clientWidth) /
            end.diff(start, "days")
        );
        if (isNaN(value)) return;
        setCalStart(start);
        setZoom(Math.min(150, Math.max(3.5, value)));
      } catch (e) {
        return;
      }
    };

    useEffect(() => {
      setTimeout(setInitialZoom, 500);
    }, []);

    const [zoom, setZoom] = useState(50);

    const view = useMemo(() => {
      const visibleDays = (width - sidebarWidth) / zoom;
      const sidebarDays = sidebarWidth / zoom;
      const loadPadding = (width / zoom) * 0.3;
      const viewPadding = (width / zoom) * 0.6;
      return {
        safeStart: calStart.subtract(Math.floor(loadPadding), "days"),
        safeEnd: calStart.add(Math.ceil((width / zoom) * 1.3), "days"),
        viewStart: calStart,
        viewEnd: calStart.add(visibleDays, "days"),
        zoom,
        calStart: calStart
          .subtract(Math.floor(viewPadding + sidebarDays), "days")
          .startOf("week"),
        calEnd: calStart.add(Math.ceil(visibleDays + viewPadding), "days"),
        loadStart: calStart.subtract(
          Math.floor(loadPadding + sidebarDays),
          "days"
        ),
        offset: Math.round(Math.random() * 100),
        loadEnd: calStart.add(Math.ceil(visibleDays + loadPadding), "days"),
      };
    }, [calStart, zoom, width, sidebarWidth]);

    useLayoutEffect(() => {
      if (!view) return;
      target.current.scrollLeft = calStart.diff(view.calStart, "days") * zoom;
    }, [view]);

    const onScroll = useCallback(
      (e: React.UIEvent<HTMLElement>) => {
        const currentViewStart = view.calStart.add(
          Math.round(e.currentTarget.scrollLeft / zoom),
          "days"
        );
        const currentViewEnd = view.calStart.add(
          Math.ceil((e.currentTarget.scrollLeft + width - sidebarWidth) / zoom),
          "days"
        );
        if (
          currentViewStart < view.loadStart ||
          currentViewEnd > view.loadEnd
        ) {
          setCalStart(currentViewStart);
        }
      },
      [zoom, view, sidebarWidth]
    );

    useResizeObserver(target, (entry) => {
      setWidth(entry.contentRect.width);
    });

    useResizeObserver(sidebar, (entry) => {
      setSidebarWidth(entry.contentRect.width);
    });

    const [days, lines] = useMemo(() => {
      const days = [];
      const lines = [];
      let lastMonth = "";
      let lastWeek = "";
      for (
        let currentDate = view.calStart;
        currentDate < view.calEnd;
        currentDate = currentDate.add(1, "days")
      ) {
        const weekday = ((parseInt(currentDate.format("d")) + 6) % 7) + 1;
        const month = currentDate.format("MMMM YYYY");
        const week = currentDate.format("W");
        if (lastMonth !== month) {
          days.push(
            <div
              key={month}
              className={
                "vc-month " +
                (parseInt(currentDate.format("M")) % 2 ? "odd" : "even")
              }
              style={{
                gridRow: view.offset + 1,
                gridColumn:
                  currentDate.diff(view.calStart, "days") +
                  2 +
                  " / " +
                  (currentDate.endOf("month").diff(view.calStart, "days") + 3),
              }}
            >
              {month}
            </div>
          );
          lastMonth = month;
        }
        if (lastWeek !== week) {
          days.push(
            <div
              key={week + month}
              className={
                "vc-week " +
                (parseInt(currentDate.format("W")) % 2 ? "odd" : "even")
              }
              style={{
                gridRow: view.offset + 2,
                gridColumn:
                  currentDate.diff(view.calStart, "days") +
                  2 +
                  " / " +
                  (currentDate.endOf("isoWeek").diff(view.calStart, "days") +
                    3),
              }}
            >
              {view.zoom > 8 ? "KW " : ""}
              {week}
              {view.zoom > 5 ? " (" + currentDate.format("D") + ".)" : ""}
            </div>
          );
          lastWeek = week;
        }
        const dday = currentDate.format("YYYY-MM-DD");
        days.push(
          <div
            className={"vc-day d" + weekday + (holidays.has(dday) ? " holiday" : "")}
            key={dday}
            style={{ gridRow: view.offset + 3 }}
          >
            <div className="weekPart">{currentDate.format("dd")}</div>
            <div className="dayPart">{currentDate.format("D")}</div>
            {!!dateButtons ? (
              <div className="dateButtons">
                {dateButtons(dday)}
              </div>
            ) : null}
          </div>
        );

        lines.push(
          <div
            key={"line" + currentDate.format("YYYY-MM-DD")}
            className={"vc-line d" + weekday + (holidays.has(dday) ? " holiday" : "")}
            style={{
              gridRowStart: view.offset + 4,
              gridColumn: currentDate.diff(view.calStart, "days") + 2,
            }}
          ></div>
        );
      }
      return [days, lines];
    }, [view.calStart, view.calEnd, view.zoom, holidays]);

    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      >
        <Box
          sx={{
            background: "#edf0f9",
            border: "1px solid rgba(0,0,0,0.2)",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            display: "flex",
            columnGap: "5px",
            boxShadow:
              "inset 0px -4px 5px rgba(0,0,0,0.1)" /*borderBottom: "none"*/,
          }}
        >
          <Box
            sx={{ display: "flex", flex: 1, alignItems: "center", ml: "5px" }}
          >
            {buttons}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mr: "5px" }}>
            <Button
              onClick={() =>
                setCalendar(
                  view.viewStart.startOf("isoWeek"),
                  view.viewStart.endOf("isoWeek")
                )
              }
            >
              1 WOCHE
            </Button>
            <Button
              onClick={() =>
                setCalendar(
                  view.viewStart.startOf("isoWeek"),
                  view.viewStart.add(1, "week").endOf("isoWeek")
                )
              }
            >
              2 WOCHEN
            </Button>
            <Button
              onClick={() =>
                setCalendar(
                  view.viewStart.startOf("isoWeek"),
                  view.viewStart.add(4, "week").endOf("isoWeek")
                )
              }
            >
              Monat
            </Button>
            <IconButton
              size="small"
              onClick={() => (zoom < 350 ? setZoom(zoom / 0.9) : null)}
            >
              <ZoomIn />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => (zoom > 3.5 ? setZoom(zoom * 0.9) : null)}
            >
              <ZoomOut />
            </IconButton>
            <SelectCalendarPositionMenu calStart={calStart} setCalStart={setCalStart} />
            <IconButton
              size="small"
              onClick={() =>
                setCalStart(
                  view.viewStart.subtract(Math.round(500 / view.zoom), "day")
                )
              }
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              size="small"
              onClick={() =>
                setCalStart(
                  view.viewStart.add(Math.round(500 / view.zoom), "day")
                )
              }
            >
              <ChevronRight />
            </IconButton>
          </Box>
        </Box>

        {!!view ? (
          <CalendarContext.Provider value={view}>
            <div
              ref={target}
              onScroll={onScroll}
              className={zoom < 15 ? "weekmode" : zoom < 25 ? "nowdmode" : ""}
              style={{
                userSelect: "none",
                width: "100%",
                overflow: "auto",
                ...(!!maxHeight ? {maxHeight} : {}),
                "::-webkit-scrollbar": { display: "none" },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                display: "grid",
                flex: 1,
                gridTemplateColumns:
                  "auto repeat(" + days.length + ", " + view.zoom + "px)",
                gridAutoFlow: "column",
                gridTemplateRows: "auto",
              }}
              onDragOver={(e) => e.preventDefault()}
            >
              <div
                className="vc-ulsidebar"
                style={{
                  position: "sticky",
                  left: 0,
                  gridColumn: 1,
                  gridRowStart: view.offset + 1,
                  gridRowEnd: view.offset + 4,
                }}
                ref={sidebar}
              ></div>

              {days}
              {children}
              {lines}
            </div>
          </CalendarContext.Provider>
        ) : null}
      </Box>
    );
  }
);
