import {
  Button,
  Paper,
  Box,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Divider,
} from "@mui/material";
import PlanningCalendar from "../calendarElements/PlanningCalendar";
import VerticalCalendar from "../../shared/VerticalCalendar";
import dayjs from "dayjs";
import LockIcon from "@mui/icons-material/Lock";
import { Add, Mouse, Redo, Undo } from "@mui/icons-material";
import { Fragment, useMemo, useState } from "react";
import { ProjectJob, ProjectPhase } from "../Project";
import PhaseEditor from "../editors/PhaseEditor";
import DayEditor from "../editors/DayEditor";
import useRights from "@/shared/api/useRights";

interface PlanSelection {
  date: string | null;
  phase: number;
}

export default function PlanningPage({
  project,
  setField,
  initialSelection,
  saveProject,
  setDateChangeNecessary,
  undo,
  redo,
  undoPossible,
  redoPossible,
}) {
  const [currentSelection, setCurrentSelection2] =
    useState<PlanSelection>(initialSelection);

  const [weekplanning, setWeekplanning] = useState(false);
  const [lockOrder, setLockOrder] = useState(true);

  const rights = useRights();

  const setCurrentSelection = (x) => {
    setCurrentSelection2(x);
  };

  const setCurrentPhaseField = (changes: Partial<ProjectPhase>, createBreakpoint?: boolean = false) => {
    const phases = project.phases.slice();
    const topChange = Object.keys(changes).some(key => !["jobs","resourceOrders"].includes(key));
    phases[currentSelection.phase] = {
      ...phases[currentSelection.phase],
      ...changes,
      changed: !!phases[currentSelection.phase].changed || topChange
    };
    console.log("CHANGES", changes);
    setField({ phases },createBreakpoint);
  };

  const setCurrentDayField = (changes: Partial<ProjectJob>, createBreakpoint?: boolean = false) => {
    if (!currentSelection.date) return;
    const phases = project.phases.slice();
    const topChange = Object.keys(changes).some(key => !["materialOrders","resourceOrders"].includes(key));
    phases[currentSelection.phase] = {
      ...phases[currentSelection.phase],
      jobs: {
        ...phases[currentSelection.phase].jobs,
        [currentSelection.date]: {
          ...phases[currentSelection.phase].jobs[currentSelection.date],
          ...changes,
          changed: !!phases[currentSelection.phase].jobs[currentSelection.date].changed || topChange
        },
      },
    };
    setField({ phases },createBreakpoint);
  };

  const addPhase = () => {
    const phases = project.phases.slice();
    phases.push({
      id: 0,
      name: "",
      trade: null,
      uuid: self.crypto.randomUUID(),
      position: null,
      plannedStart: null,
      plannedEnd: null,
      subcontractor: null,
      deleted: false,
      autoFill: false,
      changeAutoFill: true,
      autoFillExceptions: [],
      comment: "",
      jobs: {},
      resourceOrders: [
        /* {
          id: 0,
          deleted: false,
          amount: 1,
          tag: null,
          comment: "",
          type: "CREW",
          references: [null],
          auto: true,
          exceptions: []
        } */
        //TODO AUTOKOLONNE
      ],
    } as ProjectPhase);
    setField({ phases }, true);
    setCurrentSelection({ date: null, phase: phases.length - 1 });
  };

  let phaseExists = false;
  try {
    phaseExists =
      !currentSelection.date &&
      !!project.phases[currentSelection.phase] &&
      !project.phases[currentSelection.phase].deleted;
  } catch (e) {}

  let dayExists = false;
  try {
    dayExists =
      !!currentSelection.date &&
      !project.phases[currentSelection.phase].deleted &&
      !!project.phases[currentSelection.phase].jobs[currentSelection.date] &&
      !project.phases[currentSelection.phase].jobs[currentSelection.date]
        .deleted;
  } catch (e) {}

  const [initialStart, initialEnd] = useMemo(() => {
    if (!!initialSelection.date){
      const start = dayjs(initialSelection.date,"YYYY-MM-DD");
      return [start.subtract(4,"days"),start.add(10,"days")];
    } else if (!!project.plannedStart && !!project.plannedEnd) {
      const pstart = dayjs(project.plannedStart,"YYYY-MM-DD").subtract(4,"days");
      const pstartplus = pstart.add(60,"days");
      const pend = dayjs(project.plannedEnd, "YYYY-MM-DD").add(4,"days");
      const pendminus = pend.subtract(60,"days");
      const today = dayjs().startOf("day").subtract(4,"days");
      const todayPlus = today.add(30,"days");
      if (today <= pstart) {
        return [pstart, pstartplus > pend ? pend : pstartplus];
      } else if (today > pstart && today < pend){
        return [today, todayPlus > pend ? pend:todayPlus];
      } else if (today >= pend){
        return [pendminus < pstart ? pstart : pendminus, pend];
      } else return [dayjs("today").startOf("week"), dayjs("today").endOf("week")];
    } else return [dayjs("today").startOf("week"), dayjs("today").endOf("week")];
  } , []);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        p: "10px",
        rowGap: "10px",
        overflow: "hidden",
      }}
    >
      {navigator.userAgent.toLowerCase().includes("firefox") ? (
        <Alert sx={{ mb: "10px" }} variant="filled" severity="warning">
          Der Kalender funktioniert im Firefox Browser leider nur eingeschränkt.
          Wir empfehlen deshalb Google Chrome oder Microsoft Edge.
        </Alert>
      ) : null}
      <Paper sx={{ overflow: "hidden" }}>
        <VerticalCalendar
          maxHeight={"40vh"}
          initialCalEnd={initialEnd}
          initialCalStart={initialStart}
          buttons={
            <div className="buttonbarplanning">
              {project.writeable ? (
                <Button size="small" onClick={addPhase} startIcon={<Add />}>
                  Bauphase
                </Button>
              ) : (
                <div />
              )}
              <div style={{ display: "flex" }}>
                <Divider orientation="vertical" flexItem />
                <IconButton disabled={!undoPossible} onClick={undo}>
                  <Undo />
                </IconButton>
                <IconButton onClick={redo} disabled={!redoPossible}>
                  <Redo />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <div className="buttonbarmouse">
                  <Mouse />
                  <ToggleButtonGroup
                    exclusive
                    value={weekplanning}
                    size="small"
                    onChange={(_, weekplanning) => {
                      if (weekplanning !== null) setWeekplanning(weekplanning);
                    }}
                  >
                    <ToggleButton value={true}>Woche</ToggleButton>
                    <ToggleButton value={false}>Tag</ToggleButton>
                  </ToggleButtonGroup>
                  <Divider orientation="vertical" flexItem />
                  {"DISPOSITION" in rights ? (
                    <Fragment>
                      <ToggleButton
                        size="small"
                        value="check"
                        selected={lockOrder}
                        onChange={() =>
                          setLockOrder((prevSelected) => !prevSelected)
                        }
                      >
                        <LockIcon fontSize="small" />
                      </ToggleButton>
                      <Divider orientation="vertical" flexItem />
                    </Fragment>
                  ) : null}
                </div>
              </div>
              <div />
            </div>
          }
        >
          <PlanningCalendar
            weekplanning={weekplanning}
            setWeekplanning={setWeekplanning}
            project={project}
            currentSelection={currentSelection}
            setDateChangeNecessary={setDateChangeNecessary}
            setCurrentSelection={setCurrentSelection}
            setField={setField}
            lockOrder={lockOrder}
          />
        </VerticalCalendar>
      </Paper>

      {!phaseExists && !dayExists ? (
        <Box
          sx={{
            alignSelf: "center",
            textAlign: "center",
            maxWidth: "400px",
            p: 1,
            opacity: 0.5,
          }}
        >
          Wähle oder erstelle zuerst eine Bauphase
        </Box>
      ) : phaseExists ? (
        <Paper
          sx={{
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: !phaseExists ? "center" : "initial",
          }}
        >
          <PhaseEditor
            setProjectField={setField}
            project={project}
            setDateChangeNecessary={setDateChangeNecessary}
            phaseId={currentSelection.phase}
            setField={setCurrentPhaseField}
          />
        </Paper>
      ) : (
        <DayEditor
          setProjectField={setField}
          project={project}
          phaseId={currentSelection.phase}
          date={currentSelection.date}
          saveProject={saveProject}
          setField={setCurrentDayField}
          setPhaseField={setCurrentPhaseField}
        />
      )}
    </Box>
  );
}
