import { CalendarContext } from "@/components/shared/VerticalCalendar";
import useRights from "@/shared/api/useRights";
import dayjs from "dayjs";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import useJobHashes from "./useJobHashes";

const blankImage = new Image();
blankImage.src =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";

const preventDefault = (e) => e.preventDefault();

export default function JobLine({
  line,
  onSelect,
  writeable,
  lockOrder,
  startAdd,
  selectedDate,
  jobs,
  changeDates,
}) {

  const view = useContext(CalendarContext);
  const [startPageX, setStartPageX] = useState<number | null>(null);
  const [addMap, setAddMap] = useState<{ [date: string]: string }>({});

  const rights = useRights();
  const isDispo = "DISPOSITION" in rights;

  const letters = useJobHashes(jobs);

  const onSelectDate = (e) => {
    const date = view.calStart
      .add(
        Math.floor(
          (e.clientX - e.target.getBoundingClientRect().left) / view.zoom
        ),
        "days"
      )
      .format("YYYY-MM-DD");
    onSelect(date);
  };

  const onSelectExistingDate = (e) => {
    onSelect(e.target.dataset.date, false);
  };

  const jobkeys = useMemo(
    () =>
      Object.values(jobs)
        .filter((j) => !j.deleted)
        .map((x) => x.date),
    [jobs]
  );
  

  useLayoutEffect(() => {
    console.log("jobs.chng");
    setAddMap({});
  }, [jobs]);

  const dragstart = useCallback(
    (e) => {
      e.dataTransfer.setDragImage(blankImage, 0, 0);
      e.dataTransfer.effectAllowed = "move";
      e.target.style.cursor = "ew-resize !important";
      e.target.classList.add("dragging");
      setStartPageX(e.pageX);
    },
    [setStartPageX]
  );

  const calculateAddMap = useCallback(
    (date: string, fadd: number) => {
      let add = fadd;
      const newAddMap: { [date: string]: string } = {};
      if (!lockOrder && add !== 0){
        const origin = dayjs(date, "YYYY-MM-DD");
        newAddMap[date] = origin
            .add(add, "days")
            .format("YYYY-MM-DD");
      } else if (add !== 0) {
        const origin = dayjs(date, "YYYY-MM-DD");
        const dates = Object.values(jobs)
          .filter((j) => !j.deleted)
          .map((x) => [x.date, isDispo || x.status === "PLANNING"])
          .sort(
            add > 0
              ? (a, b) => a[0].localeCompare(b[0])
              : (a, b) => b[0].localeCompare(a[0])
          );

        if (!isDispo) {
          let count = 0;
          let firstFixed = null;
          for (const [c, moveable] of dates) {
            if ((add > 0 && c <= date) || (add < 0 && c >= date)) continue;
            if (!moveable) {
              firstFixed = dayjs(c, "YYYY-MM-DD");
              break;
            }
            count++;
          }
          let lastPossible = null;
          if (!!firstFixed && count > 0) {
            lastPossible = firstFixed;
            while (true) {
              if (
                (add > 0 && lastPossible.day() === 1) ||
                (add < 0 && lastPossible.day() === 5)
              ) {
                lastPossible = lastPossible.subtract(add > 0 ? 3 : -3, "day");
              } else {
                lastPossible = lastPossible.subtract(add > 0 ? 1 : -1, "day");
              }
              count--;
              if (count <= 0) break;
            }
            lastPossible = lastPossible.subtract(add > 0 ? 1 : -1, "day");
          } else if (!!firstFixed) {
            lastPossible = firstFixed.subtract(add > 0 ? 1 : -1, "day");
          }
          if (!!lastPossible) {
            const xdiff = lastPossible.diff(origin,"days");
            if (add > 0){
              add = Math.min(xdiff,add);
            } else {
              add = Math.max(xdiff,add);
            }
          }
        }

        if (add !== 0) {
          newAddMap[date] = origin
            .add(add, "days")
            .format("YYYY-MM-DD");
          let lastDate = newAddMap[date];
          for (const [c, moveable] of dates) {
            if ((add > 0 && c <= date) || (add < 0 && c >= date)) continue;
            if ((add > 0 && lastDate < c) || (add < 0 && lastDate > c)) break;
            let newDate = dayjs(lastDate, "YYYY-MM-DD").add(
              add > 0 ? 1 : -1,
              "day"
            );
            while (
              newDate.isoWeekday() > 5 &&
              !dates.includes(newDate.format("YYYY-MM-DD"))
            ) {
              newDate = newDate.add(add > 0 ? 1 : -1, "day");
            }
            newAddMap[c] = newDate.format("YYYY-MM-DD");
            lastDate = newAddMap[c];
          }
        }
      }
      setAddMap(newAddMap);
      return newAddMap;
    },
    [jobkeys, setAddMap, isDispo, lockOrder]
  );

  const mouseMove = useCallback(
    (e) => {
      if (e.pageX === 0 || !startPageX) return;
      const factor = view.zoom < 15 ? 7 : 1;
      calculateAddMap(
        e.target.dataset.date,
        Math.round((e.pageX - startPageX) / view.zoom / factor) * factor
      );
    },
    [calculateAddMap, view.zoom, startPageX]
  );

  const mouseUp = useCallback(
    (e) => {
      if (e.pageX === 0 || !startPageX) return;
      const factor = view.zoom < 15 ? 7 : 1;
      const addMap = calculateAddMap(
        e.target.dataset.date,
        Math.round((e.pageX - startPageX) / view.zoom / factor) * factor
      );
      e.target.classList.remove("dragging");
      changeDates(addMap, e.target.dataset.date);

      setStartPageX(null);
    },
    [startPageX, calculateAddMap, view.zoom, setStartPageX]
  );

  const days = [];
  for (let date in jobs) {
    if (jobs[date].deleted) continue;
    const dd =
      startAdd !== 0
        ? dayjs(date, "YYYY-MM-DD").add(startAdd, "days")
        : dayjs(addMap[date] ?? date, "YYYY-MM-DD");

    if (dd < view?.calStart || dd > view?.calEnd) continue;
    const letter = letters[jobs[date].id] || "";
    days.push(
      <div
        draggable={writeable && (isDispo || jobs[date].status === "PLANNING")}
        onDragOver={preventDefault}
        onDragStart={dragstart}
        onDrag={mouseMove}
        onDragEnd={mouseUp}
        onClick={onSelectExistingDate}
        className={"vc-date color" + (date === selectedDate ? " selected " : " ") + (jobs[date].status === "PLANNING"
          ? "blue"
          : jobs[date].status === "RAW"
          ? "yellow"
          : jobs[date].status === "READY"
          ? "orange"
          : "green") + (letter.length ? " withLetter" : "") }
        data-date={date}
        key={"phase" + date}
        style={{
          display: "flex",
          gridRow: line,
          gridColumn: dd.diff(view.calStart, "days") + 2,
        }}
      >{letter.length ? <div className="hasLetter">{letter}</div> : null}</div>
    );
  }

  return [
    <div
      className="vc-jobline"
      onClick={onSelectDate}
      key={"hlx" + line}
      style={{
        gridRow: line,
        gridColumn: 2 + " / " + (view.calEnd.diff(view.calStart, "days") + 2),
      }}
    ></div>,
    days,
  ];
}
