import {
  CalendarContext,
  CalendarElementsProps,
} from "../../shared/VerticalCalendar";
import { Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PossibleCalendarTypes } from "../MainCalendar";
import dayjs from "dayjs";
import ApiService from "@/api/ApiService";
import { useQueries, UseQueryOptions } from "@tanstack/react-query";
import ProjectLine from "./ProjectLine";
import ResourceLine from "./ResourceLine";
import { usePersistantStore } from "@/stores/global";
import MaterialLine from "./MaterialLine";

export interface CalProject {
  id: number;
  name: string;
  number: string;
  comment: string;
};

export interface CalPhase {
  id: number;
  name: string;
  comment: string;
  project: CalProject;
};

export interface CalJob {
  id: number;
  date: string;
  status: string;
  comment: string;
  phase: CalPhase;
}



export type CalDataLine<Reference={}, JobExtension={}> = Reference & {
  first: string | null;
  last: string | null;
  name: string;
  max: number;
  elements: { [date: string]: (CalJob & JobExtension)[] };
};

interface CalDataResponse {
  [lineId: string]: CalDataLine;
}

function getMonthsInRange(
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
  months: Set<string> = new Set()
) {
  // Base case: if start is after end, return the months array
  if (start.isAfter(end, "month")) {
    return months;
  }

  // Add the current month to the array
  months.add(start.format("YYYY-MM"));

  // Recursively call the function with the next month
  return getMonthsInRange(start.add(1, "month"), end, months);
}

const OffsetCounter = (init: number) => {
  const ctr = {
    count: 0,
    init: function (val: number) {
      this.count = val;
      return this;
    },
    increment: function (val: number) {
      const current = this.count;
      this.count += val;
      return current;
    },
  };
  return ctr.init(init);
};

export default function InnerCalendar({
  type,
  reference,
  settings,
  setJobInfoId,
  readOnly
}: {
  type: PossibleCalendarTypes;
  reference: number | null;
}) {
  const region = usePersistantStore(store => store.region);
  const view = useContext(CalendarContext);
  const [hoverLine, setHoverLine] = useState(null);
  const [moveMap, setMoveMap] = useState({});
  const [moveData, setMoveData] = useState(null);

  if (!view) return null;

  const offsetCounter = OffsetCounter(view.offset + 4);

  /* const [months, setMonths] = useState<Set<string>>(new Set());

  useEffect(() => {
    setMonths(
      (c) => new Set([...c, ...getMonthsInRange(view.calStart, view.calEnd)])
    );
  }, [view.calStart, view.calEnd]);
  */

  const months = useMemo(
    () => getMonthsInRange(view.safeStart, view.safeEnd),
    [view.safeStart, view.safeEnd]
  );

  const data = useQueries<UseQueryOptions<CalDataResponse>[], CalDataLine[]>({
    queries: Array.from(months).map((month) => {
      return {
        queryKey: ["getCalendar", type, ""+reference, month, region],
        queryFn: ApiService.getCalendar(type, reference ?? 0, month, region),
      };
    }),
    combine: (results) =>
      Object.values(
        results
          .filter((r) => r.isSuccess)
          .reduce((carry: CalDataResponse, monthQuery) => {
            for (let projectId in monthQuery.data) {
              if (projectId in carry) {
                carry[projectId] = {
                  ...carry[projectId],
                  autoBookings: {...carry[projectId]?.autoBookings ?? {}, ...(!!monthQuery.data[projectId].autoBookings && !Array.isArray(monthQuery.data[projectId].autoBookings) ? monthQuery.data[projectId].autoBookings : {}) ?? {}},
                  first:
                    !carry[projectId].first || carry[projectId].first > monthQuery.data[projectId].first
                      ? monthQuery.data[projectId].first
                      : carry[projectId].first,
                  last:
                    !carry[projectId].last || carry[projectId].last < monthQuery.data[projectId].last
                      ? monthQuery.data[projectId].last
                      : carry[projectId].last,
                  max:
                    !carry[projectId].max || carry[projectId].max < monthQuery.data[projectId].max
                      ? monthQuery.data[projectId].max
                      : carry[projectId].max,
                  elements: {
                    ...carry[projectId].elements,
                    ...monthQuery.data[projectId].elements,
                  },
                };
              } else {
                carry[projectId] = monthQuery.data[projectId];
              }
            }
            return carry;
          }, {} as CalDataResponse)
      ).sort((a, b) => (a.id === 0 ? "     0" : (a.first??(type === "RESOURCES"? a.number : a.name)??"")).localeCompare(b.id === 0 ? "     0" : (b.first??(type === "RESOURCES"? b.number : b.name)??""))),
  });

  return data
    .filter(
      (x) =>
        !x.first ||
        (view.safeStart.format("YYYY-MM-DD") <= x.last &&
          view.safeEnd.format("YYYY-MM-DD") >= x.first)
    )
    .map((x) =>
      type === "PROJECTS" ? (
        <ProjectLine
          key={x.id}
          readOnly={readOnly}
          data={x}
          offset={offsetCounter.increment(1)}
        />
      ) : type === "MATERIAL" || type === "DISPO" ? (
        null
      ) : (
        <ResourceLine
        type={type}
        setJobInfoId={setJobInfoId}
        settings={settings}
          hoverLine={hoverLine}
          readOnly={readOnly}
          setHoverLine={setHoverLine}
          moveMap={moveMap}
          setMoveMap={setMoveMap}
          setMoveData={setMoveData}
          moveData={moveData}
          key={x.id}
          data={x}
          offset={offsetCounter.increment(1)}
        />
      )
    );
}
