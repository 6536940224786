import {
  Paper,
  Button,
  Box,
  IconButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Fragment, useCallback } from "react";
import { Add, Delete, Edit } from "@mui/icons-material";
import { initialMaterialOrder, MaterialOrder, SupplierOrder } from "../Project";
import EnhancedTable from "@/shared/views/EnhancedTable";
import { TopBar } from "@/shared/structure/TopBar";
import AddMaterialDialog from "../dialogs/AddMaterialDialog";


export const createArticleText = (row: MaterialOrder)  => {
  if (!row.article)
    return "Kein Material";

  const articles = [<Fragment><strong>{row.amount+ " " + row.article.unit}</strong>&nbsp;{row.article?.name + " (" + row.article?.number + ")"}</Fragment>];
  for (let aa of row.additionalArticles){
    if (aa.deleted) continue;
    articles.push(<Fragment><strong>{aa.amount+ " " + aa.article.unit}</strong>&nbsp;{aa.article?.name + " (" + aa.article?.number + ")"}</Fragment>);
  }
  if (articles.length === 1) return articles[0];
  return <div className="fffwrap">{articles.map((x,i) => <div key={i} className="fffarticle">- {x}</div>)}</div>;
}

export const createDeliveryText = (
  transportInformation: MaterialOrder["transportInformation"],
  takt: MaterialOrder["takt"]
) => {
  const parts = [];
  for (let tt of transportInformation ?? []) {
    const truckAmount = tt.amount;
    let truckTypeStr = {
      "3A": "Dreiachser",
      "4A": "Vierachser",
      SA: "Sattel",
      "3A4A": "Solo",
      HZ: "Hängerzug",
      XX: "",
    }[tt.type];

    if (tt.thermo) {
      truckTypeStr = (truckTypeStr || "LKW") + " Thermo";
    }

    if (tt.bordmatik) {
      truckTypeStr = (truckTypeStr || "LKW") + " Bordmatik";
    }
    parts.push(
      !truckAmount
        ? truckTypeStr
        : truckAmount +
            " " +
            (truckTypeStr || "LKW") +
            (!!tt.siteWork ? " (anschl. Baustellendienst)" : "")
    );
  }

  if (parts.length === 0) {
    parts.push("-");
  } else {
    parts.push(takt > 0 ? "Takt: " + takt + " min." : "");
  }

  return parts.filter((x) => x.length > 0).join(", ");
};

const headCells = [
  {
    id: "_time",
    label: "Uhrzeit",
    numeric: true,
    sortable: true,
  },
  {
    id: "_lieferant",
    label: "Lieferant",
    numeric: false,
    sortable: true,
  },
  {
    id: "_artikel",
    label: "Artikel",
    numeric: false,
    sortable: true,
  },
  {
    id: "_delivery",
    label: "Logistik",
    numeric: false,
    sortable: true,
  },
  {
    id: "_comment",
    label: "Weitere Informationen",
    numeric: false,
    sortable: true,
  },
];

export default function MaterialTab({
  supplierOrders,
  changeable = true,
  jobState = [],
  projectId,
  setJobState = (_e) => {},
}: {
  supplierOrders: SupplierOrder[];
  changeable: boolean;
  projectId: number;
  jobState: MaterialOrder[];
  setJobState: (data: MaterialOrder[]) => void;
}) {
  const deleteJobOrder = useCallback(
    (index: number) => {
      setJobState(
        jobState.slice().map((so, i) => ({
          ...so,
          changed: true,
          deleted: !!so.deleted || i === index,
        }))
      );
    },
    [jobState]
  );

  const saveJobOrder = useCallback(
    (subOrder: MaterialOrder, index: number) => {
      if (index === -1) {
        const newState = jobState.slice();
        newState.push(subOrder);
        setJobState(newState);
      } else {
        setJobState(
          jobState.slice().map((so, i) => (i === index ? {...subOrder, changed: true} : so))
        );
      }
    },
    [jobState]
  );

  const hasJobOrders = jobState.filter((x) => !x.deleted).length > 0;

  const rowFormatter = useCallback(
    (row: MaterialOrder) => ({
      ...row,
      _time: row.firstUnload ?? "egal",
      _lieferant: !!row.article
        ? row.article?.plantName + " (" + row.article?.supplierName + ")"
        : "",
      _artikel: createArticleText(row),
      _delivery: createDeliveryText(row.transportInformation, row.takt),
      _comment:
        row.comment +
          (!row.position && !!row.article && !row.article.bauhof
            ? (row.comment.length > 0 ? ", " : "") + "Unbepreister Artikel!"
            : "") || "-",
    }),
    []
  );

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <TopBar>
        {!changeable ? null : (
          <AddMaterialDialog
            projectId={projectId}
            save={(o) => saveJobOrder(o, -1)}
            supplierOrders={supplierOrders}
            OpenButton={({ onClick }) => (
              <Button startIcon={<Add />} onClick={onClick}>
                Bestellung hinzufügen
              </Button>
            )}
            initialState={initialMaterialOrder()}
          />
        )}
      </TopBar>
      <Box
        sx={{ flex: 1, overflow: "auto", background: "#f5f5f5", padding: 2 }}
      >
        {hasJobOrders ? (
          <Paper>
            <EnhancedTable
              initialRows={100}
              buttons={
                !changeable
                  ? (r) => null
                  : (row) => (
                      <Fragment>
                        <AddMaterialDialog
                          projectId={projectId}
                          supplierOrders={supplierOrders}
                          save={(order) => saveJobOrder(order, row.index)}
                          initialState={jobState[row.index]}
                          OpenButton={({ onClick }) => (
                            <IconButton size="small" onClick={onClick}>
                              <Edit />
                            </IconButton>
                          )}
                        />
                        <IconButton
                          size="small"
                          onClick={() => deleteJobOrder(row.index)}
                        >
                          <Delete />
                        </IconButton>
                      </Fragment>
                    )
              }
              type=""
              noEmptyCells
              initialSort="_time"
              headCells={headCells}
              rowFormatter={rowFormatter}
              rows={jobState
                .map((x, index) => ({ ...x, index }))
                .filter((x) => !x.deleted)}
            />
          </Paper>
        ) : (
          <Alert severity="info" variant="filled">
            <AlertTitle>Nichts gefunden.</AlertTitle>
            Hier wurde noch nichts bestellt.
          </Alert>
        )}
      </Box>
    </Box>
  );
}
