import ApiService from "@/api/ApiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { MaterialOrder } from "@/components/planning/Project";
import { CircularProgress } from "@mui/material";

import {
  GridColDef,
  DataGrid} from '@mui/x-data-grid';

import {
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import dayjs from "dayjs";
import {
  createArticleText,
  createDeliveryText,
} from "@/components/planning/editors/MaterialTab";
import useRights from "@/shared/api/useRights";

interface ResourceTag {
  id: number;
  name: string;
  type: "HUMAN" | "RESOURCE" | "CREW";
  calendarType: "NONE" | "CALENDAR_ONLY" | "WISHABLE" | "REQUESTABLE";
}

export interface StaffMember {
  id: number;
  name: string;
  tags: ResourceTag[];
}

export interface Crew {
  id: number;
  name: string;
}

export interface Resource {
  id: number;
  name: string;
  tags: ResourceTag[];
  number: string;
}

interface ResourceBooking {
  id: number;
  crew: Crew | null;
  isLeader: boolean;
  resource: Resource | null;
  staffMember: StaffMember | null;
  order: ResourceOrder;
  type: "CREW" | "RESOURCE" | "HUMAN";
}

interface ResourceOrder {
  id: number;
  amount: number;
  tag: ResourceTag | null;
  comment: string;
  type: "CREW" | "RESOURCE" | "HUMAN";
  job: number | null;
  phase: number | null;
  deleted: boolean;
  references: (number | null)[];
  exceptions: string[];
}

export interface WithOrders {
  materialOrders: MaterialOrder[];
  resourceOrders: {
    resourceBookings: ResourceBooking[];
    resourceOrders: ResourceOrder[];
  };
}

function getColumns(withPlant: boolean, readOnly:boolean):GridColDef[] {
  const cells: GridColDef[] = [];

  if (withPlant) {
    cells.push({
      headerName: "Werk",
      flex: 1,
      field: 'plant',
      valueGetter: (value, row) => {
        return !row.materialOrder.article ? "" : row.materialOrder.article.plantName;
      },
    });
  }
  return cells.concat([{
    field: "date",
    headerName: "Datum",
    width: 150,
    type: "date",
    valueGetter: (value, row) => dayjs(row.date, "YYYY-MM-DD").toDate(),
    valueFormatter: (value, row) => {
      return dayjs(row.date, "YYYY-MM-DD").format("ddd, DD.MM.YYYY")+(row.duration === "HALF" ? " - halbtags" : "")
    },
  },
  {
    field: "time",
    headerName: "Uhrzeit",
    width: 100,
    valueGetter: (_, row) => row.materialOrder.firstUnload ?? "egal"
  },
  {
    field: "costbase",
    headerName: "Kostenstelle",
    width: 100,
    valueGetter: (_, row) => row.phase.project.number,
  },
  {
    field: "project",
    headerName: "Baustelle",
    flex: 1,
    valueGetter: (_, row) => row.phase.project.name,
  },
  {
    field: "crew",
    headerName: "Kolonne",
    flex: 1,
    valueGetter: (_, row) => Object.values(row.crews).join("; "),
  },
  {
    field: "artikel",
    headerName: "Artikel",
    display: "flex",
    flex: 2,
    renderCell: ({row}) => createArticleText(row.materialOrder),
    sortable: false
  },
  {
    field: "delivery",
    headerName: "Logistik",
    flex: 2,
    valueGetter: (_, row) => createDeliveryText(
      row.materialOrder.transportInformation,
      row.materialOrder.takt
    ),
    sortable: false
  },
  {
    field: "comment",
    flex: 1,
    headerName: "Weitere Informationen",
    valueGetter: (_, row) => row.materialOrder.comment +
    (!row.materialOrder.position &&
    !!row.materialOrder.article &&
    !row.materialOrder.article.bauhof
      ? (row.materialOrder.comment.length > 0 ? ", " : "") +
        "Unbepreister Artikel!"
      : "") || "-",
  },
  {
    field: "state",
    width: 100,
    headerName: "Status",
    valueGetter: (_, row) => (row.status === "PLANNING"
      ? "in Planung"
      : row.status === "RAW"
      ? "grobgeplant"
      : row.status === "READY" ? "grobgeplant" 
      : "feingeplant") + (!!row.orderSentBy ? ", bestellt" : ""),
  },
  {
    field: "plantState",
    display: "flex",
    width: 80,
    headerName: "Gelesen",
    renderCell: (params) => <PlantState
    readOnly={readOnly}
    id={params.row.materialOrder.id}
    state={params.row.materialOrder.plantStatus}
  />
  }])
}


const PlantState = ({ state, id, readOnly }: { state: "OPEN" | "READ"; id: number; readOnly: boolean; }) => {
  const queryClient = useQueryClient();
  const changeMutation = useMutation<any, AxiosResponse, ChangeData>({
    mutationFn: ApiService.setPlantOrderStatus(id),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getMaterialCalendar"] });
    },
  });
  return changeMutation.isPending ? (
    <CircularProgress />
  ) : state === "OPEN" ? (
    <CheckBoxOutlineBlank
      sx={{ cursor: "pointer" }}
      onClick={readOnly ? undefined: () => changeMutation.mutate("READ")}
    />
  ) : (
    <CheckBox
      sx={{ cursor: "pointer" }}
      onClick={readOnly ? undefined: () => changeMutation.mutate("OPEN")}
    />
  );
};


export default function MixingPlantView({
  reference,
}: {
  reference: number | null;
}) {
  const rights = useRights();

  const materialQuery = useQuery<any[], AxiosResponse>({
    queryKey: ["getMaterialCalendar", "" + reference],
    queryFn: ApiService.getCalendar("MATERIAL", reference, "none", 0),
  });

  return (
    <div className="calListWrapper">
      {materialQuery.isLoading ? (
        <CircularProgress />
      ) : materialQuery.isLoadingError ? (
        "Error"
      ) : (
        <DataGrid
          rows={materialQuery.data}
          getRowId={row => row.materialOrder.id}
          loading={materialQuery.isLoading}
          columns={getColumns(reference === 0, !("PLANT" in rights))}
        />
      )}
    </div>
  );
}
