import {
  Add,
  AddCircleTwoTone,
  RemoveCircleTwoTone,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import LabelWrapper from "../../root/LabelWrapper";
import { ResourceOrder } from "../Project";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import ApiService from "@/api/ApiService";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import MultiSelector from "../elements/MultiSelector";
import { usePersistantStore } from "@/stores/global";
import dayjs from "dayjs";
import NewRangePicker from "@/shared/forms/NewRangePicker";
import { Crew, Resource, StaffMember } from "@/components/calendar/listView/CalendarListView";
import useRights from "@/shared/api/useRights";

function compressDates(dates) {
  if (!dates.length) return [];

  const result = [];
  let start = dates[0];
  let count = 0;

  for (let i = 1; i <= dates.length; i++) {
      const current = dates[i];
      const previous = dates[i - 1];
      const nextDay = new Date(previous);
      nextDay.setDate(nextDay.getDate() + 1);
      const nextDayString = nextDay.toISOString().split('T')[0];

      if (current === nextDayString) {
          count++;
      } else {
          result.push(count > 0 ? `${start}+${count}` : start);
          start = current;
          count = 0;
      }
  }

  return result;
}

const fixSizes = (refs: ResourceOrder["references"], amount: number) =>
  refs.length === amount
    ? refs
    : refs.length > amount
    ? refs.slice(0, refs.length)
    : refs.concat(new Array(amount - refs.length).fill(null));

  const today = dayjs().format("YYYY-MM-DD");
  
export default function AddResourceDialog<T extends ResourceOrder>({
  save,
  OpenButton,
  hideAutoBooking = false,
  forceWishable = false,
  initialState,
  constraintData = null,
  dates = [],
  region,
}: {
  save: (order: T) => void;
  OpenButton: any;
  dates: string[];
  hideAutoBooking?: boolean;
  forceWishable?: boolean;
  initialState: T;
  constraintData?: null | {start: string, end: string};
  region: number;
}) {
  const [open, setOpen] = useState(false);
  const [showWishes, setShowWishes] = useState(false);
  const [state, setState] = useState<T>(initialState);
  console.log(dates);

  const rights = useRights();
  const isDispo = "DISPOSITION" in rights;

  const resStatusQuery = useQuery<((StaffMember|Resource|Crew)&{status: "UNKNOWN"|"BOOKED"|"FREE"})[], AxiosResponse>({
    queryKey: ["getByTag", ""+state.tag, ""+region, dates],
    enabled: !!state.tag && showWishes,
    queryFn: ApiService.getResourceStatus(state.type, state.tag ?? 0, region, {type: "dates", dates: compressDates(dates.filter(date => date >= today && (!state.startConstraint || date >= state.startConstraint) && (!state.endConstraint || date <= state.endConstraint)))}),
  });

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const openAndSet = useCallback(() => {
    setState(initialState);
    setOpen(true);
  }, [initialState]);

  useEffect(() => {
    if (!!state.startConstraint && !!state.endConstraint && state.startConstraint > state.endConstraint)
      setState(s => ({
        ...s,
        endConstraint: s.startConstraint
      }));
  },[state.startConstraint, state.endConstraint]);

  const valid = true;

  return (
    <Fragment>
      <OpenButton onClick={openAndSet} />
      {open ? <Dialog open={true} maxWidth="lg" fullWidth onClose={close}>
        <Fragment>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002169",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Ressource bestellen
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          > 
            {!!constraintData ? <LabelWrapper label="Zeitraum einschränken">
              <ToggleButtonGroup
                orientation="horizontal"
                exclusive
                value={!!state.startConstraint}
                onChange={(_,type) =>
                  setState((s) => ({
                    ...s,
                    startConstraint: type ? constraintData.start : null,
                    endConstraint: type ? constraintData.end : null,
                  }))}
              >
                <ToggleButton value={false}>Gesamte Bauphase</ToggleButton>
                <ToggleButton value={true}>Zeitraum</ToggleButton>
              </ToggleButtonGroup>
            </LabelWrapper> : null}
            {!!state.startConstraint && !!constraintData ? <LabelWrapper label="Zeitraum wählen">
              <NewRangePicker small minimumDate={dayjs(constraintData.start,"YYYY-MM-DD")}
                maximumDate={dayjs(constraintData.end,"YYYY-MM-DD")}
                onChange={([startDate, endDate]) => {
                  setState(s => ({
                    ...s,
                    startConstraint: !!startDate ? startDate.format("YYYY-MM-DD") : null,
                    endConstraint: !!endDate ? endDate.format("YYYY-MM-DD") : null
                  }));
                }}
                value={[
                  dayjs(state.startConstraint,"YYYY-MM-DD"),
                  dayjs(state.endConstraint,"YYYY-MM-DD"),
                ]} />
            </LabelWrapper> : null}
            <LabelWrapper label="Typ">
              <ToggleButtonGroup
                orientation="horizontal"
                exclusive
                value={state.type}
                onChange={(_, type) =>
                  type &&
                  type !== state.type &&
                  (() => {
                    setState((s) => ({
                      ...s,
                      type,
                      tag: null,
                      references: [null],
                      amount: 1,
                    }));
                    setShowWishes(false);
                  })()
                }
              >
                <ToggleButton value="CREW">Kolonne</ToggleButton>
                <ToggleButton value="RESOURCE">Maschine/Fahrzeug</ToggleButton>
                <ToggleButton value="HUMAN">Mitarbeiter</ToggleButton>
              </ToggleButtonGroup>
            </LabelWrapper>
            <LabelWrapper label="Anzahl">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  disabled={state.amount <= 1}
                  onClick={() =>
                    setState((s) => ({
                      ...s,
                      amount: s.amount - 1,
                      references: fixSizes(s.references, s.amount - 1),
                    }))
                  }
                >
                  <RemoveCircleTwoTone />
                </IconButton>
                <Box sx={{ fontSize: "20px", mx: "10px" }}>{state.amount}</Box>
                <IconButton
                  onClick={() =>
                    setState((s) => ({
                      ...s,
                      amount: s.amount + 1,
                      references: fixSizes(s.references, s.amount + 1),
                    }))
                  }
                >
                  <AddCircleTwoTone />
                </IconButton>
              </Box>
            </LabelWrapper>
            <LabelWrapper label="Kategorie">
              <ManyToOneSelect
                showWithoutChoice
                remoteFilter={{
                  type: state.type,
                  region: ["region", region],
                  calendarType: ["%IN", ["WISHABLE", "REQUESTABLE"]],
                }}
                remoteFilterReady={true}
                placeholder="Bitte wählen..."
                entity={"resourceTag"}
                currentId={state.tag ?? 0}
                sideEffect={(tag) => {
                  setShowWishes(forceWishable || isDispo || tag?.calendarType === "WISHABLE");
                }
                }
                setId={(nid) => setState((s) => ({ ...s, tag: nid || null }))}
              />
            </LabelWrapper>

            {showWishes ? (
              <LabelWrapper label="Wunschauswahl">
                <MultiSelector
                  onChange={(references) =>
                    setState((s) => ({ ...s, references }))
                  }
                  value={state.references}
                  query={
                    resStatusQuery
                  }
                />
              </LabelWrapper>
            ) : null}
            {hideAutoBooking ? null : <LabelWrapper label="Automatische Buchung">
              <ToggleButtonGroup
                exclusive
                value={state.autoBooking}
                onChange={(_, autoBooking) =>
                  autoBooking !== null && setState((s) => ({ ...s, autoBooking }))
                }
              >
                <ToggleButton color="success" value={true}>
                  Ja
                </ToggleButton>
                <ToggleButton color="error" value={false}>
                  Nein
                </ToggleButton>
              </ToggleButtonGroup>
            </LabelWrapper>}
            <LabelWrapper label="Weitere Informationen">
              <TextField
                size="small"
                fullWidth
                value={state.comment}
                placeholder="Weitere Informationen"
                multiline
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    comment: (e.target as HTMLInputElement).value,
                  }))
                }
              />
            </LabelWrapper>
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
            <Button onClick={close}>Abbrechen</Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!valid}
              onClick={() => {
                save(state);
                close();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Fragment>
      </Dialog>:null}
    </Fragment>
  );
}
