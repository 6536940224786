import {
  Add,
  AddCircleTwoTone,
  ArrowForward,
  Delete,
  Edit,
  ExpandMore,
  RemoveCircleTwoTone,
  Search,
  StarRateRounded,
  X,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import LabelWrapper from "../../root/LabelWrapper";
import {
  AdditionalArticle,
  Article,
  MaterialOrder,
  OrderPosition,
  ResourceOrder,
  SupplierOrder as SO,
  TruckInfo,
} from "../Project";

import SupplierOrder from "../elements/SupplierOrder";
import TimePicker from "react-time-picker";
import ApiService from "@/api/ApiService";
import ErrorIndicator from "@/shared/dialogs/ErrorIndicator";
import EnhancedTable from "@/shared/views/EnhancedTable";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { tileLayer } from "leaflet";
import MaterialSelector from "./MaterialSelector";
import { update } from "lodash";

export default function AddMaterialDialog({
  save,
  OpenButton,
  initialState,
  supplierOrders,
  projectId,
}: {
  supplierOrders: SO[];
  projectId: number;
  save: (order: MaterialOrder) => void;
  OpenButton: any;
  initialState: MaterialOrder;
}) {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState("100");
  const [additionalAmounts, setAdditionalAmounts] = useState<string[]>([]);
  const [articleSelectorOpen, setArticleSelectorOpen] = useState<
    false | "INITIAL" | number
  >(!initialState.article && initialState.amount !== -1 ? "INITIAL" : false);
  const [updateAmount, setUpdateAmount] = useState(false);
  const [state, setState] = useState<MaterialOrder>(initialState);

  useEffect(() => {
    if (!updateAmount) return;
    setUpdateAmount(false);
    setAmount("" + state.amount);
    setAdditionalAmounts(state.additionalArticles.map(x => ""+x.amount));
  }, [updateAmount]);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const openAndSet = useCallback(() => {
    setState(initialState);
    setAmount("" + initialState.amount);
    setAdditionalAmounts(initialState.additionalArticles.map(x => ""+x.amount));
    setOpen(true);
  }, [initialState]);

  const addPosition = () => {
    const articles = state.transportInformation ?? [];
    articles.push({
      type: "XX",
      amount: null,
      thermo: false,
      bordmatik: false,
      siteWork: state.amount === -1,
    });
    setState((s) => ({
      ...s,
      transportInformation: articles,
    }));
  };

  const addArticle = () => {
    const articles = state.additionalArticles ?? [];
    articles.push({
      amount: 0,
      article: null,
      id: 0,
      deleted: false
    });
    setState((s) => ({
      ...s,
      additionalArticles: articles,
    }));
    setAdditionalAmounts(x => {const f = x.slice(); f.push(""); return f;})
    setArticleSelectorOpen(articles.length-1);
  };



  const deletePosition = (i: number) => {
    setState((s) => {
      const transportInformation = (s.transportInformation ?? []).slice();
      transportInformation.splice(i, 1);
      return {
        ...s,
        transportInformation,
      };
    });
  };

  const setPositionField = (i: number, key: keyof TruckInfo, value: any) => {
    setState((s) => ({
      ...s,
      transportInformation: (s.transportInformation ?? []).map((t, xi) => ({
        ...t,
        [key]: xi === i ? value : t[key],
      })),
    }));
  };

  const setArticleField = (i: number, key: keyof AdditionalArticle, value: any) => {
    setState((s) => ({
      ...s,
      additionalArticles: (s.additionalArticles ?? []).map((t, xi) => ({
        ...t,
        [key]: xi === i ? value : t[key],
      })),
    }));
  };

  const valid =
    (state.amount > 0 && !!state.article) ||
    (!state.article &&
      state.amount === -1 &&
      (state.transportInformation ?? []).reduce<boolean | null>(
        (c, f) => c !== false && !!f.siteWork,
        null
      ));

  useEffect(() => {
    if (!!state.article || state.amount === -1) return;
    setArticleSelectorOpen("INITIAL");
  });

  console.log("ADditin", state.additionalArticles, additionalAmounts);

  return (
    <Fragment>
      <OpenButton onClick={openAndSet} />
      <Dialog open={open} maxWidth="lg" fullWidth onClose={close}>
        <Fragment>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002196",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Material bestellen
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {articleSelectorOpen !== false ? (
              <MaterialSelector
                onChange={function (
                  change: Partial<MaterialOrder>,
                  forceBaustellendienst: "DA" | "DAB" | "DELETE"
                ): void {
                  if (articleSelectorOpen === "INITIAL") {
                    setState((s) => ({
                      ...s,
                      ...change,
                      additionalArticles:
                        forceBaustellendienst === "DA" ||
                        forceBaustellendienst === "DAB"
                          ? []
                          : s.additionalArticles,
                      transportInformation:
                        forceBaustellendienst === "DELETE"
                          ? []
                          : forceBaustellendienst === "DAB"
                          ? (s.transportInformation ?? []).map((x) => ({
                              ...x,
                              siteWork: true,
                            }))
                          : s.transportInformation,
                    }));
                  } else {
                    setState((s) => ({
                      ...s,
                      additionalArticles: (s.additionalArticles ?? []).map(
                        (t, xi) => ({
                          ...t,
                          ...(xi === articleSelectorOpen ? change : {}),
                        })
                      ),
                    }));
                  }
                  setUpdateAmount(true);
                  setArticleSelectorOpen(false);
                }}
                projectId={projectId}
                onlyBauhof={state?.article?.bauhof ?? 0}
                allowBaustellendienst={articleSelectorOpen === "INITIAL"}
                supplierOrders={
                  articleSelectorOpen !== "INITIAL" ? [] : supplierOrders
                }
                abort={
                  (articleSelectorOpen !== "INITIAL" && !!state.additionalArticles[articleSelectorOpen].article) || (articleSelectorOpen === "INITIAL" && (!!state.article || state.amount === -1))
                    ? () => setArticleSelectorOpen(false)
                    : undefined
                }
              />
            ) : (
              <Fragment>
                <div className="extraFlex">
                  <LabelWrapper label="Artikelauswahl">
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Box>
                        {!!state.article ? (
                          <Fragment>
                            <Box>{state.article.name}</Box>
                            <Box>{state.article.number}</Box>
                            <Box>{state.article.plantName}</Box>
                            {state.article.bauhof === 0 ? <Fragment><Box>{state.article.supplierName}</Box>
                            {!state.position ? (
                              <Box>
                                <em>Unbepreister Artikel!</em>
                              </Box>
                            ) : null}</Fragment> : null}
                          </Fragment>
                        ) : (
                          <Box>Ohne Materiallieferung</Box>
                        )}
                      </Box>
                      <IconButton
                        onClick={() => setArticleSelectorOpen("INITIAL")}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  </LabelWrapper>
                  {state.amount !== -1 ? (
                    <LabelWrapper
                      label={`Menge in ${state.article?.unit ?? "t"}`}
                    >
                      <TextField
                        size="small"
                        fullWidth
                        value={amount}
                        autoFocus
                        placeholder="Menge"
                        multiline
                        onChange={(e) => {
                          setAmount((e.target as HTMLInputElement).value);
                          setState((s) => ({
                            ...s,
                            amount:
                              parseInt((e.target as HTMLInputElement).value) ||
                              0,
                          }));
                        }}
                      />
                    </LabelWrapper>
                  ) : null}
                </div>
                {state.additionalArticles.map((art,i) => art.deleted ? null : 
              <div className="extraFlex" key={i}>
             <Box sx={{ display: "flex", gap: 1,p:2, borderBottom: "1px solid #aaa", alignItems: "center" }}>
                  <Box>
                    <Box>{art.article.name}</Box>
                        <Box>{art.article.number}</Box>
                  </Box>
                  <IconButton
                    onClick={() => setArticleSelectorOpen(i)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => setArticleField(
                      i,
                      "deleted",
                      true
                    )}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              
                <LabelWrapper
                  label={`Menge in ${art.article?.unit ?? "t"}`}
                >
                  <TextField
                    size="small"
                    fullWidth
                    value={additionalAmounts[i]}
                    placeholder="Menge"
                    multiline
                    onChange={(e) => {
                      setAdditionalAmounts(aa => {
                        const additionalAmounts = aa.slice();
                        additionalAmounts[i] = (e.target as HTMLInputElement).value;
                        return additionalAmounts;
                      });
                      setArticleField(
                        i,
                        "amount",
                        parseInt((e.target as HTMLInputElement).value) ||
                          0
                      );
                    }}
                  />
                </LabelWrapper>
            </div>)}
                {!!state.article && state.article.bauhof > 0 ? <div style={{ textAlign: "right" }}>
                      <Button
                        sx={{ m: "10px" }}
                        size="small"
                        startIcon={<Add />}
                        onClick={addArticle}
                        variant="outlined"
                      >
                        Artikel hinzufügen
                      </Button>
                    </div> : null}

                <Fragment>
                  <div className="truckInfo">
                    <div className="truckInfoInner">
                      {(state.transportInformation ?? []).length > 0 ? (
                        (state.transportInformation ?? []).map((ti, i) => (
                          <div key={i} className="extraFlex specialFlex">
                            <LabelWrapper label={"LKW-Anzahl " + (i + 1)}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <IconButton
                                  disabled={!ti.amount}
                                  onClick={() =>
                                    setPositionField(
                                      i,
                                      "amount",
                                      !ti.amount ? null : ti.amount - 1 || null
                                    )
                                  }
                                >
                                  <RemoveCircleTwoTone />
                                </IconButton>
                                <Box sx={{ mx: "10px" }}>
                                  {!ti.amount ? "egal" : ti.amount + " LKW"}
                                </Box>
                                <IconButton
                                  onClick={() =>
                                    setPositionField(
                                      i,
                                      "amount",
                                      !ti.amount ? 1 : ti.amount + 1
                                    )
                                  }
                                >
                                  <AddCircleTwoTone />
                                </IconButton>
                              </Box>
                            </LabelWrapper>
                            <LabelWrapper label={"LKW-Art " + (i + 1)}>
                              <ToggleButtonGroup
                                size="small"
                                orientation="horizontal"
                                exclusive
                                value={ti.type}
                                onChange={(_, truckType) =>
                                  truckType &&
                                  truckType !== ti.type &&
                                  setPositionField(i, "type", truckType)
                                }
                              >
                                <ToggleButton value="XX">egal</ToggleButton>
                                <ToggleButton value="SA">Sattel</ToggleButton>
                                <ToggleButton value="3A">3-Achser</ToggleButton>
                                <ToggleButton value="4A">4-Achser</ToggleButton>
                                <ToggleButton value="3A4A">Solo</ToggleButton>
                                <ToggleButton value="HZ">
                                  Hängerzug
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </LabelWrapper>
                            <LabelWrapper label={"Thermo " + (i + 1)}>
                              <ToggleButtonGroup
                                size="small"
                                orientation="horizontal"
                                exclusive
                                value={ti.thermo}
                                onChange={(_, thermo) =>
                                  setPositionField(i, "thermo", thermo)
                                }
                              >
                                <ToggleButton value={false}>Nein</ToggleButton>
                                <ToggleButton value={true}>Ja</ToggleButton>
                              </ToggleButtonGroup>
                            </LabelWrapper>
                            <LabelWrapper label={"Bordmatik " + (i + 1)}>
                              <ToggleButtonGroup
                                size="small"
                                orientation="horizontal"
                                exclusive
                                value={ti.bordmatik}
                                onChange={(_, thermo) =>
                                  setPositionField(i, "bordmatik", thermo)
                                }
                              >
                                <ToggleButton value={false}>Nein</ToggleButton>
                                <ToggleButton value={true}>Ja</ToggleButton>
                              </ToggleButtonGroup>
                            </LabelWrapper>
                            <LabelWrapper label={"Baustellendienst " + (i + 1)}>
                              <ToggleButtonGroup
                                size="small"
                                orientation="horizontal"
                                exclusive
                                value={!!ti.siteWork}
                                disabled={state.amount === -1}
                                onChange={(_, thermo) =>
                                  setPositionField(i, "siteWork", thermo)
                                }
                              >
                                <ToggleButton value={false}>Nein</ToggleButton>
                                <ToggleButton value={true}>Ja</ToggleButton>
                              </ToggleButtonGroup>
                            </LabelWrapper>
                            <LabelWrapper label={""}>
                              <IconButton onClick={() => deletePosition(i)}>
                                <Delete />
                              </IconButton>
                            </LabelWrapper>
                          </div>
                        ))
                      ) : (
                        <div style={{ textAlign: "center", padding: "10px" }}>
                          Kein LKW benötigt (z.B. eigenständige Abholung)
                        </div>
                      )}
                    </div>

                    <div style={{ textAlign: "right" }}>
                      <Button
                        sx={{ mt: "10px" }}
                        size="small"
                        startIcon={<Add />}
                        onClick={addPosition}
                        variant="outlined"
                      >
                        LKW-Art hinzufügen
                      </Button>
                    </div>
                  </div>
                  {state.transportInformation.length > 0 ? (
                    <div className="extraFlex">
                      <LabelWrapper label="Erster LKW an BS">
                        <TimePicker
                          onChange={(firstUnload) =>
                            setState((s) => ({
                              ...s,
                              firstUnload,
                            }))
                          }
                          className={!state.firstUnload ? "nostart" : ""}
                          disableClock
                          value={state.firstUnload}
                        />
                      </LabelWrapper>
                      <LabelWrapper label="Takt">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            disabled={state.takt < 1}
                            onClick={() =>
                              setState((s) => ({
                                ...s,
                                takt: s.takt - 5,
                              }))
                            }
                          >
                            <RemoveCircleTwoTone />
                          </IconButton>
                          <Box sx={{ mx: "10px", minWidth: "65px", textAlign: "center" }}>
                            {state.takt > 0
                              ? state.takt +
                                " min"
                              : "kein Takt"}
                          </Box>
                          <IconButton
                            onClick={() =>
                              setState((s) => ({
                                ...s,
                                takt: s.takt + 5,
                              }))
                            }
                          >
                            <AddCircleTwoTone />
                          </IconButton>
                        </Box>
                      </LabelWrapper>
                    </div>
                  ) : null}
                  <LabelWrapper label="Weitere Informationen">
                    <TextField
                      size="small"
                      fullWidth
                      value={state.comment}
                      placeholder="Weitere Informationen"
                      multiline
                      onChange={(e) =>
                        setState((s) => ({
                          ...s,
                          comment: (e.target as HTMLInputElement).value,
                        }))
                      }
                    />
                  </LabelWrapper>
                </Fragment>
              </Fragment>
            )}
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
            <Button onClick={close}>Abbrechen</Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!valid}
              onClick={() => {
                save(state);
                close();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Fragment>
      </Dialog>
    </Fragment>
  );
}
