import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import useRights from "@/shared/api/useRights";

const paths = [
  {
    to: "/settings/resourceTag",
    label: "Kategorien",
  },
  {
    to: "/settings/dispoCategory",
    label: "Disposition",
  },
  {
    to: "/settings/specialPlace",
    label: "Buchorte",
  },
  {
    to: "/settings/crew",
    label: "Kolonnen",
  },
  {
    to: "/settings/resource",
    label: "Geräte",
  },
  {
    to: "/settings/staffMember",
    label: "Mitarbeiter",
    divider: true,
  },
  {
    to: "/settings/supplier",
    label: "Lieferanten",
  },
  {
    to: "/settings/plant",
    label: "Lieferwerke",
  },
  {
    to: "/settings/subcontractor",
    label: "Subunternehmen",
    divider: true
  },
  {
    to: "/settings/stoffstromCategory",
    label: "Stoffstrom",
    divider: true,
  },
  {
    to: "/settings/tradeTemplate",
    label: "Gewerke",
    divider: true
  },
  {
    to: "/settings/holiday",
    label: "Schließtage",
    divider: true
  },
  {
    to: "/settings/user",
    label: "Benutzer",
  },
];

const idFromPath = (path: string) => {
  let j = 0;
  for (let i = 0; i < paths.length; i++) {
    if (path.startsWith(paths[i].to)) return j;
    j += paths[i].divider ? 2 : 1;
  }
  return 0;
};

export default function Settings() {
  const { pathname } = useLocation();

  const [value, setValue] = React.useState(() => idFromPath(pathname));

  const rights = useRights();
  if (!("BASEDATA" in rights)) return <Navigate replace to="/" />;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue(idFromPath(pathname));
  }, [pathname]);

  const tabs = [];
  for (let path of paths) {
    tabs.push(
      <Tab component={Link} to={path.to} label={path.label} key={path.label} />
    );
    if (path.divider) tabs.push(<Divider />);
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        overflow: "hidden",
        display: "flex",
      }}
    >
      <Tabs
        sx={{ borderRight: 1, borderColor: "divider" }}
        value={value}
        onChange={handleChange}
        orientation="vertical"
      >
        {tabs}
      </Tabs>
      <Outlet />
    </Box>
  );
}
