import { Fragment } from "react/jsx-runtime";
import { CalDataLine } from "./InnerCalendar";
import { CalendarContext } from "@/components/shared/VerticalCalendar";
import { useCallback, useContext, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import ApiService from "@/api/ApiService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import ResInner from "./ResInner";
import CrewParts from "./CrewParts";
import { Edit, Weekend } from "@mui/icons-material";
import {
  textColors,
  colorsSortedNoBlack as allColors,
} from "@/shared/forms/ColorPicker";
import { split } from "lodash";

export const nosec = (str) => (str.indexOf("Sekund") > -1 ? "heute" : str);

interface CalStaffMember {
  id: number;
  name: string;
  number: string;
}
interface CalCrew {
  id: number;
  name: string;
}
interface CalResource {
  id: number;
  name: string;
  number: string;
}
interface CalResourceOrder {
  id: number;
  comment: string;
  singleDayOrder: boolean;
}

interface CalResourceBooking {
  id: number;
  crew: number;
}

interface AutoBooking {
  id: number;
  resource_order_id: number;
  planned_start: string;
  planned_end: string;
  project: {
    id: number;
    name: string;
    number: string;
    plannedStart: string;
    plannedEnd: string;
  };
}

const today = dayjs().startOf("day");

class ResultMapper {
  list: {
    [daykey: string]: {
      elementKeys: Set<string>;
      elements: any[];
      date: dayjs.Dayjs;
    };
  } = {};

  movingList: {
    [daykey: string]: {
      elementKeys: Set<string>;
      elements: any[];
      date: dayjs.Dayjs;
    };
  } = {};

  addAutobookings(abs: AutoBooking[], start: dayjs.Dayjs, end: dayjs.Dayjs) {
    let yesterday = "";
    for (let date = start; date <= end; date = date.add(1, "day")) {
      const daykey = date.format("YYYY-MM-DD");
      if (daykey in this.list) continue;
      try {
        for (let ab of abs ?? []) {
          if (
            daykey < ab.planned_start ||
            daykey > ab.planned_end ||
            ab.exceptions.includes(daykey)
          )
            continue;
          const yesterday = date.subtract(1, "day").format("YYYY-MM-DD");
          const elementKey = "auto" + ab.resource_order_id;
          if (
            date.isoWeekday() > 5 &&
            !(
              this.list[yesterday]?.elementKeys?.size === 1 &&
              this.list[yesterday].elementKeys.has(elementKey)
            )
          )
            continue;

          if (!(daykey in this.list)) {
            this.list[daykey] = {
              elementKeys: new Set(),
              date,
              elements: [],
            };
          }

          this.list[daykey].elementKeys.add(elementKey);
          this.list[daykey].elements.push({
            element: ab,
            hide: 0,
            type: "auto",
          });
        }
      } catch (e) {
        console.log(e, abs, start, end);
      }
    }
  }

  addNormal(date: dayjs.Dayjs, element: any, hide: boolean) {
    const daykey = date.format("YYYY-MM-DD");
    try {
      const elementKey =
        (!!element.vacation
          ? "v" + element.title
          : !!element.resourceOrder
          ? "o" + element.phase.id
          : !!element.resourceBooking.resourceOrder
          ? "b" + element.phase.id
          : "c" + element.phase.id) +
        "_" +
        hide +
        "_" +
        (!!element.orderSentAt ? "sent" : "waiting") +
        "_" +
        (element?.status ?? "");
      if (!(daykey in this.list)) {
        this.list[daykey] = {
          elementKeys: new Set(),
          date,
          elements: [],
        };
      }
      this.list[daykey].elementKeys.add(elementKey);
      this.list[daykey].elements.push({
        element,
        hide,
        type: !!element.vacation ? "vacation" : "normal",
      });
    } catch (e) {
      console.log(e, "adding error", element, daykey);
    }
  }

  getListBack(weekmode = false) {
    const dates = Object.keys(this.list).sort();

    let carry = null;
    const output = [];

    for (let date of dates) {
      let isDifferent =
        !carry ||
        carry.elementKeys.symmetricDifference(this.list[date].elementKeys)
          .size > 0;
      let splitItUp = false;
      let fillerElements = {};
      if (!!carry && !isDifferent) {
        if (this.list[date].elements[0].type === "normal") {
          const xdiff = this.list[date].date.diff(carry.end, "days");
          splitItUp = xdiff > 1;
          if (
            splitItUp &&
            weekmode &&
            xdiff <= 3 &&
            Object.keys(carry.elements).length < 21
          ) {
            const xdate = this.list[date].date.day();
            const ydate = carry.end.day();
            if (
              (xdate === 0 && ydate === 5) ||
              (xdate === 1 && ydate === 5) ||
              (xdate === 1 && ydate === 6)
            ) {
              splitItUp = false;
              const dd = carry.end.add(1, "day").format("YYYY-MM-DD");
              fillerElements[dd] = [];
              for (let i = 0; i < carry.elementKeys.size; i++) {
                fillerElements[dd].push({
                  element: null,
                  hide: 0,
                  type: "filler",
                });
              }
            }
            if (xdate === 1 && ydate === 5) {
              const dd = this.list[date].date
                .subtract(1, "day")
                .format("YYYY-MM-DD");
              fillerElements[dd] = [];
              for (let i = 0; i < carry.elementKeys.size; i++) {
                fillerElements[dd].push({
                  element: null,
                  hide: 0,
                  type: "filler",
                });
              }
            }
          }
        } else {
          if (!weekmode) {
            splitItUp = this.list[date].date.day() === 1;
          } else {
            splitItUp = Object.keys(carry.elements).length > 20;
          }
        }
      }

      if (!carry || isDifferent || splitItUp) {
        if (carry) output.push(carry);
        carry = {
          start: this.list[date].date,
          end: this.list[date].date,
          elementKeys: this.list[date].elementKeys,
          elements: { [date]: this.list[date].elements },
        };
      } else {
        carry.end = this.list[date].date;
        carry.elements = {
          ...carry.elements,
          ...fillerElements,
          [date]: this.list[date].elements,
        };
      }
    }
    if (carry) output.push(carry);

    return output;
  }

  getMovingListBack() {
    const dates = Object.keys(this.movingList).sort();
    let carry = null;
    const output = [];
    for (let date of dates) {
      if (
        !carry ||
        this.movingList[date].date.diff(carry.end, "days") > 1 ||
        carry.elementKeys.difference(this.movingList[date].elementKeys).size > 0
      ) {
        if (carry) output.push(carry);
        carry = {
          start: this.movingList[date].date,
          end: this.movingList[date].date,
          elementKeys: this.movingList[date].elementKeys,
          elements: { [date]: this.movingList[date].elements },
        };
      } else {
        carry.end = this.movingList[date].date;
        carry.elements[date] = this.movingList[date].elements;
      }
    }
    if (carry) output.push(carry);
    return output;
  }

  addMoving(date, element, changedOffset) {
    const daykey = date.format("YYYY-MM-DD");
    const elementKey =
      (!!element.resourceOrder
        ? "o" + element.resourceOrder.id
        : "b" + element.resourceBooking.resourceOrder?.id) +
      "c" +
      changedOffset;
    if (!(daykey in this.movingList)) {
      this.movingList[daykey] = {
        elementKeys: new Set(),
        elements: [],
        date,
      };
    }
    this.movingList[daykey].elementKeys.add(elementKey);
    this.movingList[daykey].elements.push({ element, changedOffset });
  }
}

const calculateAddMap = (date: string, add: number, jobs: string[]) => {
  const newAddMap: { [date: string]: string } = {};
  if (add !== 0) {
    const dates = add > 0 ? jobs : jobs.slice().reverse();
    newAddMap[date] = dayjs(date, "YYYY-MM-DD")
      .add(add, "days")
      .format("YYYY-MM-DD");
    let lastDate = newAddMap[date];
    for (const c of dates) {
      if ((add > 0 && c <= date) || (add < 0 && c >= date)) continue;
      if ((add > 0 && lastDate < c) || (add < 0 && lastDate > c)) break;
      let newDate = dayjs(lastDate, "YYYY-MM-DD").add(add > 0 ? 1 : -1, "day");
      while (
        newDate.isoWeekday() > 5 &&
        !dates.includes(newDate.format("YYYY-MM-DD"))
      ) {
        newDate = newDate.add(add > 0 ? 1 : -1, "day");
      }
      newAddMap[c] = newDate.format("YYYY-MM-DD");
      lastDate = newAddMap[c];
    }
  }
  return newAddMap;
};

interface ChangeData {
  move: { phase: number; moveMap: { [origin: string]: string } } | null;
  orderToBooking: {
    order: number;
    jobId: number | null;
    startDate: string;
    target: number;
  } | null;
  bookingMove: {
    booking: number | null;
    startDate: string;
    order: number | null;
    target: number;
  } | null;
  partMove: {
    resourceBookingId: number;
    jobId: number;
  } | null;
}

const blankImage = new Image();
blankImage.src =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";

const preventDefault = (e) => e.preventDefault();

export default function ResourceLine({
  offset,
  data,
  partDateSelectorRef,
  readOnly,
  hoverLine,
  setJobInfoId,
  setHoverLine,
  settings,
  moveMap,
  setMoveMap,
  moveData,
  setMoveData,
  type,
}: {
  offset: number;
  readOnly: boolean;
  type: "CREWS" | "STAFF" | "RESOURCES";
  data: CalDataLine<
    CalStaffMember | CalCrew | CalResource,
    | { resourceOrder: CalResourceOrder }
    | { resourceBooking: CalResourceBooking }
  >;
}) {
  const [startPageXY, setStartPageXY] = useState<[number, number] | null>(null);

  const view = useContext(CalendarContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const changeMutation = useMutation<any, AxiosResponse, ChangeData>({
    mutationFn: ApiService.changeCalendar(),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      queryClient.invalidateQueries({ queryKey: ["getOpenRequests"] });
      setMoveData(null);
      setMoveMap(null);
      setHoverLine(null);
      setStartPageXY(null);
    },
  });

  const deleteMutation = {
    mutate: (data) =>
      changeMutation.mutate({ deleteBooking: { ...data, type } }),
  };

  const dragstart = useCallback(
    (e, elements, i) => {
      const dayadd = Math.floor(
        (e.clientX - e.target.getBoundingClientRect().left) / view.zoom
      );
      const startDate = dayjs(e.target.dataset.date, "YYYY-MM-DD")
        .add(dayadd, "days")
        .format("YYYY-MM-DD");

      const element = elements[startDate][i].element;
      e.dataTransfer.setDragImage(blankImage, 0, 0);
      e.dataTransfer.effectAllowed = "move";
      e.target.style.cursor = "ew-resize !important";
      e.target.classList.add("dragging");
      //for (let el of document.querySelectorAll(".vc-sidebar.calendar")) el.style.height = window.getComputedStyle(el).height;
      setHoverLine({ id: data.id, offset, moveOnlyOrigin: e.shiftKey });
      setMoveData({
        ...element,
        origin: data.id,
        startDate,
      });
      setStartPageXY([e.pageX, e.pageY]);
    },
    [view.zoom, offset]
  );

  const dropPart = useCallback(
    (e, elements, i) => {
      const dayadd = Math.floor(
        (e.clientX - e.target.getBoundingClientRect().left) / view.zoom
      );
      const startDate = dayjs(e.target.dataset.date, "YYYY-MM-DD")
        .add(dayadd, "days")
        .format("YYYY-MM-DD");
      
        const parts = e.dataTransfer.getData("text").split("#");
        const resourceBookingId = parseInt(parts[0]);
        const originDate = parts[1];
        const resourceId = parseInt(parts[2] ?? "0");
        const resourceType = parts[3] ?? "NONE";
   
       
      partDateSelectorRef.current?.openModal({
        resourceBookingId, originDate, resourceId, resourceType, startDate, jobId: elements[startDate][i].element.id
      });
        
      e.target.classList.remove("dragover");
    },
    [view.zoom]
  );

  const mouseMove = useCallback(
    (e) => {
      if (e.pageY === 0 || e.pageX === 0 || !startPageXY) return;
      const factor = view.zoom < 15 ? 7 : 1;
      const add =
        Math.round((e.pageX - startPageXY[0]) / view.zoom / factor) * factor;
      const addMap = !!moveData.phase.jobKeys
        ? calculateAddMap(moveData.startDate, add, moveData.phase.jobKeys)
        : {};

      setMoveMap(addMap);
    },
    [view.zoom, startPageXY, moveData]
  );

  const mouseUp = useCallback(
    (e) => {
      if (e.pageY === 0 || e.pageX === 0 || !startPageXY) return;
      const factor = view.zoom < 15 ? 7 : 1;
      const add =
        Math.round((e.pageX - startPageXY[0]) / view.zoom / factor) * factor;
      const addMap = !!moveData.phase.jobKeys
        ? calculateAddMap(moveData.startDate, add, moveData.phase.jobKeys)
        : {};
      if (!!moveData.resourceOrder) {
        changeMutation.mutate({
          move: { phase: moveData.phase.id, moveMap: addMap },
          orderToBooking:
            hoverLine.id === 0
              ? null
              : {
                  order: moveData.resourceOrder.id,
                  startDate: moveData.startDate,
                  jobId:
                    hoverLine.moveOnlyOrigin ||
                    moveData.resourceOrder.singleDayOrder
                      ? moveData.id
                      : null,
                  target: hoverLine.id,
                },
          bookingMove: null,
        });
      } else {
        changeMutation.mutate({
          move: { phase: moveData.phase.id, moveMap: addMap },
          orderToBooking: null,
          /* hoverLine.id === 0
              ? null
              : {
                  order: moveData.resourceOrder.id,
                  jobId:
                    hoverLine.moveOnlyOrigin ||
                    moveData.resourceOrder.singleDayOrder
                      ? moveData.id
                      : null,
                  target: hoverLine.id,
                }, */
          bookingMove: {
            type,
            startDate: moveData.startDate,
            origin: moveData.origin,
            booking: hoverLine.moveOnlyOrigin
              ? moveData.resourceBooking.id
              : null,
            order: !hoverLine.moveOnlyOrigin
              ? moveData.resourceBooking.resourceOrder?.id
              : null,
            target: hoverLine.id,
          },
        });
      }

      e.target.classList.remove("dragging");
    },
    [startPageXY, view.zoom, hoverLine, moveData]
  );

  const result = new ResultMapper();

  const days = [];
  const moving = [];

  for (let date in data.elements) {
    const exx = [];
    const dd = dayjs(date, "YYYY-MM-DD");

    if (dd < view?.calStart || dd > view?.calEnd) continue;

    for (let i = 0; i < data.elements[date].length; i++) {
      const element = data.elements[date][i];

      let changedOffset = offset;
      let changedDate = date;

      if (!!moveData && !!hoverLine && !element.vacation) {
        try {
          changedDate =
            element.phase.id === moveData.phase.id && !!moveMap
              ? moveMap[date] ?? date
              : date;
        } catch (e) {
          console.log(element, moveData);
          throw e;
        }

        if (
          !!moveData.resourceOrder !== !!element.resourceOrder ||
          moveData.startDate > date
        )
          changedOffset = offset;
        else if (!!moveData.resourceOrder) {
          if (
            hoverLine.moveOnlyOrigin ||
            moveData.resourceOrder.singleDayOrder
          ) {
            changedOffset =
              moveData.id === element.id ? hoverLine.offset : offset;
          } else {
            changedOffset =
              moveData.resourceOrder.id === element.resourceOrder.id
                ? hoverLine.offset
                : offset;
          }
        } else if (hoverLine.moveOnlyOrigin) {
          changedOffset =
            moveData.resourceBooking.id === element.resourceBooking.id
              ? hoverLine.offset
              : offset;
        } else {
          changedOffset =
            moveData.resourceBooking.resourceOrder?.id ===
              element.resourceBooking?.resourceOrder?.id &&
            moveData.origin === data.id
              ? hoverLine.offset
              : offset;
        }
      }

      let hide = 0;
      if (changedDate !== date || changedOffset !== offset) {
        const xdd = dayjs(changedDate, "YYYY-MM-DD");
        hide = moveData.id !== element.id ? 1 : 2;

        if (xdd >= view?.calStart && xdd <= view?.calEnd) {
          const weekday = ((parseInt(xdd.format("d")) + 6) % 7) + 1;

          result.addMoving(xdd, element, changedOffset);

          moving.push(
            <div
              className={
                "vc-date-res moving" +
                (moveData.id === element.id ? " moveOrigin" : "") +
                " d" +
                weekday
              }
              key={"bgphasemove" + data.id + "_" + date + "_" + i}
              style={{
                gridRow: changedOffset,
                gridColumn: xdd.diff(view.calStart, "days") + 2,
              }}
            ></div>
          );
        }
      }

      result.addNormal(dd, element, hide);
    }

    false
      ? days.push(
          <div
            className={
              "vc-date-res boxWrapper" + (data.id === 0 ? " openline" : "")
            }
            data-date={date}
            key={"phase" + data.id + "_" + date}
            style={{
              gridRow: offset,
              gridColumn: dd.diff(view.calStart, "days") + 2,
            }}
          >
            {exx}
          </div>
        )
      : null;
  }

  result.addAutobookings(
    Object.values(data.autoBookings ?? {}),
    today > view?.calStart ? today : view?.calStart,
    view?.calEnd
  );

  const weekmode = view?.zoom < 40;

  const mergedList = result.getListBack(weekmode);
  const mergedMoving = result.getMovingListBack();

  for (let x of mergedList) {
    const date = x.start.format("YYYY-MM-DD");
    days.push(
      <div
        className={
          "vc-date-res boxWrapper" + (data.id === 0 ? " openline" : "")
        }
        data-date={date}
        key={"phase" + data.id + "_" + date}
        style={{
          gridRow: offset,
          gridColumnStart: x.start.diff(view.calStart, "days") + 2,
          gridColumnEnd: x.end.diff(view.calStart, "days") + 3,
        }}
      >
        {x.elements[date].map(({ element, hide, type: xtype }, i) =>
          xtype === "normal" ? (
            <Fragment key={"phase" + data.id + "_" + date + "_" + i}>
              <div
                draggable={!readOnly}
                style={
                  weekmode
                    ? {
                        backgroundColor:
                          allColors[
                            element.phase.project.id % allColors.length
                          ],
                        color:
                          textColors[
                            allColors[
                              element.phase.project.id % allColors.length
                            ]
                          ],
                        opacity: !!element.resourceBooking ? 1 : 0.6,
                      }
                    : {}
                }
                onDragOver={preventDefault}
                onDragEnter={
                  type === "CREWS" && !!element.resourceBooking
                    ? (e) => e.target.classList.add("dragover")
                    : undefined
                }
                onDragLeave={
                  type === "CREWS" && !!element.resourceBooking
                    ? (e) => e.target.classList.remove("dragover")
                    : undefined
                }
                onDrop={
                  type === "CREWS" && !!element.resourceBooking
                    ? (e) => dropPart(e, x.elements, i)
                    : undefined
                }
                onDragStart={(e) => dragstart(e, x.elements, i)}
                onDrag={mouseMove}
                data-date={date}
                onDragEnd={mouseUp}
                className={
                  "resourceOrder" +
                  (hide > 0 ? " moveX" + hide : "") +
                  (!!element.resourceBooking ? " resourceBooking" : "")
                }
              >
                <ResInner
                  readOnly={readOnly}
                  did={data.id}
                  setJobInfoId={setJobInfoId}
                  elements={x.elements}
                  i={i}
                  changeMutation={changeMutation}
                  deleteMutation={deleteMutation}
                />
              </div>
              {type === "CREWS" && !!element.resourceBooking && hide === 0 ? (
                <CrewParts
                  readOnly={readOnly}
                  did={data.id}
                  elements={x.elements}
                  settings={settings}
                  i={i}
                />
              ) : null}
            </Fragment>
          ) : xtype === "vacation" ? (
            <div
              onDragOver={preventDefault}
              className={"resourceOrder vacation"}
              key={"vacation" + data.id + "_" + date + "_" + i}
            >
              {element.title}
            </div>
          ) : (
            <div
              style={
                weekmode
                  ? {
                      backgroundColor:
                        allColors[element.project.id % allColors.length],
                      color:
                        textColors[
                          allColors[element.project.id % allColors.length]
                        ],
                    }
                  : {}
              }
              onDragOver={preventDefault}
              className={"resourceOrder autobooking"}
              key={"autobooking" + data.id + "_" + date + "_" + i}
            >
              {element.project.name + " (" + element.project.number + ")"}
              <div className="resOrderEdit">
                <div className="resOrderButton">
                  <Edit
                    onClick={() =>
                      navigate("/project/" + element.project.id, {})
                    }
                    fontSize="inherit"
                  />
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  }

  for (let x of mergedMoving) {
    const date = x.start.format("YYYY-MM-DD");
    const changedOffset = x.elements[date][0].changedOffset;
    const element = x.elements[date][0].element;
    let isOrigin = false;
    for (let xd in x.elements) {
      isOrigin = isOrigin || x.elements[xd][0].element.id === moveData.id;
    }

    moving.push(
      <div
        className={
          "vc-date-res moving" +
          (isOrigin ? " moveOrigin" : "") +
          " dnoBackground"
        }
        key={"phasemove" + data.id + "_" + element.id + "_" + date}
        style={{
          gridRow: changedOffset,
          gridColumnStart: x.start.diff(view.calStart, "days") + 2,
          gridColumnEnd: x.end.diff(view.calStart, "days") + 3,
        }}
      >
        <div
          className={
            "resourceOrder" +
            (!!element.resourceBooking ? " resourceBooking" : "")
          }
        >
          <ResInner
            elements={x.elements}
            setJobInfoId={setJobInfoId}
            did={data.id}
            i={0}
            changeMutation={false}
            deleteMutation={false}
          />
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div
        key={"sidebar" + offset}
        className={"vc-sidebar calendar" + (data.id === 0 ? " openline" : "")}
        style={{
          gridRow: offset,
        }}
      >
        {data.id === 0 ? (
          <em>offen</em>
        ) : (
          <Fragment>
            <div className="vvss_name">
              {(!!data?.number ? data.number + ": " : "") + data.name}
            </div>
            {!!data.lastLocation ? (
              <div className="vvss_location">
                {!!data.lastLocation.free ? (
                  <div className="vvss_free">[frei]</div>
                ) : null}
                <div className="vvss_locname">
                  {!!data.lastLocation.project
                    ? data.lastLocation.project.name
                    : data.lastLocation.locationName}
                </div>
                <div className="vvss_age">
                  (
                  {nosec(
                    dayjs(data.lastLocation.date, "YYYY-MM-DD").from(
                      dayjs().startOf("day")
                    )
                  )}
                  )
                </div>
              </div>
            ) : null}
          </Fragment>
        )}
      </div>

      {days}
      {moving}
      <div
        className={"vc-hl" + (data.id === 0 ? " openline" : "")}
        onDragOver={(e) =>
          setHoverLine({ id: data.id, offset, moveOnlyOrigin: e.shiftKey })
        }
        key={"hl" + offset}
        style={{
          pointerEvents: "initial",
          gridRow: offset,
          gridColumn: 2 + " / " + (view.calEnd.diff(view.calStart, "days") + 2),
        }}
      ></div>
    </Fragment>
  );
}
