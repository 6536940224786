import ApiService from "@/api/ApiService";
import AddMaterialDialog from "@/components/planning/dialogs/AddMaterialDialog";
import AddResourceDialog from "@/components/planning/dialogs/AddResourceDialog";
import {
  initialMaterialOrder,
  MaterialOrder,
  SupplierOrder,
} from "@/components/planning/Project";
import { Add, Delete, Edit, Loop } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Fragment, useState } from "react";

type SingleJobMaterialOrder = MaterialOrder & { job: number };

export function DirectMaterialEditor({
  job,
  region,
  project,
  date,
  order,
}: {
  job: number;
  date: string;
  region: number;
  project: number;
}) {
  const queryClient = useQueryClient();

  const saveMutation = useMutation<
    SingleJobMaterialOrder,
    AxiosResponse,
    SingleJobMaterialOrder
  >({
    mutationFn: ApiService.editEntity("materialOrder"),
    onSuccess: (answer) => {
      queryClient.invalidateQueries({
        queryKey: ["getDispoCal", date, "" + region],
      });
      queryClient.invalidateQueries({ queryKey: ["getJobApp", "" + job] });
    },
  });

  const query = useQuery<SupplierOrder[], AxiosResponse>({
    queryKey: ["getSupplierOrders", project],
    queryFn: ApiService.getEntitiesWithFilter("supplierOrder", {
      project: ["project", project],
    }),
  });

  const loading = saveMutation.isPending;

  return query.isSuccess ? (
    <Fragment>
      <AddMaterialDialog
        save={function (order: MaterialOrder): void {
          saveMutation.mutate({ ...order, job });
        }}
        OpenButton={({ onClick }) => (
          <IconButton size="small" onClick={onClick}>
            <Edit fontSize="small"  />
          </IconButton>
        )}
        initialState={order}
        supplierOrders={query.data}
        projectId={project}
      />
      <IconButton
        size="small"
        onClick={() => saveMutation.mutate({ ...order, job, deleted: true })}
      >
        <Delete fontSize="small" />
      </IconButton>
    </Fragment>
  ) : null;
}
