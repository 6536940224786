import ApiService from "@/api/ApiService";
import AddMaterialDialog from "@/components/planning/dialogs/AddMaterialDialog";
import AddResourceDialog from "@/components/planning/dialogs/AddResourceDialog";
import {
  initialMaterialOrder,
  MaterialOrder,
  SupplierOrder,
} from "@/components/planning/Project";
import { Add, Loop } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Fragment, useState } from "react";

type SingleJobMaterialOrder =MaterialOrder & { job: number };

export function DirectMaterialBooking({
  job,
  region,
  project,
  date
}: {
  job: number;
  date: string;
  region: number;
  project: number;
}) {

 const queryClient = useQueryClient();

  const saveMutation = useMutation<
    SingleJobMaterialOrder,
    AxiosResponse,
    SingleJobMaterialOrder
  >({
    mutationFn: ApiService.createEntity("materialOrder"),
    onSuccess: (answer) => {
      queryClient.invalidateQueries({queryKey: ["getDispoCal", date, ""+region]});
      queryClient.invalidateQueries({queryKey: ["getJobApp", ""+job]});
    },
  });

  const query = useQuery<SupplierOrder[], AxiosResponse>({
    queryKey: ["getSupplierOrders", project],
    queryFn: ApiService.getEntitiesWithFilter("supplierOrder", { project: ["project", project] }),
  });

 
  

  const loading =
    saveMutation.isPending;

  return query.isSuccess ? (
    <AddMaterialDialog
      save={function (order: MaterialOrder): void {
        saveMutation.mutate({...order, job});
      }}
      OpenButton={
        ({onClick}) => <div className="directbookingButton" onClick={!loading ? onClick : () => {}}>
          {loading ? <Loop fontSize="inherit" /> : <Add fontSize="inherit" />}
          <div className="dibutext">hinzufügen</div>
        </div>
      }
      initialState={{
        ...initialMaterialOrder()
      }}
      supplierOrders={query.data}
      projectId={project}
    />
  ) : null;
}
