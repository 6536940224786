import ApiService from "@/api/ApiService";
import { Error, Loop } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import React, { useMemo } from "react";

interface BookingCheckProps {
  job: number;
  resourceOrder: number;
  type: string;
}

function CrewTooltip({ members }) {
    return (
      <Tooltip
        title={
          <>
            <div style={{ marginBottom: "5px" }}>
              {members.map((x) => x.type === "HUMAN" ? (
                <div style={{...(x.isLeader ? { fontWeight: "bold" } : {}),...(!!x.vacationData ? { textDecoration: "strike-through" } : {})}}>
                  {x.staffMember.name + ", " + x.staffMember.firstName}
                </div>
              ) : null)}
            </div>
            <div>
              {members.map((x) => x.type !== "HUMAN" ? (
                <div>{x.resource.name + " (" + x.resource.number + ")"}</div>
              ) : null )}
            </div>
          </>
        }
        arrow
      >
        {members[0].crew.name}
      </Tooltip>
    );
  }

const CrewSorter = ({ data }: { data: any[] }) => {
  const crews = useMemo(() => {
    const crews: { [key: string]: any[] } = {};
    for (let element of data) {
      if (!element.crew) continue;
      const cid = element.crew.id;
      if (!(cid in crews)) crews[cid] = [];
      crews[cid].push(element);
    }
    return Object.values(crews);
  }, [data]);

  if (crews.length === 0) return null;

return <>{crews.flatMap(crewMembers => [<CrewTooltip members={crewMembers} />, <span>,&nbsp;</span>]).slice(0, -1)}</>

};

const BookingCheck: React.FC<BookingCheckProps> = ({
  job,
  resourceOrder,
  type,
}) => {
  // Implement the logic for BookingCheck component

  const query = useQuery<ResourceBooking[], AxiosResponse>({
    queryKey: ["getResourceBooking", job, resourceOrder],
    enabled: job > 0,
    queryFn: ApiService.getEntitiesWithFilter(
      "resourceBooking",
      {
        job: ["projectJob", job],
        resourceOrder: ["resourceOrder", resourceOrder],
        deletedAt: null,
      },
      "planningState"
    ),
  });

  return (
    <>
      {query.isLoading ? (
        <Loop fontSize="inherit" />
      ) : query.isError ? (
        <Error fontSize="inherit" />
      ) : query.isSuccess ? (
        type === "CREW" ? (
          <CrewSorter data={query.data} />
        ) : type === "HUMAN" ? (
          query.data
            .map((x) => !!x.staffMember ? (x.staffMember.name + ", " + x.staffMember.firstName) : "")
            .join("; ")
        ) : (
          query.data
            .map((x) => !!x.resource ? (x.resource.name + " (" + x.resource.number + ")") : "")
            .join(", ")
        )
      ) : (
        <div>-</div>
      )}
    </>
  );
};

export default BookingCheck;
