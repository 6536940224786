import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import dayjs from "dayjs";
import LabelWrapper from "../../root/LabelWrapper";
import { PositionEditor } from "./PositionEditor";
import {
  initialPosition,
  ProjectPhase,
  ProjectState,
  ResourceOrder,
  SitePosition,
  SubcontractorOrder,
  WithExceptions,
} from "../Project";
import { Delete, WrongLocation, X } from "@mui/icons-material";

import { useEffect, useRef, useState } from "react";
import ResourceTab from "./ResourceTab";
import NewRangePicker from "@/shared/forms/NewRangePicker";

export const gewerke = {
  asphalt: { id: "asphalt", name: "Asphalt" },
  bau: { id: "bau", name: "Bau" },
  sonstiges: { id: "sonstiges", name: "Sonstiges" },
};

const today = dayjs().format("YYYY-MM-DD");

export default function PhaseEditor({
  project,
  phaseId,
  setField,
  setDateChangeNecessary,
  setProjectField,
}: {
  phaseId: number;
  setDateChangeNecessary: any;
  project: ProjectState;
  setProjectField: (changes: Partial<ProjectState>) => void;
  setField: (changes: Partial<ProjectPhase>, createBreakpoint?: boolean) => void;
}) {
  const [currentPage, setCurrentPage] = useState<
    "PHASE" | "RESOURCES" | "MATERIAL" | "TRANSPORT"
  >("PHASE");

  const nameRef = useRef();
  const phase = project.phases[phaseId];

  if (phase.deleted) return null;

  useEffect(() => {
    if (!nameRef.current || phase.name.length !== 0) return;

    nameRef.current.focus();
  }, [nameRef.current]);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          borderTop: "1px solid rgba(0,0,0,0.2)",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderRight: "1px solid #aaa",
            borderLeft: "1px solid rgba(0,0,0,0.2)",
            background: "#edf0f9",
            borderBottom: "1px solid #aaa",
            boxShadow: "inset 0px -4px 5px rgba(0,0,0,0.1)",
            px: 2,
            fontSize: "15px",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          <div>{phase.name || "Unbenannt"}</div>
        </Box>
        <ToggleButtonGroup
          sx={{
            background: "#edf0f9",
            ".MuiToggleButtonGroup-firstButton": {
              borderLeft: "none !important",
            },
            ".MuiToggleButtonGroup-groupedHorizontal.Mui-selected": {
              background: "white !important",
              borderBottom: "none",
              borderLeft: "1px solid #aaa",
            },
            ".MuiToggleButtonGroup-groupedHorizontal": {
              borderRadius: 0,
              borderTop: "none",
              borderBottom: "1px solid #aaa",
              borderRight: "1px solid #aaa",
            },
          }}
          orientation="horizontal"
          exclusive
          value={currentPage}
          onChange={(_, val) => val && setCurrentPage(val)}
        >
          <ToggleButton value="PHASE">Bauphase</ToggleButton>
          <ToggleButton value="RESOURCES">Personal & Maschinen</ToggleButton>
          {/*<ToggleButton value="MATERIAL">Material</ToggleButton>*/}
        </ToggleButtonGroup>
        <Box
          sx={{
            flex: 1,
            background: "#edf0f9",
            borderBottom: "1px solid #aaa",
            borderRight: "1px solid rgba(0,0,0,0.2)",
            boxShadow: "inset 0px -4px 5px rgba(0,0,0,0.1)",
          }}
        ></Box>
      </Box>
      {currentPage === "PHASE" ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            overflow: "hidden",
            alignItems: "stretch",
          }}
        >
          <Box
            sx={{
              minWidth: "400px",
              flex: 1,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LabelWrapper label="Name">
              <TextField
                size="small"
                fullWidth
                value={phase.name}
                inputRef={nameRef}
                placeholder="Name der Phase"
                onChange={(e) =>
                  setField({
                    name: (e.target as HTMLInputElement).value,
                  })
                }
              />
            </LabelWrapper>
            <LabelWrapper label="Gewerk">
              <ManyToOneSelect
                showWithoutChoice
                autoSelect
                remoteFilter={{
                  region: ["region", project.region],
                }}
                sideEffectOnlyOnChange
                sideEffect={(selected) => {
                  const [autoCount, orderCount] = phase.resourceOrders
                    .slice()
                    .reduce(
                      ([ac, oc], current) => [
                        ac + (!current.deleted && !!current.auto ? 1 : 0),
                        oc + (!current.deleted ? 1 : 0),
                      ],
                      [0, 0]
                    );
                  let resourceOrders = phase.resourceOrders.slice();
                  if (orderCount === 0 || autoCount > 0) {
                    resourceOrders = resourceOrders.map((x) => ({
                      ...x,
                      deleted: x.deleted || !!x.auto,
                    }));
                    const tagCount: { [tag: number]: number } = {};
                    for (let po of project.resourceOrders) {
                      if (!!po.deleted) continue;
                      if (!(po.tag ?? 0 in tagCount)) tagCount[po.tag ?? 0] = 0;
                      tagCount[po.tag ?? 0] += po.amount;
                    }
                    for (let ao of selected?.autoOrders ?? []) {
                      const planned = 1; //can later be changed
                      const amount = planned - (tagCount[ao.id] ?? 0);
                      if (amount > 0) {
                        resourceOrders.push({
                          id: 0,
                          deleted: false,
                          amount,
                          tag: ao.id,
                          comment: "",
                          type: ao.type,
                          references: [null],
                          auto: true,
                          exceptions: [],
                        });
                      }
                    }
                  }
                  const afchange =
                    !!phase.changeAutoFill && !!selected
                      ? { autoFill: selected.autoFill }
                      : {};
                  setField({
                    ...afchange,
                    trade: selected?.id || null,
                    resourceOrders,
                  });
                  if (!!afchange.autoFill) {
                    setDateChangeNecessary(true);
                  }
                }}
                placeholder="Bitte wählen..."
                entity={"tradeTemplate"}
                currentId={phase.trade ?? 0}
                setId={(trade) => setField({ trade: trade || null })}
              />
            </LabelWrapper>
            <LabelWrapper label="Geplante Bauzeit">
              <NewRangePicker
                small
                onChange={([startDate, endDate]) => {
                  setDateChangeNecessary(true);
                  setField({
                    plannedStart: !!startDate
                      ? startDate.format("YYYY-MM-DD")
                      : null,
                    plannedEnd: !!endDate
                      ? endDate.format("YYYY-MM-DD")
                      : null,
                  });
                }}
                value={[
                  !!phase.plannedStart
                    ? dayjs(phase.plannedStart)
                    : null,
                  !!phase.plannedEnd ? dayjs(phase.plannedEnd) : null,
                ]}
              />
              {!phase.plannedStart || !phase.plannedEnd ? (
                <Typography variant="caption">
                  Auch auswählbar durch Klick in den Kalender
                </Typography>
              ) : null}
            </LabelWrapper>
            <LabelWrapper label="Automatische Tagesplanungen">
              <ToggleButtonGroup
                exclusive
                value={phase.autoFill}
                onChange={(_, autoFill) => {
                  if (autoFill !== null)
                    setField({ autoFill, changeAutoFill: false });
                  if (!!autoFill) setDateChangeNecessary(true);
                }}
              >
                <ToggleButton color="success" value={true}>
                  Ja
                </ToggleButton>
                <ToggleButton color="error" value={false}>
                  Nein
                </ToggleButton>
              </ToggleButtonGroup>
            </LabelWrapper>
            <LabelWrapper label="Subunternehmer">
              <Autocomplete
                sx={{ mt: "10px", mb: "6px" }}
                value={phase.subcontractor}
                onChange={(_event: any, newValue: number | null) => {
                  const x = {};
                  if (!!phase.resourceOrders[0]?.auto) {
                    x["resourceOrders"] = phase.resourceOrders.slice();
                    x["resourceOrders"][0].deleted = !!newValue;
                    x["resourceOrders"][0].changed = true;
                  }
                  setField({
                    ...x,
                    subcontractor: newValue,
                  });
                }}
                size={"small"}
                fullWidth={true}
                options={project.subcontractorOrders
                  .filter((x) => !x.deleted)
                  .sort(
                    (a, b) =>
                      -b.subcontractor.name.localeCompare(a.subcontractor.name)
                  )
                  .map((x) => x.subcontractor.id)}
                getOptionLabel={(id: number) =>
                  project.subcontractorOrders.reduce(
                    (carry, current) =>
                      id === current.subcontractor.id
                        ? current.subcontractor.name
                        : carry,
                    "(unbekannt)"
                  )
                }
                renderInput={(params: any) => (
                  <TextField {...params} placeholder={"Kein Subunternehmer"} />
                )}
              />
            </LabelWrapper>
            <LabelWrapper label="Kommentar">
              <TextField
                size="small"
                fullWidth
                multiline
                value={phase.comment}
                placeholder="Kommentar"
                onChange={(e) =>
                  setField({
                    comment: (e.target as HTMLInputElement).value,
                  })
                }
              />
            </LabelWrapper>
            <div style={{ flex: 1 }} />
            {project.writeable ? <Button
              sx={{ alignSelf: "flex-start", m: 1 }}
              startIcon={<Delete />}
              color="error"
              variant="outlined"
              onClick={() => setField({ deleted: true },true)}
            >
              Phase löschen
            </Button> : null}
          </Box>
          <Box
            id="mapContainer"
            sx={{
              flex: 10,
              borderLeft: "1px solid #aaa",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ position: "relative", flex: 1 }}>
              <PositionEditor
                position={
                  (phase.position ?? project.position) || initialPosition
                }
                onChange={(changes: Partial<SitePosition>) =>
                  setField({
                    position: {
                      ...((phase.position ?? project.position) ||
                        initialPosition),
                      ...changes,
                    },
                  })
                }
              />
            </Box>
            {!!phase.position ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 1,
                  borderTop: "1px solid #aaa",
                  color: "#777",
                  fontStyle: "italic",
                  fontSize: "80%",
                  alignItems: "center",
                }}
              >
                <Box>Angepasster Ort</Box>
                <Button
                  size="small"
                  onClick={() =>
                    setField({
                      position: null,
                    })
                  }
                  variant="outlined"
                  startIcon={<WrongLocation />}
                >
                  zurücksetzen
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      ) : currentPage === "RESOURCES" ? (
        <ResourceTab
          jobDates={Object.values(phase.jobs).filter(x => !x.deleted).map(x => x.date)}
          region={project.region}
          changeable={project.writeable}
          constraintData={{start: !phase.plannedStart || today > phase.plannedStart ? today : phase.plannedStart, end: !phase.plannedEnd || today > phase.plannedEnd ? today : phase.plannedEnd}}
          projectState={project.resourceOrders}
          setProjectState={(resourceOrders) =>
            setProjectField({ resourceOrders })
          }
          phaseState={phase.resourceOrders}
          setPhaseState={(resourceOrders) => setField({ resourceOrders })}
          jobState={[]}
          setJobState={() => {}}
          jobUuid={null}
          phaseUuid={phase.uuid}
        />
      ) : null}
    </Box>
  );
}
