import dayjs from "dayjs";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface GlobalState {
  pageTitle: string;
  setPageTitle: (title: string) => void;

  getDate: () => dayjs.Dayjs;

  date: string;
  setDate: (date: dayjs.Dayjs) => void;
}

interface PersistantState {
  authenticated: boolean;
  setAuthenticated: (auth: boolean, userId?: number) => void;
  region: number;
  userId: number;
  plantReference: number;
  setPlantReference: (region: number) => void;
  kgpReference: number;
  setKgpReference: (region: number) => void;
  setRegion: (region: number) => void;
  dispoCategory: number;
  setDispoCategory: (dispoCategory: number) => void;
  type: any;
  setType: (type: any) => void;
  reference: any;
  setReference: (reference: any) => void;
  settings: any;
  setSettings: (reference: any) => void;
}

export const useGlobalStore = create<GlobalState>()(
  devtools(
    (set, get) => ({
      pageTitle: "",
      setPageTitle: (pageTitle) => set((state) => ({ pageTitle })),
      getDate: () => dayjs(get().date),
      date: dayjs().add(1, "day").format("YYYY-MM-DD"),
      setDate: (date) => set((state) => ({ date: date.format("YYYY-MM-DD") })),
    }),
    {
      name: "global-storage",
    }
  )
);

const defaultSettings = {
  showCrewMembers: false,
  showDispoResources: true,
  showDispoOrders: true,
  showDispoInformation: true,
  showDispoMaterial: true,
  showCrewResources: false,
  showOtherStaff: false,
  showOtherResources: false,
  showMaterial: false,
};

export const usePersistantStore = create<PersistantState>()(
  devtools(
    persist(
      (set, get) => ({
        region: 0,
        userId: 0,
        plantReference: 0,
        dispoCategory: 0,
        kgpReference:0,
        setKgpReference: (kgpReference) => set((state) => ({ kgpReference })),
        type: null,
        reference: null,
        settings: defaultSettings,
        setSettings: (settings) => set((state) => ({ settings })),
        setType: (type) => set((state) => ({ type })),
        setReference: (reference) => set((state) => ({ reference })),
        setPlantReference: (plantReference) => set((state) => ({ plantReference })),
        setRegion: (region) => set((state) => ({ region })),
        setDispoCategory: (dispoCategory) =>
          set((state) => ({ dispoCategory })),
        authenticated: false,
        setAuthenticated: (authenticated, userId = 0) => {
          let reset = {};
          if (!authenticated) {
            localStorage.setItem(import.meta.env.VITE_APP_NAME + "-token", "");
            localStorage.setItem(
              import.meta.env.VITE_APP_NAME + "-refreshToken",
              ""
            );
            localStorage.setItem(import.meta.env.VITE_APP_NAME + "-rights", "");
          } else if (get().userId !== userId) {
            reset = {
              settings: defaultSettings,
              region: 0,
              plantReference: 0,
              dispoCategory: 0,
              type: null,
              reference: null,
            };
          }
          return set((state) => ({ authenticated, userId, ...reset }));
        },
      }),
      {
        name: "persistant-storage",
      }
    )
  )
);
