import { ArrowForward } from "@mui/icons-material";
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { deDE } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import { useCallback } from "react";

export default function NewRangePicker({
  value,
  onChange,
  small = false,
  maximumDate,
  minimumDate,
  arrowIcon = <ArrowForward />,
}: {
  value: [dayjs.Dayjs | null, dayjs.Dayjs | null];
  onChange: (change: [dayjs.Dayjs | null, dayjs.Dayjs | null]) => void;
  small?: boolean;
  maximumDate?: dayjs.Dayjs;
  minimumDate?: dayjs.Dayjs;
  arrowIcon?: React.ReactNode;
}) {
  const handleStartChange = useCallback(
    (v: dayjs.Dayjs | null) => onChange([v, value[1] || v]),
    [onChange, value]
  );

  const handleEndChange = useCallback(
    (v: dayjs.Dayjs | null) => onChange([value[0] || v, v]),
    [onChange, value]
  );

  const pickerProps = (isStart: boolean) => ({
    slotProps: { textField: { size: small ? "small" : "medium" } },
    maxDate: isStart ? value[1] || maximumDate : maximumDate,
    minDate: isStart ? minimumDate : value[0] || minimumDate,
    value: isStart ? value[0] : value[1],
    onChange: isStart ? handleStartChange : handleEndChange,
    displayWeekNumber: true,
  });

  return (
    <LocalizationProvider
      adapterLocale="de"
      dateAdapter={AdapterDayjs}
      localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <DatePicker {...pickerProps(true)} />
        {arrowIcon}
        <DatePicker {...pickerProps(false)} />
      </Box>
    </LocalizationProvider>
  );
}
