import {
  Paper,
  Button,
  Typography,
  Box,
  IconButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Fragment, useCallback } from "react";
import {
  Add,
  Delete,
  Edit,
  Message,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  initialResourceOrder,
  ResourceOrder,
  WithExceptions,
  WithPhaseExceptions,
} from "../Project";
import EnhancedTable from "@/shared/views/EnhancedTable";
import { TopBar } from "@/shared/structure/TopBar";
import AddResourceDialog from "../dialogs/AddResourceDialog";
import useReferenceNames from "@/shared/forms/useReferenceNames";
import BookingCheck from "../elements/BookingCheck";

const headCells = [
  {
    id: "amount",
    label: "Menge",
    numeric: true,
    sortable: true,
  },
  {
    id: "_category",
    label: "Kategorie",
    numeric: false,
    sortable: true,
  },

  {
    id: "_wish",
    label: "Wunschauswahl",
    numeric: false,
    sortable: true,
  },
  {
    id: "_booking",
    label: "Disponiert",
    numeric: false,
    sortable: true,
  },
  {
    id: "_comment",
    label: "Weitere Informationen",
    numeric: false,
    sortable: true,
  },
];

export default function ResourceTab({
  projectState,
  changeable = true,
  constraintData = {},
  setProjectState,
  phaseState = [],
  setPhaseState = (_e) => {},
  jobState = [],
  setJobState = (_e) => {},
  jobUuid = null,
  jobId =null,
  jobDate = null,
  jobDates = [],
  phaseUuid = null,
  region,
}: {
  region: number;
  changeable: boolean;
  jobDate: string;
  jobDates: string[];
  constraintData: any;
  phaseState: (ResourceOrder & WithExceptions)[];
  setPhaseState: (data: (ResourceOrder & WithExceptions)[]) => void;
  projectState: (ResourceOrder & WithExceptions & WithPhaseExceptions)[];
  setProjectState: (
    data: (ResourceOrder & WithExceptions & WithPhaseExceptions)[]
  ) => void;
  jobState: ResourceOrder[];
  setJobState: (data: ResourceOrder[]) => void;
  jobUuid: string | null;
  jobId: number | null;
  phaseUuid: string | null;
}) {
  const deleteProjectOrder = useCallback(
    (index: number) => {
      setProjectState(
        projectState.slice().map((so, i) => ({
          ...so,
          deleted: !!so.deleted || i === index,
          changed: true
        }))
      );
    },
    [projectState]
  );

  const deletePhaseOrder = useCallback(
    (index: number) => {
      setPhaseState(
        phaseState.slice().map((so, i) => ({
          ...so,
          changed: true,
          deleted: !!so.deleted || i === index,
        }))
      );
    },
    [phaseState]
  );

  const deleteJobOrder = useCallback(
    (index: number) => {
      setJobState(
        jobState.slice().map((so, i) => ({
          ...so,
          changed: true,
          deleted: !!so.deleted || i === index,
        }))
      );
    },
    [jobState]
  );

  const saveProjectOrder = useCallback(
    (
      subOrder: ResourceOrder & WithExceptions & WithPhaseExceptions,
      index: number
    ) => {
      if (index === -1) {
        const newState = projectState.slice();
        newState.push(subOrder);
        setProjectState(newState);
      } else {
        setProjectState(
          projectState.slice().map((so, i) => (i === index ? {...subOrder, changed: true} : so))
        );
      }
    },
    [projectState]
  );

  const savePhaseOrder = useCallback(
    (subOrder: ResourceOrder & WithExceptions, index: number) => {
      if (index === -1) {
        const newState = phaseState.slice();
        newState.push(subOrder);
        setPhaseState(newState);
      } else {
        setPhaseState(
          phaseState.slice().map((so, i) => (i === index ? {...subOrder, changed: true} : so))
        );
      }
    },
    [phaseState]
  );

  const saveJobOrder = useCallback(
    (subOrder: ResourceOrder, index: number) => {
      if (index === -1) {
        const newState = jobState.slice();
        newState.push(subOrder);
        setJobState(newState);
      } else {
        setJobState(
          jobState.slice().map((so, i) => (i === index ? {...subOrder, changed: true} : so))
        );
      }
    },
    [jobState]
  );

  const addPhaseException = useCallback(
    (index: number) => {
      if (!phaseUuid) return;
      setProjectState(
        projectState.slice().map((so, i) => ({
          ...so,
          changed: !!so.changed || i === index,
          phaseExceptions:
            i === index
              ? so.phaseExceptions.slice().concat([phaseUuid])
              : so.phaseExceptions,
        }))
      );
    },
    [projectState, phaseUuid]
  );

  const removePhaseException = useCallback(
    (index: number) => {
      if (!phaseUuid) return;
      setProjectState(
        projectState.slice().map((so, i) => ({
          ...so,
          changed:!!so.changed || i === index,
          phaseExceptions:
            i === index
              ? so.phaseExceptions.slice().filter((x) => x !== phaseUuid)
              : so.phaseExceptions,
        }))
      );
    },
    [projectState, phaseUuid]
  );

  const addException = useCallback(
    (index: number) => {
      if (!jobUuid) return;
      setPhaseState(
        phaseState.slice().map((so, i) => ({
          ...so,
          changed: !!so.changed || i === index,
          exceptions:
            i === index
              ? so.exceptions.slice().concat([jobUuid])
              : so.exceptions,
        }))
      );
    },
    [phaseState, jobUuid]
  );

  const removeException = useCallback(
    (index: number) => {
      if (!jobUuid) return;
      setPhaseState(
        phaseState.slice().map((so, i) => ({
          ...so,
          changed: !!so.changed || i === index,
          exceptions:
            i === index
              ? so.exceptions.slice().filter((x) => x !== jobUuid)
              : so.exceptions,
        }))
      );
    },
    [phaseState, jobUuid]
  );

  const addExceptionToProject = useCallback(
    (index: number) => {
      if (!jobUuid) return;
      setProjectState(
        projectState.slice().map((so, i) => ({
          ...so,
          changed: !!so.changed || i === index,
          exceptions:
            i === index
              ? so.exceptions.slice().concat([jobUuid])
              : so.exceptions,
        }))
      );
    },
    [projectState, jobUuid]
  );

  const removeExceptionFromProject = useCallback(
    (index: number) => {
      if (!jobUuid) return;
      setProjectState(
        projectState.slice().map((so, i) => ({
          ...so,
          changed: !!so.changed || i === index,
          exceptions:
            i === index
              ? so.exceptions.slice().filter((x) => x !== jobUuid)
              : so.exceptions,
        }))
      );
    },
    [projectState, jobUuid]
  );

  const hasJobOrders = jobState.filter((x) => !x.deleted).length > 0;
  const hasPhaseOrders =
    phaseState.filter(
      (x) =>
        !x.deleted &&
        (!jobDate || !x.startConstraint || x.startConstraint <= jobDate) &&
        (!jobDate || !x.endConstraint || x.endConstraint >= jobDate)
    ).length > 0;
  const hasProjectOrders = projectState.filter((x) => !x.deleted).length > 0;

  const tagNames = useReferenceNames("resourceTag");
  const crewNames = useReferenceNames("crew");
  const resourceNames = useReferenceNames("resource");
  const staffNames = useReferenceNames("staffMember");

  const rowFormatter = useCallback(
    (row: ResourceOrder) => {
      console.log(row);
      return ({
      ...row,
      _className:
        (!!row.exceptions && row.exceptions.includes(jobUuid)) ||
        (!!row.phaseExceptions && row.phaseExceptions.includes(phaseUuid))
          ? "strikedOut"
          : "",
      _comment: row.comment || <em style={{ color: "#666" }}>-</em>,
      _category:
        row.type === "CREW"
          ? "Kolonne"
          : row.type === "HUMAN"
          ? "Zusätzlicher Mitarbeiter"
          : tagNames.get(row.tag ?? 0) ?? "unbekannt",
      _booking: jobId ? <BookingCheck job={jobId} resourceOrder={row.id} type={row.type} /> : <em style={{ color: "#666" }}>siehe Tagesplanungen</em>,
      _wish: row.references
        .map((ref) =>
          !!ref
            ? (row.type === "CREW"
                ? crewNames
                : row.type === "HUMAN"
                ? staffNames
                : resourceNames
              ).get(ref)
            : null
        )
        .filter((x) => !!x)
        .join(", ") || <em style={{ color: "#666" }}>-</em>,
    });},
    [tagNames, crewNames, resourceNames, staffNames, jobUuid, phaseUuid]
  );

  console.log(jobDates);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <TopBar>
        {!changeable ? null : !!jobUuid ? (
          <AddResourceDialog<ResourceOrder>
            region={region}
            dates={[jobDate]}
            save={(o) => saveJobOrder(o, -1)}
            OpenButton={({ onClick }) => (
              <Button startIcon={<Add />} onClick={onClick}>
                Bestellung hinzufügen
              </Button>
            )}
            initialState={initialResourceOrder()}
          />
        ) : !!phaseUuid ? (
          <AddResourceDialog<ResourceOrder & WithExceptions>
            region={region}
            dates={jobDates}
            constraintData={constraintData}
            save={(o) => savePhaseOrder(o, -1)}
            OpenButton={({ onClick }) => (
              <Button startIcon={<Add />} onClick={onClick}>
                Eintrag hinzufügen
              </Button>
            )}
            initialState={{
              ...initialResourceOrder(),
              exceptions: [],
            }}
          />
        ) : (
          <AddResourceDialog<
            ResourceOrder & WithExceptions & WithPhaseExceptions
          >
            region={region}
            dates={[]}
            save={(o) => saveProjectOrder(o, -1)}
            OpenButton={({ onClick }) => (
              <Button startIcon={<Add />} onClick={onClick}>
                Eintrag hinzufügen
              </Button>
            )}
            initialState={{
              ...initialResourceOrder(),
              exceptions: [],
              phaseExceptions: [],
            }}
          />
        )}
      </TopBar>
      <Box
        sx={{ flex: 1, overflow: "auto", background: "#f5f5f5", padding: 2 }}
      >
        {!hasJobOrders && !hasPhaseOrders && !hasProjectOrders ? (
          <Alert severity="info" variant="filled">
            <AlertTitle>Nichts gefunden.</AlertTitle>
            Hier wurde noch nichts bestellt.
          </Alert>
        ) : null}
        {hasJobOrders ? (
          <Typography variant="h6" gutterBottom>
            Nur heute
          </Typography>
        ) : null}
        {hasJobOrders ? (
          <Paper>
            <EnhancedTable
              initialRows={100}
              buttons={
                !changeable
                  ? (row) => null
                  : (row) => (
                      <Fragment>
                        <AddResourceDialog<ResourceOrder>
                          region={region}
                          dates={[jobDate]}
                          save={(order) => saveJobOrder(order, row.index)}
                          initialState={jobState[row.index]}
                          OpenButton={({ onClick }) => (
                            <IconButton size="small" onClick={onClick}>
                              <Edit />
                            </IconButton>
                          )}
                        />
                        <IconButton
                          size="small"
                          onClick={() => deleteJobOrder(row.index)}
                        >
                          <Delete />
                        </IconButton>
                      </Fragment>
                    )
              }
              type=""
              noEmptyCells
              initialSort="_category"
              headCells={headCells}
              rowFormatter={rowFormatter}
              rows={jobState
                .map((x, index) => ({ ...x, index }))
                .filter((x) => !x.deleted)}
            />
          </Paper>
        ) : null}
        {hasPhaseOrders && !!jobUuid ? (
          <Typography
            sx={{ mt: hasJobOrders ? 2 : 0 }}
            variant="h6"
            gutterBottom
          >
            Bauphase
          </Typography>
        ) : null}
        {hasPhaseOrders ? (
          <Paper>
            <EnhancedTable
              initialRows={100}
              buttons={
                !changeable
                  ? (row) => null
                  : !!jobUuid
                  ? (row) => (
                      <Fragment>
                        {row.exceptions.includes(jobUuid) ? (
                          <IconButton
                            size="small"
                            onClick={() => removeException(row.index)}
                          >
                            <Visibility />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            onClick={() => addException(row.index)}
                          >
                            <VisibilityOff />
                          </IconButton>
                        )}
                      </Fragment>
                    )
                  : (row) => (
                      <Fragment>
                        <AddResourceDialog<ResourceOrder & WithExceptions>
                          region={region}
                          dates={jobDates}
                          constraintData={constraintData}
                          save={(order) => savePhaseOrder(order, row.index)}
                          initialState={phaseState[row.index]}
                          OpenButton={({ onClick }) => (
                            <IconButton size="small" onClick={onClick}>
                              <Edit />
                            </IconButton>
                          )}
                        />
                        <IconButton
                          size="small"
                          onClick={() => deletePhaseOrder(row.index)}
                        >
                          <Delete />
                        </IconButton>
                      </Fragment>
                    )
              }
              type=""
              noEmptyCells
              initialSort="_category"
              headCells={headCells}
              rowFormatter={rowFormatter}
              rows={phaseState
                .map((x, index) => ({ ...x, index }))
                .filter(
                  (x) =>
                    !x.deleted &&
                    (!jobDate || !x.startConstraint || x.startConstraint <= jobDate) &&
                    (!jobDate || !x.endConstraint || x.endConstraint >= jobDate)
                )}
            />
          </Paper>
        ) : null}

        {hasProjectOrders && !!phaseUuid ? (
          <Typography
            sx={{ mt: hasJobOrders || hasPhaseOrders ? 2 : 0 }}
            variant="h6"
            gutterBottom
          >
            Projekt
          </Typography>
        ) : null}
        {hasProjectOrders ? (
          <Paper>
            <EnhancedTable
              initialRows={100}
              buttons={
                !changeable
                  ? (row) => null
                  : !!jobUuid
                  ? (row) => (
                      <Fragment>
                        {row.phaseExceptions.includes(
                          phaseUuid
                        ) ? null : row.exceptions.includes(jobUuid) ? (
                          <IconButton
                            size="small"
                            onClick={() =>
                              removeExceptionFromProject(row.index)
                            }
                          >
                            <Visibility />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            onClick={() => addExceptionToProject(row.index)}
                          >
                            <VisibilityOff />
                          </IconButton>
                        )}
                      </Fragment>
                    )
                  : !!phaseUuid
                  ? (row) => (
                      <Fragment>
                        {row.phaseExceptions.includes(phaseUuid) ? (
                          <IconButton
                            size="small"
                            onClick={() => removePhaseException(row.index)}
                          >
                            <Visibility />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            onClick={() => addPhaseException(row.index)}
                          >
                            <VisibilityOff />
                          </IconButton>
                        )}
                      </Fragment>
                    )
                  : (row) => (
                      <Fragment>
                        <AddResourceDialog<
                          ResourceOrder & WithExceptions & WithPhaseExceptions
                        >
                        dates={[]}
                          region={region}
                          save={(order) => saveProjectOrder(order, row.index)}
                          initialState={projectState[row.index]}
                          OpenButton={({ onClick }) => (
                            <IconButton size="small" onClick={onClick}>
                              <Edit />
                            </IconButton>
                          )}
                        />
                        <IconButton
                          size="small"
                          onClick={() => deleteProjectOrder(row.index)}
                        >
                          <Delete />
                        </IconButton>
                      </Fragment>
                    )
              }
              type=""
              noEmptyCells
              initialSort="_category"
              headCells={headCells}
              rowFormatter={rowFormatter}
              rows={projectState
                .map((x, index) => ({ ...x, index }))
                .filter((x) => !x.deleted)}
            />
          </Paper>
        ) : null}
      </Box>
    </Box>
  );
}
