import { Box, CircularProgress, Paper, Tooltip } from "@mui/material";
import { UseQueryResult } from "@tanstack/react-query";
import { Fragment } from "react/jsx-runtime";

function SpecializedTooltip({ data, children }) {
  if (!("leader" in data)) return children;
  return (
    <Tooltip
      title={
        <Fragment>
          <div style={{ marginBottom: "5px" }}>
            {data.members.map((x) => (
              <div style={x.id === data.leader ? { fontWeight: "bold" } : {}}>
                {x.name + ", " + x.firstName}
              </div>
            ))}
          </div>
          <div>
            {data.resources.map((x) => (
              <div>{x.name + " (" + x.number + ")"}</div>
            ))}
          </div>
        </Fragment>
      }
      arrow
    >
      {children}
    </Tooltip>
  );
}

export default function MultiSelector({
  onChange,
  value,
  query,
}: {
  query: UseQueryResult;
  value: (number | null)[];
  onChange: (references: (number | null)[]) => void;
}) {
  const onClick = (id) => {
    if (value.includes(id)) {
      return onChange(value.map((x) => (x === id ? null : x)));
    } else {
      let index = -1;
      for (let i = 0; i < value.length; i++) {
        if (!!value[i]) continue;
        index = i;
        break;
      }
      if (index >= 0) {
        return onChange(value.map((x, i) => (i === index ? id : x)));
      } else {
        return onChange(value.slice(1).concat([id]));
      }
    }
  };

  return query.isPending ? (
    query.isFetching ? (
      <CircularProgress />
    ) : (
      "Bitte wählen Sie eine Kategorie"
    )
  ) : query.isError ? (
    "Fehler"
  ) : query.data.length === 0 ? (
    "Nichts gefunden"
  ) : (
    <Paper
      variant="outlined"
      sx={{ display: "flex", flexWrap: "wrap", gap: 1, p: 1 }}
    >
      {query.data.map((x) => (
        <SpecializedTooltip data={x}>
          <Box
            className={
              (x?.status === "FREE"
                ? "color green"
                : x?.status === "BOOKED"
                ? "color red"
                : "color grey") + (value.includes(x.id) ? " selected" : "")
            }
            sx={{
              border: "1px solid #aaa",
              p: "2px",
              lineHeight: 1,
              userSelect: "none",
              fontSize: "14px",
              flex: 1,
              cursor: "pointer",
              whiteSpace: "nowrap",
              borderRadius: "3px",
              "&:hover": { opacity: 0.7 },
            }}
            onClick={() => onClick(x.id)}
          >
            {x.name}
            {"firstName" in x ? ", " + x.firstName : ""}
            {!!x.number ? " (" + x.number + ")" : ""}
          </Box>
        </SpecializedTooltip>
      ))}
    </Paper>
  );
}
