import dayjs from "dayjs";
import { CalJob } from "../calendarElements/InnerCalendar";
import { WithOrders } from "./CalendarListView";
import {
  AccountBalance,
  AccountCircle,
  ChatBubble,
  Checklist,
  LocalShipping,
  Schedule,
  Send,
} from "@mui/icons-material";
import { Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { createDeliveryText } from "@/components/planning/editors/MaterialTab";
import { Button } from "@mui/material";
import { DirectBooking } from "./DirectBooking";
import { DirectMaterialBooking } from "./DirectMaterialBooking";
import { DirectMaterialEditor } from "./DirectMaterialEditor";

const groupBookings = (bookings: any[]) => {
  const groups: { [tag: string]: any[] } = {};
  for (const b of bookings) {
    const res = b.type === "HUMAN" ? b.staffMember : b.resource;
    if (!res || b.deleted) continue;
    let tag = res.tags
      .filter((x) => ["WISHABLE", "REQUESTABLE"].includes(x.calendarType))
      .map((x) => x.name)
      .sort()
      .join(", ");
    if (tag.length === 0)
      tag = res.tags
        .filter((x) =>
          ["WISHABLE", "REQUESTABLE", "CALENDAR_ONLY"].includes(x.calendarType)
        )
        .map((x) => x.name)
        .sort()
        .join(", ");
    if (tag.length === 0) tag = b.type === "HUMAN" ? "Mitarbeiter" : "Maschine";
    if (!(tag in groups)) groups[tag] = [];
    groups[tag].push(b);
  }
  return Object.entries(groups).sort((a, b) =>
    a[1][0].type === b[1][0].type
      ? a[0].localeCompare(b[0])
      : b[1][0].type.localeCompare(a[1][0].type)
  );
};

export default function OrderInformation({
  data,
  showMaterial = true,
  showResources = true,
  withDirectBooking = false,
  showInfo = true,
  showOrders = true,
  setCalendar
}: {
  data: CalJob & WithOrders;
  showMaterial: boolean;
  withDirectBooking?: boolean;
  showResources: boolean;
  showOrders: boolean;
}) {
  const comments = [
    [data.comment, ""],
    [data.phase.comment, " zur Bauphase"],
    [data.phase.project.comment, " zum Projekt"],
  ]
    .map(([x, y]) => [x.trim(), y])
    .filter(([x, y]) => !!x);

  const gb = showResources
    ? groupBookings(data.resourceBookingsAndOrders.resourceBookings.slice() ?? [])
    : [];

  return (
    <div className="orderInformationWrapper">
      {showInfo ? <div className="oIDataWrapper">
        <div className="oIDataLine">
          <div className="oIDataIcon">
            <Checklist fontSize="inherit" />
          </div>
          <div className="oIDataTextWrapper">
            <div className="oIDataDescription">Bestellstatus</div>
            <div className="oIDataText">
              {!data.orderSentAt
                ? "noch nicht bestellt - Planung"
                : "bestellt von " +
                  data.orderSentBy.firstName +
                  " " +
                  data.orderSentBy.name +
                  " " +
                  dayjs
                    .utc(data.orderSentAt, "YYYY-MM-DD HH:mm:ss")
                    .local()
                    .fromNow()}
            </div>
          </div>
        </div>

        <div className="oIDataLine">
          <div className="oIDataIcon">
            <Schedule fontSize="inherit" />
          </div>
          <div className="oIDataTextWrapper">
            <div className="oIDataDescription">Beginn</div>
            <div className="oIDataText">
              {(!!data.startTime
                ? "ab " + data.startTime + " Uhr"
                : !!data.firstUnload
                ? "Erster LKW kommt um " + data.firstUnload + " Uhr"
                : "Beginn nach Absprache") + (data?.duration === "HALF" ? " - halbtags" : "")}
            </div>
          </div>
        </div>

        {!!data.phase.project.manager ? (
          <div className="oIDataLine">
            <div className="oIDataIcon">
              <AccountCircle fontSize="inherit" />
            </div>
            <div className="oIDataTextWrapper">
              <div className="oIDataDescription">Bauleiter</div>
              <div className="oIDataText">
                {data.phase.project.manager.firstName +
                  " " +
                  data.phase.project.manager.name}
              </div>
            </div>
          </div>
        ) : null}

        {!!data.phase.project.client ? (
          <div className="oIDataLine">
            <div className="oIDataIcon">
              <AccountBalance fontSize="inherit" />
            </div>
            <div className="oIDataTextWrapper">
              <div className="oIDataDescription">Kunde</div>
              <div className="oIDataText">{data.phase.project.client.name}</div>
            </div>
          </div>
        ) : null}

        {comments.map(([comment, subtitle], i) => (
          <div key={"komm" + i} className="oIDataLine">
            <div className="oIDataIcon">
              <ChatBubble fontSize="inherit" />
            </div>
            <div className="oIDataTextWrapper">
              <div className="oIDataDescription">Kommentar{subtitle}</div>
              <div className="oIDataText">{comment}</div>
            </div>
          </div>
        ))}
      </div> : null}
      {showMaterial ? (
        <Fragment>
          <div className="oIDataHeader"><div className="oIttt">Material & Logistik</div>{withDirectBooking ? <DirectMaterialBooking job={data.id} date={data.date} region={data.phase.project.region} project={data.phase.project.id} /> : null}</div>
          {data.materialOrders.length === 0 ? (
            <div className="oIZero">Keine Bestellung</div>
          ) : (
            <div className="oIMaterialWrapper">
              {data.materialOrders.filter(x => !x.deleted).map((order) => (
                <div key={order.id} className="oImaterialLine">
                  <div className="oIMaterialComponent oitime">
                      <Schedule fontSize="inherit" />{!!order.firstUnload ? order.firstUnload : "auf Abruf"}
                    
                  </div>
                  <div className="oIMaterialComponent oiarticle">
                      <div>{!!order.article
                        ? order.amount +
                          " " + order.article.unit + " " +
                          order.article.name +
                          " von " +
                          order.article.plant.name +
                          " (" +
                          order.article.number +
                          ")"
                        : "nur Baustellendienst"}</div>
                      {order.additionalArticles.map(x => <div key={x.id}>{x.amount +
                          " " + x.article.unit + " " +
                          x.article.name +
                          " (" +
                          x.article.number}</div>)}
                  </div>
                  
                      
                      {(uuu => uuu.length > 0 ? <div className="oIMaterialComponent oitruck"><LocalShipping fontSize="inherit" />{uuu}</div>: null)(createDeliveryText(
                        order.transportInformation,
                        order.takt
                      ))}
                  
                  <div className="oIMaterialComponent oicomment">
                    {order.comment.length > 0 ? (
                      <Fragment>
                        
                          <ChatBubble fontSize="inherit" />
                        {order.comment}
                      </Fragment>
                    ) : null}
                  </div>
                  {withDirectBooking ? <DirectMaterialEditor order={order} job={data.id} date={data.date} region={data.phase.project.region} project={data.phase.project.id} /> : null}
                  {order.transportInformation?.length > 0 ? <div className="oIMaterialComponent oibutton">
                    <Button
                      variant="contained"
                      size="small"
                      color="info"
                      startIcon={<Send />}
                    >
                      #transplan
                    </Button>
                  </div> : null}
                </div>
              ))}
            </div>
          )}
        </Fragment>
      ) : null}
      {showResources || showOrders ? (
        <Fragment>
          <div className="oIDataHeader"><div className="oIttt">Geräte & Mitarbeiter</div>{withDirectBooking ? <DirectBooking job={data.id} date={data.date} region={data.phase.project.region} project={data.phase.project.id} /> : null}</div>
          {data.resourceBookingsAndOrders.resourceOrders.length + gb.length === 0 ? (
            <div className="oIZero">Keine Bestellung</div>
          ) : (
            <div className="oIResourceWrapper">
              {showOrders && (data.resourceBookingsAndOrders.resourceOrders.filter(x => !x.deleted).length > 0 || withDirectBooking) ? (
                <div className="oiOpen">
                  {data.resourceBookingsAndOrders.resourceOrders.filter(x => !x.deleted).map((o) => (
                    <div key={o.id} className="oiOpenResource" onClick={!!o.rejection ? undefined : 
                        () => setCalendar(o.type === "CREW" ? "CREWS" : o.type === "RESOURCE" ? "RESOURCES" : "STAFF", o.tag?.id ?? null, dayjs(data.date,"YYYY-MM-DD"))
                      }>
                      <div className="oiResTitle" style={!!o.rejection ? {textDecoration:"line-through"} :{}}>
                        {o.open + "x "}
                        {o.type === "CREW" ? "Kolonne " : ""}
                        {o.tag?.name}
                      </div>
                      {o.comment.length > 0 ? (
                        <div className="oiOpenResComment">{o.comment}</div>
                      ) : null}
                      {!!o.rejection ? (
                        <div className="oiOpenResComment">{o.rejection.comment} ({o.rejection.createdBy.firstName} {o.rejection.createdBy.name})</div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null}
              {gb.map(([title, orders]) => (
                <div key={title} className="oiRealRes">
                  <div className="oiRealResTitle">{title}</div>
                  <div className="oiResNames">
                    {orders
                      .sort((a, b) =>
                        a.type !== b.type
                          ? a.type.localeCompare(b.type)
                          : a.type === "RESOURCE"
                          ? (a.resource?.name ?? "Z").localeCompare(
                              b.resource?.name ?? "Z"
                            )
                          : a.isLeader && !b.isLeader
                          ? -1
                          : !a.isLeader && b.isLeader
                          ? 1
                          : (a.staffMember?.name ?? "Z").localeCompare(
                              b.staffMember?.name ?? "Z"
                            )
                      )
                      .map((o, i, f) =>
                        o.type === "HUMAN"
                          ? o.staffMember?.name
                          : o.resource?.name + " (" + o.resource?.number + ")"
                      ).join("; ")}
                  </div>
                </div>
              ))}
            </div>
          )}
        </Fragment>
      ) : null}
    </div>
  );
}
