import dayjs from "dayjs";
import { nosec } from "../calendarElements/ResourceLine";
import { ResourceBookingType, SpBookingType, GhostType } from "../KGP";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useEffect, useState } from "react";


export default function KGPResource({
  y,
  parent,
  disabled
}: {
  disabled: boolean;
  y: ResourceBookingType | SpBookingType | GhostType;
  parent: { type: "specialPlace" | "project" | "none"; id: number | null };
}) {

    const [waitForUpdate, setWaitForUpdate] = useState(false);

    const [{ isDragging }, dragRef, dragPreview] = useDrag(
        () => ({
          type: y.type,
          item: y,
          collect: (monitor) => ({
            isDragging: monitor.isDragging()
          }),
          canDrag: _monitor => !disabled,
          end: (item, monitor) => {
            if (monitor.didDrop()) {
              setWaitForUpdate(true);
            }
          },
        }),
        [disabled]
      )

      useEffect(() => {
        dragPreview(getEmptyImage(), { captureDraggingState: true });
      }, []);


  const hidePosition =
    !!y.resource.lastLocation &&
    !y.resource.lastLocation.free &&
    ((parent.type === "project" &&
      !!y.resource.lastLocation.project &&
      y.resource.lastLocation.project.id === parent.id) ||
      (parent.type === "specialPlace" &&
        !!y.resource.lastLocation.specialPlace &&
        y.resource.lastLocation.specialPlace.id === parent.id));

  return (
    <div
      ref={dragRef}
      className={
        "kgpcontent " +
        (isDragging || waitForUpdate ? "dragging " : "") +
        y.type +
        (hidePosition ? " hiddenPos" : " alwaysvVisiblePos")
      }
    >
      <div className="kgpresname">
        <span className="kgpresnumber">{y.resource.number}</span>
        {y.resource.name}
      </div>
      {!!y.resource.lastLocation ? (
        <div className="kgp_location">
          {!!y.resource.lastLocation.free ? (
            <div className="kgp_free">frei</div>
          ) : null}
          {(parent.type === "project" &&
            !!y.resource.lastLocation.project &&
            y.resource.lastLocation.project.id === parent.id) ||
          (parent.type === "specialPlace" &&
            !!y.resource.lastLocation.specialPlace &&
            y.resource.lastLocation.specialPlace.id === parent.id) ? null : (
            <div className="kgp_locname">
              {!!y.resource.lastLocation.project
                ? y.resource.lastLocation.project.name
                : !!y.resource.lastLocation.specialPlace
                ? y.resource.lastLocation.specialPlace.name
                : y.resource.lastLocation.locationName}

              
            </div>
          )}
          <div className="kgp_age">
            (
            {nosec(
              dayjs(y.resource.lastLocation.date, "YYYY-MM-DD").from(
                dayjs().startOf("day")
              )
            )}
            )
          </div>
        </div>
      ) : null}
    </div>
  );
}
