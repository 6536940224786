import { SatelliteAlt } from "@mui/icons-material";

interface SimpleType {
  type: "string" | "boolean" | "tableOnly";
}

interface ManyToOneType {
  type: "ManyToOne";
  relation: string;
  regionalized: boolean;
}

interface ManyToManyType {
  type: "ManyToMany";
  relation: string | ((object: any) => string);
  regionalized: boolean;
  remoteFilter?: any;
  groupBy?: (object: any) => string;
}

interface EnumType {
  type: "enum";
  values: {
    [key: string]: string;
  };
}

type FieldType = SimpleType | EnumType | ManyToOneType | ManyToManyType;

type Field = {
  id: string;
  title: string;
  table: boolean;
  default: any;
  disableOnEdit?: boolean;
  disabledFn?: (state:any) => boolean;
  sideEffectFn?: (state: any) => any;
} & FieldType;

export interface SingleDefinition {
  title: string;
  deleteable: boolean;
  sortBy: string;
  regionalized: boolean;
  createable: boolean;
  fields: Field[];
}

interface Definition {
  [id: string]: SingleDefinition;
}

export const displayFunctions = {
  staffMember: (x) => !x ? "" : (x.name + ", " + x.firstName + " [" + x.number + "]"),
  resource: (x) => !x ? "" : (x.name + " [" + x.number + "]"),
};

const def: Definition = {
  resourceTag: {
    title: "Kategorien",
    regionalized: true,
    deleteable: true,
    createable: true,
    sortBy: "_type",
    fields: [
      {
        id: "name",
        type: "string",
        title: "Name",
        table: true,
        default: "",
      },
      {
        id: "superCategory",
        type: "string",
        title: "Oberkategorie",
        table: true,
        default: "",
      },
      {
        id: "usesShortPlanning",
        type: "boolean",
        title: "Kleingerät",
        table: true,
        default: false,
        disabledFn: state => state.type !== "RESOURCE"
      },
      {
        id: "type",
        type: "enum",
        disableOnEdit: true,
        values: {
          HUMAN: "Mitarbeiter",
          CREW: "Kolonnen",
          RESOURCE: "Geräte",
        },
        title: "Typ",
        table: true,
        default: "RESOURCE",
        sideEffectFn: state => ({...state, usesShortPlanning: state.type !== "RESOURCE" ? false : state.usesShortPlanning})
      },
      {
        id: "calendarType",
        title: "Bestellart (Planung)",
        table: true,
        type: "enum",
        default: "NONE",
        values: {
          NONE: "Ausgeblendet",
          CALENDAR_ONLY: "Nur im Kalender",
          REQUESTABLE: "Bestellbar",
          WISHABLE: "Mit Wunsch bestellbar"
        },
      },
      {
        id: "appCalendarType",
        title: "Bestellart (App)",
        table: true,
        type: "enum",
        default: "NONE",
        values: {
          NONE: "Ausgeblendet",
          REQUESTABLE: "Bestellbar",
          WISHABLE: "Mit Wunsch bestellbar"
        },
      },
      {
        id: "dispoCategory",
        title: "Disposition",
        table: true,
        type: "ManyToOne",
        relation: "dispoCategory",
        regionalized: true,
        default: null,
      },
      {
        id: "members",
        title: "Bestandteile",
        table: false,
        type: "ManyToMany",
        regionalized: true,
        relation: ({ type }: { type: "HUMAN" | "CREW" | "RESOURCE" }) =>
          type === "HUMAN"
            ? "staffMember"
            : type === "CREW"
            ? "crew"
            : "resource",
        default: [],
      },
    ],
  },
  dispoCategory: {
    title: "Disposition",
    regionalized: true,
    deleteable: true,
    createable: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        type: "string",
        title: "Name",
        table: true,
        default: "",
      },
      {
        id: "resourceTags",
        title: "Kategorien",
        table: false,
        type: "ManyToMany",
        regionalized: true,
        relation: "resourceTag",
        default: [],
      },
    ],
  },
  specialPlace: {
    title: "Buchorte",
    regionalized: true,
    deleteable: true,
    createable: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        type: "string",
        title: "Name",
        table: true,
        default: "",
      },
      {
        id: "number",
        type: "string",
        title: "Nummer",
        table: true,
        default: "",
      },
    ],
  },
  crew: {
    title: "Kolonnen",
    deleteable: true,
    createable: true,
    regionalized: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "leader",
        title: "Polier",
        table: false,
        type: "ManyToOne",
        relation: "staffMember",
        regionalized: true,
        default: null,
      },
      {
        id: "members",
        title: "Mitarbeiter",
        table: false,
        type: "ManyToMany",
        regionalized: true,
        relation: "staffMember",
        default: [],
      },
      {
        id: "resources",
        title: "Geräte",
        table: false,
        type: "ManyToMany",
        regionalized: true,
        relation: "resource",
        default: [],
      },
      {
        id: "tags",
        title: "Kategorien",
        table: true,
        type: "ManyToMany",
        regionalized: true,
        remoteFilter: { type: "CREW" },
        relation: "resourceTag",
        default: [],
      },
    ],
  },
  resource: {
    title: "Geräte",
    deleteable: false,
    createable: false,
    regionalized: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        disableOnEdit: true,
        default: "",
      },
      {
        id: "number",
        title: "Nummer",
        table: true,
        disableOnEdit: true,
        type: "string",
        default: "",
      },
      {
        id: "tags",
        title: "Kategorien",
        table: true,
        type: "ManyToMany",
        regionalized: true,
        relation: "resourceTag",
        remoteFilter: { type: "RESOURCE" },
        default: [],
      },
    ],
  },
  staffMember: {
    title: "Mitarbeiter",
    deleteable: false,
    createable: false,
    regionalized: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        disableOnEdit: true,
        default: "",
      },
      {
        id: "firstName",
        title: "Vorname",
        table: true,
        type: "string",
        disableOnEdit: true,
        default: "",
      },
      {
        id: "number",
        title: "Nummer",
        table: true,
        type: "string",
        disableOnEdit: true,
        default: "",
      },
      {
        id: "tags",
        title: "Kategorien",
        table: true,
        type: "ManyToMany",
        regionalized: true,
        relation: "resourceTag",
        remoteFilter: { type: "HUMAN" },
        default: [],
      },
    ],
  },
  supplier: {
    title: "Lieferanten",
    deleteable: false,
    createable: false,
    regionalized: false,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "number",
        title: "Nummer",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "contact",
        title: "Kontakt",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "phone",
        title: "Telefon",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "email",
        title: "E-Mail",
        table: false,
        type: "string",
        default: "",
      },
    ],
  },
  plant: {
    title: "Lieferwerke",
    deleteable: false,
    createable: false,
    regionalized: false,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "shortName",
        title: "Kurzname",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "number",
        title: "Nummer",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "supplierName",
        title: "Lieferant",
        table: true,
        type: "tableOnly",
        default: "",
      },
      {
        id: "contact",
        title: "Kontakt",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "phone",
        title: "Telefon",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "email",
        title: "E-Mail",
        table: false,
        type: "string",
        default: "",
      },
    ],
  },
  client: {
    title: "Auftraggeber",
    deleteable: false,
    createable: false,
    regionalized: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        disableOnEdit: true,
        default: "",
      },
      {
        id: "number",
        title: "Nummer",
        disableOnEdit: true,
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "contact",
        title: "Kontakt",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "phone",
        title: "Telefon",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "email",
        title: "E-Mail",
        table: false,
        type: "string",
        default: "",
      },
    ],
  },
  subcontractor: {
    title: "Subunternehmen",
    deleteable: true,
    regionalized: false,
    createable: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "number",
        title: "Nummer",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "contact",
        title: "Kontakt",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "phone",
        title: "Telefon",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "email",
        title: "E-Mail",
        table: false,
        type: "string",
        default: "",
      },
    ],
  },
  stoffstromCategory: {
    title: "Stoffstrom-Kategorien",
    deleteable: true,
    regionalized: false,
    createable: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
    ],
  },
  tradeTemplate: {
    title: "Gewerke",
    deleteable: true,
    regionalized: true,
    createable: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "autoFill",
        type: "boolean",
        title: "Bauphasen automatisch füllen",
        table: false,
        default: false,
      },
      {
        id: "usesAsphaltPlanningStates",
        type: "boolean",
        title: "Grob-/Feinplanung erfassen?",
        table: false,
        default: false,
      },
      {
        id: "autoOrders",
        title: "Standardbedarf",
        table: false,
        type: "ManyToMany",
        regionalized: true,
        groupBy: (x) =>
          !x ? "" : x.type === "HUMAN"
            ? "Mitarbeiter"
            : x.type === "CREW"
            ? "Kolonnen"
            : "Geräte",
        relation: "resourceTag",
        default: [],
      },
    ],
  },
  holiday: {
    title: "Schließtag",
    deleteable: true,
    regionalized: true,
    createable: true,
    sortBy: "date",
    fields: [
      {
        id: "title",
        title: "Bezeichnung",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "date",
        type: "string",
        title: "Datum",
        table: true,
        default: "2025-01-01",
      },
    ],
  },
  user: {
    title: "Benutzer",
    createable: true,
    deleteable: true,
    regionalized: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "firstName",
        title: "Vorname",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "email",
        title: "E-Mail",
        table: true,
        type: "string",
        default: "",
      },
    ],
  },
};

export default def;
