import dayjs from "dayjs";
import { initialJob, ProjectJob, ProjectPhase, ProjectState } from "../Project";
import {
  CalendarContext,
  CalendarElementsProps,
} from "../../shared/VerticalCalendar";
import { Fragment } from "react/jsx-runtime";
import { useState, useLayoutEffect, useMemo, useContext } from "react";
import JobLine from "./JobLine";
import useRights from "@/shared/api/useRights";
import { LockResetOutlined } from "@mui/icons-material";

const blankImage = new Image();
blankImage.src =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";

export const moveJobs = (jobs: ProjectPhase["jobs"], days: number) => {
  return Object.fromEntries(
    Object.values(jobs).map((job) => {
      const date = dayjs(job.date, "YYYY-MM-DD")
        .add(days, "days")
        .format("YYYY-MM-DD");
      return [date, { ...job, date, changed: true }];
    })
  );
};

export const moveResourceOrders = (
  ro: ProjectPhase["resourceOrders"],
  days: number
) => {
  return ro.map((r) => ({
    ...r,
    startConstraint: !!r.startConstraint
      ? dayjs(r.startConstraint, "YYYY-MM-DD")
          .add(days, "days")
          .format("YYYY-MM-DD")
      : null,
    endConstraint: !!r.endConstraint
      ? dayjs(r.startConstraint, "YYYY-MM-DD")
          .add(days, "days")
          .format("YYYY-MM-DD")
      : null,
    changed: !!r.changed || !!r.startConstraint || !!r.endConstraint
  }));
};

const findNext = (
  date: string,
  mapdata: { addMap: { [origin: string]: string }; dateKeys: string[] },
  direction: -1 | 1
) => {
  if (date in mapdata.addMap) return mapdata.addMap[date];
  if (
    date < mapdata.dateKeys[0] ||
    date > mapdata.dateKeys[mapdata.dateKeys.length - 1]
  ) {
    return date;
  }
  if (direction === 1) {
    for (let i = mapdata.dateKeys.length - 1; i >= 0; i--) {
      if (mapdata.dateKeys[i] < date)
        return mapdata.addMap[mapdata.dateKeys[i]];
    }
  } else {
    for (let i = 0; i < mapdata.dateKeys.length; i++) {
      if (mapdata.dateKeys[i] > date)
        return mapdata.addMap[mapdata.dateKeys[i]];
    }
  }
  return date;
};

export const spreadResourceOrders = (
  ro: ProjectPhase["resourceOrders"],
  addMap: { [origin: string]: string },
  direction: number
) => {
  if (direction === 0) return ro;
  const dateKeys = Object.keys(addMap).sort();
  return ro.map((r) => ({
    ...r,
    startConstraint: !!r.startConstraint
      ? findNext(r.startConstraint, { dateKeys, addMap }, direction)
      : null,
    endConstraint: !!r.endConstraint
      ? findNext(r.endConstraint, { dateKeys, addMap }, direction)
      : null,
    changed: !!r.changed || !!r.endConstraint || !!r.startConstraint
  }));
};

function getMinMax(arr: string[]) {
  if (!arr) {
    return [null, null];
  }
  var minV = arr[0];
  var maxV = arr[0];
  for (let a of arr) {
    if (a < minV) minV = a;
    if (a > maxV) maxV = a;
  }
  return [minV, maxV];
}

export default function ProjectPhaseLine({
  project,
  setField,
  line,
  phaseId,
  weekplanning,
  currentSelection,
  setCurrentSelection,
  projectStartAdd,
  lockOrder
}: {
  weekplanning: boolean;
  project: ProjectState;
  setField: (
    index: number,
    changes: Partial<ProjectPhase>,
    isDateChange?: boolean
  ) => void;
  line: number;
  phaseId: number;
  lockOrder: boolean;
}) {
  const view = useContext(CalendarContext);

  const onDateChange = (plannedStart, plannedEnd, moveAdd) => {
    const movedJobs =
      moveAdd === 0
        ? Object.fromEntries(
            Object.entries(phase.jobs).map((x) => [
              x[0],
              {
                ...x[1],
                changed: !!x[1].changed || (x[1].deleted !== (x[1].deleted ||
                  x[1].date < plannedStart ||
                  x[1].date > plannedEnd)),
                deleted:
                  x[1].deleted ||
                  x[1].date < plannedStart ||
                  x[1].date > plannedEnd,
              },
            ])
          )
        : moveJobs(phase.jobs, moveAdd);

    setField(
      phaseId,
      {
        plannedStart,
        plannedEnd,
        resourceOrders:
          moveAdd === 0
            ? phase.resourceOrders
            : moveResourceOrders(phase.resourceOrders, moveAdd),
        jobs: movedJobs,
      },
      true
    );
    setCurrentSelection({ phase: phaseId, date: null });
  };

  const changeDates = (addMap, origin) => {
    console.log("CHANGEDATE",addMap, origin);
    if (Object.keys(addMap).length === 0) return;
    const oldjobs = Object.values(phase.jobs);
    const newjobs = oldjobs.map((job) => {
      return [
        addMap[job.date] ?? job.date,
        { ...job, changed: !!job.changed || (job.date !== (addMap[job.date] ?? job.date)), date: addMap[job.date] ?? job.date },
      ];
    });

    const njobj = Object.fromEntries(newjobs);

    if ((new Set(oldjobs.map(x => x.date))).size !== Object.keys(njobj).length) {
      console.log("ILLEGAL DATE CHANGE");
      setField(
        phaseId,
        {jobs: {...phase.jobs,[origin]:{...phase.jobs[origin], illegal: Math.random()}}},
        true
      );
      return;
    }

    setField(
      phaseId,
      {
        resourceOrders: spreadResourceOrders(
          phase.resourceOrders,
          addMap,
          origin.localeCompare(addMap[origin] ?? origin) * -1
        ),
        jobs: njobj,
      },
      true
    );
    setCurrentSelection({ phase: phaseId, date: addMap[origin] ?? origin });
  };

  const phase = project.phases[phaseId];

  const rights = useRights();
  const isDispo = "DISPOSITION" in rights;
  let canBeMoved = isDispo;
  if (!canBeMoved) {
    canBeMoved = true;
    for (let job of Object.values(phase.jobs)) {
      if (job.deleted) continue;
      if (job.status !== "PLANNING") {
        canBeMoved = false;
        break;
      }
    }
  }

  const selectDate = (clickDate: string, allowCreation: boolean = true) => {
    if (allowCreation) {
      const toCreate = new Set([clickDate]);
      if (weekplanning) {
        const weekstart = dayjs(clickDate, "YYYY-MM-DD").startOf("isoWeek");
        for (let add = 0; add <= 4; add++) {
          toCreate.add(weekstart.add(add, "day").format("YYYY-MM-DD"));
        }
      }
      let newjobs = {};
      for (let date of toCreate) {
        if (!(date in phase.jobs) || phase.jobs[date].deleted) {
          newjobs = { ...newjobs, [date]: initialJob(date) };
        }
      }
      if (Object.keys(newjobs).length > 0) {
        setField(
          phaseId,
          {
            jobs: {
              ...phase.jobs,
              ...newjobs,
            },
          },
          true
        );
      }
    }
    setCurrentSelection({ phase: phaseId, date: clickDate });
  };

  const initDuration = (e) => {
    const start = view.calStart.add(
      Math.floor(
        (e.clientX - e.target.getBoundingClientRect().left) / view.zoom
      ),
      "days"
    );
    const end = start.add(6, "day");
    onDateChange(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"), 0);
  };

  const [startAdd, setStartAdd] = useState(0);
  const [moveAdd, setMoveAdd] = useState(0);
  const [endAdd, setEndAdd] = useState(0);
  const [startEvent, setStartEvent] = useState<{
    pageX: number;
    point: "start" | "end" | "move";
  } | null>(null);

  useLayoutEffect(() => {
    setStartAdd(0);
    setMoveAdd(0);
    setEndAdd(0);
  }, [phase.plannedStart, phase.plannedEnd]);

  const start = !phase.plannedStart ? null : dayjs(phase.plannedStart);
  const end = !phase.plannedEnd ? null : dayjs(phase.plannedEnd);

  const dragstart = (e, point) => {
    e.dataTransfer.setDragImage(blankImage, 0, 0);
    e.dataTransfer.effectAllowed = "move";
    e.target.style.cursor = "ew-resize !important";
    setStartEvent({ pageX: e.pageX, point });
  };

  const mouseMove = (pageX: number) => {
    if (pageX === 0) return;
    const factor = view.zoom < 15 ? 7 : 1;
    if (startEvent.point === "move") {
      const v =
        Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor;
      setMoveAdd(v);
    } else if (startEvent.point === "start") {
      setStartAdd(
        Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor
      );
    } else {
      setEndAdd(
        Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor
      );
    }
  };

  const mouseUp = (pageX: number) => {
    if (pageX === 0) return;
    const factor = view.zoom < 15 ? 7 : 1;
    if (startEvent.point === "move") {
      const v =
        Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor;
      setMoveAdd(v);
    } else if (startEvent.point === "start") {
      setStartAdd(
        Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor
      );
    } else {
      setEndAdd(
        Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor
      );
    }
    onDateChange(
      start.add(startAdd + moveAdd, "days").format("YYYY-MM-DD"),
      end.add(endAdd + moveAdd, "days").format("YYYY-MM-DD"),
      moveAdd
    );
    setStartEvent(null);
  };

  const length = useMemo(() => {
    const glen =
      !phase.plannedStart || !phase.plannedEnd
        ? null
        : dayjs(phase.plannedEnd, "YYYY-MM-DD").diff(
            dayjs(phase.plannedStart, "YYYY-MM-DD"),
            "days"
          ) + 1;
    const jlen = Object.values(phase.jobs).filter((x) => !x.deleted).length;
    if (!glen) return "";
    return `${jlen} von ${glen} Tagen`;
  }, [phase.plannedStart, phase.plannedEnd, phase.jobs]);

  const astart =
    projectStartAdd + startAdd + moveAdd === 0 || !start
      ? start
      : start.add(projectStartAdd + startAdd + moveAdd, "days");
  const aend =
    projectStartAdd + endAdd + moveAdd === 0 || !end
      ? end
      : end.add(projectStartAdd + endAdd + moveAdd, "days");

  const xstart = !astart
    ? null
    : astart > view.calEnd
    ? null
    : astart < view.calStart
    ? view.calStart
    : astart;
  const xend = !aend
    ? null
    : aend < view.calStart
    ? null
    : aend > view.calEnd
    ? view.calEnd
    : aend;

  return (
    <Fragment>
      <div
        key={"sidebar" + line}
        className={"vc-sidebar phase"}
        style={{
          gridRow: line - 1 + " / " + (line + (!phase.plannedEnd ? 0 : 1)),
          background:
            currentSelection.phase === phaseId ? "#cbdbff" : "#eaeaea",
        }}
        onClick={() => setCurrentSelection({ phase: phaseId, date: null })}
      >
        <div className="phasetitle">{phase.name || "Unbenannt"}</div>
        <div className="phaselength">{length}</div>
      </div>
      {!xstart || !xend ? null : (
        <div
          className={"vc-phase"}
          key={"phase" + line}
          style={{
            display: "flex",
            justifyContent: "space-between",
            gridRow: line - 1,
            gridColumn:
              xstart.diff(view.calStart, "days") +
              2 +
              " / " +
              (xend.diff(view.calStart, "days") + 3),
          }}
        >
          <div
            className="vc-reshandle left"
            draggable={project.writeable}
            onDragOver={(e) => e.preventDefault()}
            onDragStart={(e) => dragstart(e, "start")}
            onDrag={(e) => (!startEvent ? null : mouseMove(e.pageX))}
            onDragEnd={(e) => (!startEvent ? null : mouseUp(e.pageX))}
          />
          <div
            className="vc-reshandle move"
            draggable={project.writeable && canBeMoved}
            onDragOver={(e) => e.preventDefault()}
            onDragStart={(e) => dragstart(e, "move")}
            onDrag={(e) => (!startEvent ? null : mouseMove(e.pageX))}
            onDragEnd={(e) => (!startEvent ? null : mouseUp(e.pageX))}
          />
          <div
            className="vc-reshandle right"
            draggable={project.writeable}
            onDragOver={(e) => e.preventDefault()}
            onDragStart={(e) => dragstart(e, "end")}
            onDrag={(e) => (!startEvent ? null : mouseMove(e.pageX))}
            onDragEnd={(e) => (!startEvent ? null : mouseUp(e.pageX))}
          />
        </div>
      )}
      <div
        className={"vc-hl" + (!phase.plannedEnd ? "" : " slight")}
        key={"hl2" + line}
        onClick={(e) => initDuration(e)}
        style={{
          pointerEvents: !phase.plannedEnd ? "initial !important" : "none",
          gridRow: line - 1,
          gridColumn: 2 + " / " + (view.calEnd.diff(view.calStart, "days") + 2),
        }}
      ></div>
      {!phase.plannedEnd ? null : (
        <JobLine
        lockOrder={lockOrder}
          selectedDate={
            currentSelection.phase === phaseId
              ? currentSelection.date ?? ""
              : ""
          }
          changeDates={changeDates}
          startAdd={projectStartAdd + moveAdd}
          key={"jobline" + line}
          line={line}
          writeable={project.writeable}
          onSelect={selectDate}
          jobs={phase.jobs}
        />
      )}
    </Fragment>
  );
}
